<template>
  <div style="height:100%;display:flex; flex-direction:column">
    <el-table :data="tableRows"
              :span-method="arraySpanMethod"
              :row-class-name="tableRowClassName"
              :cell-class-name="tableCellClassName"
              :default-sort="{prop: 'id', order: 'descending'}"
              ref="tblDelivery"
              row-key="id"
              style="width: 100%;font-size: 16px;flex: 0 0 auto;"
              :border="true"
              tooltip-effect="light"
              v-ls-saver:dlvTbl
    >
      <el-table-column type="expand" width="30px" style="padding: 0">
        <template slot-scope="props">
          <div style="display: flex; flex-wrap: nowrap; width: 100%" v-if="props.row.number === (props.row.count - 1 )">
            <div style="display: flex;width:70%">
              <el-form status-icon label-width="100px" style="width:100%">
                <el-form-item label="Запланирован">
                  <div style="display: flex; flex-wrap: nowrap;justify-content: space-between;align-items: center">
                    <el-date-picker
                        style="width:150px"
                        v-model="props.row.delivery.scheduledSendDate"
                        type="date"
                        :picker-options="{firstDayOfWeek: 1}"
                        placeholder="Выбрать">
                    </el-date-picker>

                    <el-form-item  v-if="props.row.delivery.type !== 'forNgs'" label-width="200px" label="Учитывать стоимость доставки" style="margin: 0 0 0 20px">
                      <el-switch v-model="props.row.delivery.calcDeliveryPrice"
                                 @change="loadDeliveryAccount(props.row.delivery)"
                      />
                    </el-form-item>
                  </div>
                </el-form-item>
                <el-form-item v-if="props.row.delivery.type !== 'forNgs'" label="Клиент" :id="`forChanges_client_${props.row.delivery.id}`">
                  <my-selector
                      type="client"
                      v-model="props.row.delivery.client"
                      placeholder=""
                      :buttons='true'
                      @edit="onEditClient(props.row.delivery, props.row.delivery.clientId)"
                      @add="onEditClient(props.row.delivery)"
                      @change="onClientChange(props.row.delivery, $event)"
                  />
                  <div v-if="props.row.delivery.client">
                    <div style="display: flex;justify-content: space-between">
                      <div>
                        <b>Организация:&nbsp;&nbsp;</b><span v-html="getClientAffiliation(props.row.delivery.client)"></span>
                      </div>
                      <div style="display: flex">
                        <my-comment
                            style="align-self: flex-end"
                            :length="50"
                            :comment="props.row.delivery.client.importantComment"></my-comment>
                        <my-client-debt-warning :client="props.row.delivery.client"></my-client-debt-warning>
                        <i v-if="props.row.delivery.client.isCheck"
                           :class="props.row.delivery.client.defaultPayType === 'fromAccount' ? 'el-icon-circle-check' : 'el-icon-check'"
                           style="font-weight: bold;color:green; align-self: center;margin-right: 10px;margin-left: 5px"></i>
                      </div>
                    </div>
                    <div style="display: flex">
                      <my-comment
                          style="align-self: flex-end"
                          :length="400"
                          :comment="getAffiliationComment(props.row.delivery.client)"></my-comment>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item v-if="props.row.delivery.type !== 'forNgs'" label="Получатель" :id="`forChanges_user_${props.row.delivery.id}`">
                  <my-selector
                      type="user"
                      v-model="props.row.delivery.recipient"
                      :placeholder="false"
                      :disabled="!props.row.delivery.client"
                      :buttons='true'
                      :selector="buildSearchRecipientFunction(props.row.delivery)"
                      @edit="onEditUser(props.row.delivery, props.row.delivery.recipientId)"
                      @add="onEditUser(props.row.delivery)"
                      @change="onRecipientChange(props.row.delivery, $event)"
                  />
                  <template v-if="props.row.delivery.recipient">
                    <div v-if="props.row.delivery.recipient" style="display: flex;justify-content: space-between">
                      <div>
                        <template v-if="props.row.delivery.recipient.login || props.row.delivery.recipient.oldLogin">
                          <b>Логин:&nbsp;&nbsp;</b><span
                            v-html="props.row.delivery.recipient.login ? props.row.delivery.recipient.login : props.row.delivery.recipient.oldLogin"></span>
                        </template>
                      </div>
                      <div style="display: flex;flex-wrap: nowrap">
                        <my-comment style="align-self: flex-end"
                                    :length="50"
                                    :comment="props.row.delivery.recipient.importantComment"></my-comment>
                        <i v-if="props.row.delivery.recipient.isChecked"
                           class="el-icon-circle-check"
                           style="font-weight: bold;color:green; align-self: center;margin-right: 10px;margin-left: 5px"></i>
                      </div>
                    </div>
                    <b>Адреса:&nbsp;&nbsp;</b><span v-html="getRecipientMails(props.row.delivery.recipient)"></span><br/>
                    <b>Телефоны:&nbsp;&nbsp;</b><span v-html="getRecipientPhones(props.row.delivery.recipient)"></span>
                  </template>
                </el-form-item>
                <el-form-item label="Тип доставки">
                  <div style="display:flex;justify-content: space-between;align-items: center">
                    <el-select :disabled="props.row.delivery.type === 'forNgs'" :value="computedDeliveryType(props.row.delivery)" @change="setDeliveryType(props.row.delivery, $event)">
                      <el-option label="Самовывоз" value="woDelivery"></el-option>
                      <el-option label="Доставка" value="delivery"></el-option>
                      <el-option v-if="canDeliveryForSequence(props.row.delivery)" label="Для сиквенса" value="forSequence"></el-option>
                    </el-select>
                    <el-form-item label="Накладная" label-width="110px" v-if="! props.row.delivery.woDelivery" style="margin-bottom: 0">
                      <div style="display: flex">
                        <el-input v-model="props.row.delivery.trackNumber" style="margin-right: 5px"></el-input>
                        <el-popover
                            v-if="(props.row.delivery.serviceId === 23 || props.row.delivery.serviceId ===14 ) && props.row.delivery.trackNumber"
                            placement="bottom"
                            title="Состояние доставки"
                            trigger="click"
                            v-model="isDeliveryInfoShow">
                          <i v-if="$store.state.shared.loading > 0" class="el-icon-loading"></i>
                          <div v-else-if = "props.row.delivery.serviceId === 23"  v-html="deliveryInfo" style="width: auto!important;"></div>
                          <div v-else>
                            <table>
                              <thead>
                              <tr>
                                <th>Дата</th>
                                <th style="padding-left: 10px">Город</th>
                                <th style="padding-left: 10px">Операция</th>
                                <th style="padding-left: 10px">Комментарий</th>
                              </tr>
                              </thead>
                              <tr v-for="row in deliveryInfo" :key="row.eventNum">
                                <td>{{row.eventDateTime}}</td>
                                <td style="padding-left: 10px">{{row.cityName}}</td>
                                <td style="padding-left: 10px">{{row.event}}</td>
                                <td style="padding-left: 10px">{{row.comments}}</td>
                              </tr>
                            </table>
                          </div>
                          <i slot="reference" class="el-icon-info" @click="showDeliveryInfo(props.row.delivery.serviceId, props.row.delivery.trackNumber)"></i>
                        </el-popover>
                      </div>
                    </el-form-item>
                    <el-form-item v-if="!['forSequence','forNgs'].includes(props.row.delivery.type)" label="На льду" style="margin-bottom: 0">
                      <el-switch v-model="props.row.delivery.onIce"/>
                    </el-form-item>
                  </div>
                </el-form-item>
                <template v-if="! props.row.delivery.woDelivery">
                  <el-form-item label="Курьер">
                    <div style="display: flex;justify-content: space-between">
                      <div style="display: flex;">
                        <delivery-service-selector
                            v-model="props.row.delivery.service"
                            @change="onChangeDelivery(props.row.delivery, $event)"
                        ></delivery-service-selector>
                        <el-popover v-if="props.row.delivery.defaultDeliveryServiceSelected" style="display: flex" placement="top-start" trigger="hover">
                          <div style="width:300px;word-break: break-word">
                            Доставка не оператором выбрана, а взята из адреса у клиента или из настроек дефолтной доставки
                          </div>
                          <i class="el-icon-warning" slot="reference" style="cursor: pointer;font-size: 14px;color:#E6A23C;margin-left: 5px"></i>
                        </el-popover>
                      </div>
                      <el-form-item label="Номер договора" label-width="110px" style="margin-bottom: 0">
                        <el-input v-model="props.row.delivery.deliveryServiceAccountNumber" style="min-width: 150px"></el-input>
                      </el-form-item>
                      <el-form-item label="Счет оплачен"  style="margin-bottom: 0">
                        <el-switch v-model="props.row.delivery.invoiceIsPaid"></el-switch>
                      </el-form-item>
                    </div>
                  </el-form-item>
                  <el-form-item v-if="props.row.delivery.type !== 'forNgs'" label="Адрес">
                    <my-el-autocomplete
                        :showValueField="getAddressString"
                        v-model="props.row.delivery.address"
                        :selectFunction="buildSearchFunction(props.row.delivery.client)"
                        :clear="true"
                        :add="true"
                        :edit="true"
                        :disabled="!props.row.delivery.client"
                        @edit="onBtnAddAddressClick(props.row.delivery, props.row.delivery.addressId )"
                        @add="onBtnAddAddressClick(props.row.delivery)"
                        @change="onAutocompleteAddressChange(props.row)"
                    >
                      <template slot-scope="{ item }">
                        <div style="width:900px">{{ getAddressString(item) }}</div>
                      </template>
                    </my-el-autocomplete>
                  </el-form-item>
                  <el-form-item v-if="props.row.delivery.oldAddress" label="Адрес (старая база)">
                    {{ props.row.delivery.oldAddress }}
                  </el-form-item>
                </template>
                <el-form-item label="Комментарий">
                  <el-input type="textarea" autosize v-model="props.row.delivery.comment"></el-input>
                </el-form-item>
              </el-form>
            </div>
            <div style="display:flex;flex-direction: column; flex: 1 1 30%; margin-left: 15px">
              <el-form status-icon label-width="100px" style="width:100%" label-position="left">
                <el-form-item label="Собран:" style="margin-bottom: 0">
                  <div style="width:60px;font-weight: bold">
                    <my-date :date="findStateDate(props.row.delivery, 'readyToSend')"/>
                  </div>
                </el-form-item>
                <el-form-item label="Отправлен:" style="margin-bottom: 0">
                  <div style="display:flex;font-weight: bold">
                    <my-date :date="findStateDate(props.row.delivery, 'send')"/>
                    <my-el-button
                        v-if="!editOrder && props.row.delivery.type === 'simple' &&  props.row.delivery.state === 'send' "
                        @click="$emit('cancel-send-click', props.row.delivery)"
                        type="danger"
                        size="mini">Отменить
                    </my-el-button>
                  </div>
                </el-form-item>
                <el-form-item label="Доставлен:" style="margin-bottom: 0">
                  <div style="display: flex;flex-wrap:nowrap; font-weight: bold">
                    <my-date :date="findStateDate(props.row.delivery, 'delivered')"/>
                    <my-el-button
                        v-if="!editOrder && props.row.delivery.type === 'simple' &&  props.row.delivery.state === 'delivered'"
                        @click="$emit('cancel-delivery-click', props.row.delivery)"
                        type="danger"
                        size="mini">Отменить
                    </my-el-button>
                  </div>
                </el-form-item>
                <el-form-item v-if="needCustomsDocs(props.row.delivery)" label="Таможенные документы:" label-width="300px"
                              style="margin-top:10px; margin-bottom: 5px;text-align: right">
                  <my-el-button type="success" @click="createCustomsDocs(props.row.delivery)">Сделать PDF</my-el-button>
                </el-form-item>
              </el-form>
              <file-table
                  v-if="needCustomsDocs(props.row.delivery)"
                  :file-type="['customs']" :files="props.row.delivery.files" with-date></file-table>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="" width="90">
        <template slot-scope="scope">
          {{ prepareDeliveryName(scope.row.delivery.id) }}
          <el-popover v-if="scope.row.delivery.type !== 'simple'"
                      trigger="hover">
            <template v-if="scope.row.delivery.type === 'forSequence'">Для секвенирования</template>
            <template v-else-if="scope.row.delivery.type === 'reverse'">Доставка в Евроген</template>
            <template v-else>Доставка NGS</template>
            <i
               :class="scope.row.delivery.type === 'forSequence' ? 'el-icon-setting' : (scope.row.delivery.type === 'forNgs' ? 'el-icon-s-data' : 'el-icon-download' ) "
               slot="reference"
               style="margin-left:3px;color:red"></i>
          </el-popover>

        </template>
      </el-table-column>
      <el-table-column label="Состояние" width="165px">
        <template slot-scope="scope">
          <div style="display: flex; justify-items: start">
            <div style="margin-right: 10px">{{ $getEnumValue('DeliveryStateEnum', scope.row.delivery.state) }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Запланирован" width="130px">
        <template slot-scope="scope">
          <my-date :date="scope.row.delivery.scheduledSendDate"></my-date>
        </template>
      </el-table-column>
      <el-table-column label="Доставка">
        <template slot-scope="scope">
          <div style="display: flex; justify-items: start;align-items: center">
            <div>
              {{ scope.row.delivery.woDelivery ? 'Самовывоз' : (scope.row.delivery.service ? scope.row.delivery.service.name : '') }}</div>
            <el-popover v-if="scope.row.delivery.defaultDeliveryServiceSelected" style="display: flex" placement="top-start" trigger="hover">
              <div style="width:300px;word-break: break-word">
                Доставка не оператором выбрана, а взята из адреса у клиента или из настроек дефолтной доставки
              </div>
              <i class="el-icon-warning" slot="reference" style="cursor: pointer;font-size: 14px;color:#E6A23C;margin-left: 5px"></i>
            </el-popover>
          </div>


        </template>
      </el-table-column>
      <el-table-column label="Заказ" width="160">
        <template slot-scope="scope">
          <template v-if="scope.row.rl.order.id > 0">
            <order-number
                :no-show-window="editOrder && scope.row.rl.order.metaorderId===editOrder.id"
                :order="scope.row.rl.order"
                :metaorder="editOrder"
                @click="$emit('change-active-tab', scope.row.rl.order.id.toString())"/>
          </template>
          <div v-else>Новый заказ ({{ scope.row.rl.order.type }})</div>
        </template>
      </el-table-column>
      <el-table-column label="Цена" width="120px">
        <div style="display: flex;align-items: center" slot-scope="scope">
          <my-price-input
              class="left-number-input"
              style="width:80px"
              @change="scope.row.rl.defaultPriceSelected = false"
              v-model="scope.row.rl.price">
          </my-price-input>
          <el-popover v-if="scope.row.rl.defaultPriceSelected" style="display: flex" placement="top-start" trigger="hover">
            <div style="width:300px;word-break: break-word">
              Цена не оператором выбрана, а взята из адреса у клиента или из настроек дефолтной доставки
            </div>
            <i class="el-icon-warning" slot="reference" style="cursor: pointer;font-size: 14px;color:#E6A23C;margin-left: 5px"></i>
          </el-popover>
        </div>
      </el-table-column>
      <el-table-column label="Сумма" width="100px">
        <template slot-scope="scope">
          <div v-if="scope.row.sum" style="display: flex;flex-wrap: nowrap">
            {{ $roundFmt(scope.row.sum) }} {{ scope.row.currency }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="" width="140" :resizable="false">
        <el-dropdown
            v-if="scope.row.delivery.type === 'simple' && !scope.row.delivery.isCopyOf && !hasCopy(scope.row.delivery)"
            slot-scope="scope" @command="onMoveOrderClick">
          <my-el-button type="primary">
            Перенести<i class="el-icon-arrow-down el-icon--right"></i>
          </my-el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
                :command="[false, scope.row.delivery, scope.row.rl, 0]"
                v-if="scope.row.delivery.ordersRl.length > 1">В отдельную доставку
            </el-dropdown-item>
            <el-dropdown-item v-if="deliverys.some( d => d.ordersRl.length > 0 && d.type === 'simple' && d.id !== scope.row.delivery.id)"
                              command="e" divided disabled>В доставку заказа:
            </el-dropdown-item>
            <el-dropdown-item
                v-for="dlv in deliverys.filter( d => d.ordersRl.length > 0 && d.type === 'simple' && d.id !== scope.row.delivery.id)"
                :key="'dlv_' + dlv.id"
                :command="[false, scope.row.delivery, scope.row.rl, dlv.id]"
            >{{ prepareDeliveryName(dlv.id) }}
            </el-dropdown-item>
            <el-dropdown-item v-if="otherDeliverys && otherDeliverys.length > 0" command="e" divided disabled>В другую доставку:</el-dropdown-item>
            <el-dropdown-item
                v-for="dlv in otherDeliverys"
                :key="'dlv_' + dlv.id"
                :command="[true, scope.row.delivery, scope.row.rl, dlv.id]"
            >{{ prepareDeliveryName(dlv.id) }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-table-column>
      <el-table-column label="" width="65" :resizable="false">
        <template slot-scope="scope">
          <my-el-button
              :key="`deliveryBtnAdd_${scope.row.delivery.id}`"
              v-if="!scope.row.delivery.isCopyOf && scope.row.delivery.id > 0"
              plus
              @click=copyDelivery(scope.row.delivery)></my-el-button>
          <my-el-button
              :key="`deliveryBtnDel_${scope.row.delivery.id}`"
              v-if="scope.row.delivery.isCopyOf"
              delete
              @click=deleteDelivery(scope.row.delivery)></my-el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="padding: 20px 0 10px 0;width: 100%;">
      <my-el-find-input
          placeholder="Номер заказа, Клиент, Организация"
          with-clear
          @find="onDeliverySearchClick"
      />
    </div>
    <el-table :data="tableOtherRows"
              :span-method="arraySpanMethodForOther"
              :row-class-name="tableRowClassName"
              :default-sort="{prop: 'id', order: 'descending'}"
              ref="tblOtherDelivery"
              row-key="id"
              style="width: 100%;font-size: 16px;flex-grow: 1"
              height="100%"
              :border="true"
              v-ls-saver:dlvFindTbl
    >
      <el-table-column :width="80">
        <template slot-scope="scope">
          {{ prepareDeliveryName(scope.row.delivery.id) }}
        </template>
      </el-table-column>
      <el-table-column label="Состояние" width="120">
        <template slot-scope="scope">
          <div style="display: flex; justify-items: start">
            <div style="margin-right: 10px">{{ $getEnumValue('DeliveryStateEnum', scope.row.delivery.state) }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Запланирован" width="130px">
        <template slot-scope="scope">
          <my-date :date="scope.row.delivery.scheduledSendDate"></my-date>
        </template>
      </el-table-column>
      <el-table-column label="Доставка">
        <template slot-scope="scope">
          <div style="display: flex; justify-items: start">
            <div>{{ scope.row.delivery.woDelivery ? 'Самовывоз' : (scope.row.delivery.service ? scope.row.delivery.service.name : '') }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Клиент">
        <template slot-scope="scope">
          <div v-if="scope.row.delivery.client" style="display: flex; justify-items: start">
            <div>{{ scope.row.delivery.client.name }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Заказ" width="160">
        <template slot-scope="scope">
          <order-number v-if="scope.row.rl.order.id > 0" :order="scope.row.rl.order"/>
          <div v-else>Новый заказ ({{ scope.row.rl.order.type }})</div>
        </template>
      </el-table-column>
      <el-table-column label="Цена" width="80">
        <template slot-scope="scope">
          {{ scope.row.rl.price }}
        </template>
      </el-table-column>
      <el-table-column label="" width="140">
        <template slot-scope="scope">
          <el-dropdown @command="onMoveOrderToMainClick">
            <my-el-button type="primary">
              Перенести<i class="el-icon-arrow-down el-icon--right"/>
            </my-el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="e" divided disabled>В доставку заказа:</el-dropdown-item>
              <el-dropdown-item
                  v-for="dlv in deliverys.filter(d => d.ordersRl.length > 0 && d.type === 'simple' )"
                  :key="'dlv_' + dlv.id"
                  :command="[scope.row.delivery, scope.row.rl, dlv.id]"
              >{{ prepareDeliveryName(dlv.id) }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
  </div>

</template>

<script>
import _ from 'lodash';
import addressEditor from '@/components/usersClientsAffiliation/AddressEditor';
import { createDelivery } from "@/utils/orders";
import FileTable from '@/components/common/fileTable';
import customsPrint from '@/components/deliveries/CustomsPrint';
import clientEditor from '@/components/usersClientsAffiliation/ClientEditor';
import userEditor from '@/components/usersClientsAffiliation/UserEditor';
import tableSaver from "@/utils/tableSaver";
import baseOrderTab from './BaseOrderTab.js';
import myComment from '@/components/common/myComment';
import OrderNumber from "@/components/common/orderNumber";
import DeliveryServiceSelector from "@/components/deliveries/DeliveryServiceSelector";
import MyClientDebtWarning from "@/components/common/myClientDebtWarning";


export default {
  name: 'deliveryTab',
  components: { MyClientDebtWarning, DeliveryServiceSelector, OrderNumber, FileTable, myComment },
  props: ['editOrder'],
  mixins: [tableSaver, baseOrderTab],
  data() {
    return {
      otherDeliverys: [],
      isDeliveryInfoShow: false,
      deliveryInfo:null
    }
  },

  watch: {
    'tableRows.length': async function() {
      //Без этого Если доставка expanded и добавить новый заказ который в эту доставку попадет - таблицв ломается
      //Похоже какой-то глюк таблицы
      this.$refs.tblDelivery.layout.store.states.expandRows = [];
      this.$refs.tblDelivery.toggleRowExpansion(this.tableRows[ this.tableRows.length - 1 ]);
    }
  },

  methods: {
    async showDeliveryInfo(serviceId, trackId) {
      if (!this.isDeliveryInfoShow && !this.deliveryInfo) {
        this.deliveryInfo = await this.$store.dispatch('deliveries/getDeliveryServiceStateInfo', {serviceId, trackId});
      }
    },

    onChangeDelivery(delivery, value) {
      delivery.defaultDeliveryServiceSelected = false;
      delivery.serviceId = value?.id;
      this.loadDeliveryAccount(delivery);
    },

    hasCopy(srcDelivery) {
      return this.deliverys.some(d => !d.forDelete && d.isCopyOf === srcDelivery.id);
    },

    copyDelivery(srcDelivery) {
      let newDelivery = _.cloneDeep(srcDelivery);
      newDelivery.stateLog = [];
      newDelivery.id = this.deliverys.reduce((acc, rec) => acc < rec.id ? acc : rec.id, 0) - 1;
      newDelivery.isCopyOf = srcDelivery.id;
      newDelivery.ordersRl.forEach(d => {
        d.id = 0;
        d.deliveryId = newDelivery.id;
      })
      this.deliverys.push(newDelivery);
    },

    deleteDelivery(delivery) {
      delivery.ordersRl.forEach(d => {
        d.forDelete = true;
      })
      delivery.forDelete = true;
    },

    canDeliveryForSequence(delivery) {
      return ['simple', 'forSequence'].some(s => s === delivery.type)
          && delivery.ordersRl.every(rl => rl.order.type === 'sst');
    },

    computedDeliveryType(delivery) {
      if (delivery.type === 'forSequence') {
        return 'forSequence'
      }
      return delivery.woDelivery ? 'woDelivery' : 'delivery';
    },

    setDeliveryType(delivery, value) {
      if (value === 'forSequence') {
        delivery.woDelivery = true;
        delivery.type = 'forSequence';
        delivery.calcDeliveryPrice = false;
        delivery.onIce = false;
        delivery.ordersRl.forEach(rl => rl.price = 0);
      } else {
        if (delivery.type === 'forSequence') {
          delivery.type = 'simple';
        }
        delivery.woDelivery = value === 'woDelivery';
      }
      if (delivery.woDelivery) {
        delivery.serviceId = null;
        delivery.service = null;
      }
    },

    getClientAffiliation(client) {
      return client?.affiliation?.name || '';
    },
    getAffiliationComment(client) {
      return client?.affiliation?.importantComment || '';
    },


    getRecipientMails(recipient) {
      if (recipient && recipient.contacts) {
        return recipient.contacts.filter(r => r.type === 'mail').map(r => r.contact).join(",&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;");
      }
      return "";
    },
    getRecipientPhones(recipient) {
      if (recipient && recipient.contacts) {
        return recipient.contacts.filter(r => r.type === 'phone').map(r => r.contact).join(",&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;");
      }
      return "";
    },


    prepareDeliveryName: (deliveryId) => deliveryId > 0 ? `#${deliveryId}` : `Новая доставка ${deliveryId * -1}`,

    needCustomsDocs(delivery) {
      return (delivery.id > 0) &&
          ((delivery.address?.countryId || 1) !== 1
              || (delivery.client?.countryId || 1) !== 1
              || delivery.files && delivery.files.length > 0);
    },

    findStateDate(delivery, state) {
      if (!delivery.stateLog) {
        return null;
      }
      let log = delivery.stateLog.find(st => st.newState === state);
      return log
          ? log.createdAt
          : null;
    },

    getAddressString(address) {
      return addressEditor.methods.addressToString(address);
    },

    onMoveOrderClick([toOther, srcDelivery, orderRl, newDeliveryId]) {
      this.tableRows.forEach(r => this.$refs.tblDelivery.toggleRowExpansion(r, false));

      let targetDelivery = null;
      if (newDeliveryId === 0) {  //В отдельную доставку
        targetDelivery = createDelivery(this.deliverys, 'simple', this.editOrder);
        if (!this.editOrder && this.deliverys.length > 0) {
          targetDelivery.clientId = this.deliverys[ 0 ].clientId;
          targetDelivery.client = this.deliverys[ 0 ].client;
          targetDelivery.addressId = this.deliverys[ 0 ].addressId;
          targetDelivery.address = this.deliverys[ 0 ].address;
        }
      } else {
        targetDelivery = _.find(toOther ? this.otherDeliverys : this.deliverys, d => d.id === newDeliveryId);
      }
      let idx = srcDelivery.ordersRl.indexOf(orderRl);
      srcDelivery.ordersRl.splice(idx, 1);

      orderRl.deliveryId = targetDelivery.id;
      // При попытке использовать ту же запись а не новую, если доставка из которой переносили опустела
      // при удалении доставки эта запись каскадно удаляется -> ef core наедается
      orderRl.id = srcDelivery.ordersRl.length === 0
          ? (_.min(srcDelivery.ordersRl.map(rl => rl.id)) || 0) - 1
          : orderRl.id;
      targetDelivery.ordersRl.push(orderRl);

      //Доставку ставшую пустой можно удалить только в том случае если ее еще нет в базе.
      //Еслм она в базе то удалять надо на сервере
      if (srcDelivery.id < 0 && srcDelivery.ordersRl.length === 0) {
        let idx = this.deliverys.indexOf(srcDelivery);
        this.deliverys.splice(idx, 1);
      }
      //Если переносится в другую доставку (которая не относится к текущему заказу)
      //добавляем доставку в основной список доставок - что б она уехала на сервер и там обновилась
      if (toOther) {
        let id = this.otherDeliverys.indexOf(targetDelivery);
        this.otherDeliverys.splice(id, 1);
        this.deliverys.push(targetDelivery);
      }
      //надо ?
      this.$refs.tblDelivery.doLayout();
    },

    onMoveOrderToMainClick([srcDelivery, orderRl, newDeliveryId]) {
      this.tableRows.forEach(r => this.$refs.tblDelivery.toggleRowExpansion(r, false));
      let targetDelivery = _.find(this.deliverys, d => d.id === newDeliveryId);
      targetDelivery.ordersRl.push(orderRl);
      let idx = srcDelivery.ordersRl.indexOf(orderRl);
      srcDelivery.ordersRl.splice(idx, 1);

      orderRl.deliveryId = newDeliveryId;
      // При попытке использовать ту же запись а не новую, если доставка из которой переносили опустела
      // при удалении доставки эта запись каскадно удаляется -> ef core наедается
      orderRl.id = srcDelivery.ordersRl.length === 0
          ? (_.min(srcDelivery.ordersRl.map(rl => rl.id)) || 0) - 1
          : orderRl.id;

      if (srcDelivery.ordersRl.length === 0) {    //Если доставка стала пустой - перемещаем в основной список шоб она уехала на сервер и там грохнулась
        let id = this.otherDeliverys.indexOf(srcDelivery);
        this.otherDeliverys.splice(id, 1);
        this.deliverys.push(srcDelivery);
      }
      this.$refs.tblDelivery.doLayout();
    },

    arraySpanMethod({ row, columnIndex }) {
      let result;
      if ((columnIndex > 0 && columnIndex < 5) || columnIndex === 7 || columnIndex === 9) {
        result = row.number === 0
            ? [row.count, 1]
            : [0, 0]
      }
      return result;
    },

    arraySpanMethodForOther({ row, columnIndex }) {
      if (columnIndex < 2) {
        return row.number === 0
            ? [row.count, 1]
            : [0, 0]
      }
    },

    tableRowClassName({ row }) {
      return row.deliveryNumber % 2 === 0 ? 'row-even' : 'row-odd';
    },

    tableCellClassName({ row, columnIndex }) {
      if (columnIndex === 0) {
        return ((row.count > 0) && (row.number < row.count - 1)) ? 'cell-wo-border' : '';
      }
      if (columnIndex > 4) {
        return (this.editOrder && row.rl.order.metaorderId !== this.editOrder.id) ? 'cell-other-order' : '';
      }
    },

    async loadUsersForClient(clientId) {
      const query = [{ fieldName: '@clientUserRls.clientId', op: 'eq', values: [clientId] }];
      return await this.$store.dispatch('users/loadItems', { query });
    },

    async onClientChange(delivery, newClient) {
      if (newClient) {
        delivery.clientId = newClient.id;
        delivery.users = await this.loadUsersForClient(delivery.clientId);
        delivery.recipientId = delivery.users?.length === 1 ? delivery.users[ 0 ].id : null;
        delivery.recipient = delivery.users?.length === 1 ? delivery.users[ 0 ] : null;
        delivery.addressId = newClient.addresses.length === 1 ? newClient.addresses[ 0 ].id : null;
        delivery.address = newClient.addresses.length === 1 ? newClient.addresses[ 0 ] : null;
      } else {
        delivery.users = null;
        delivery.clientId = null;
        delivery.client = null;
        delivery.recipientId = null;
        delivery.recipient = null;
        delivery.addressId = null;
        delivery.address = null;
      }
      await this.loadDeliveryAccount(delivery);
    },

    async loadDeliveryAccount(delivery) {
      if (!delivery.clientId || delivery.woDelivery || !delivery.serviceId || delivery.calcDeliveryPrice) {
        delivery.deliveryServiceAccountNumber = null;
      } else {
        delivery.deliveryServiceAccountNumber = await this.$store.dispatch('clients/getDeliveryServiceAccount', { clientId: delivery.clientId, serviceId: delivery.serviceId });
      }
    },

    onRecipientChange(delivery, newRecipient) {
      delivery.recipientId = newRecipient?.id;
    },

    buildSearchFunction(client) {
      return client?.addresses
          ? (q, cb) => cb(client.addresses.filter(a => !a.deleled))
          : (q, cb) => cb([]);
    },
    buildSearchRecipientFunction(delivery) {
      return async(q, cb) => {
        //Если клиент уже выбран при загрузке, onchange не срабатывала и userов нет
        delivery.users = delivery.users || await this.loadUsersForClient(delivery.clientId);
        if (!delivery.users) {
          const query = [{ fieldName: '@clientUserRls.clientId', op: 'eq', values: [delivery.clientId] }];
          delivery.users = await this.$store.dispatch('users/loadItems', { query });
        }
        cb(delivery.users)
      };
    },

    async onAutocompleteAddressChange(row) {
      row.delivery.addressId = row.delivery.address?.id;
    },

    async loadOtherDeliverys(query, withIndicator = true) {
      query = [
        { fieldName: "", op: "part", values: query },
        { isOr: false, fieldName: "state", op: "in", values: ['InCompile', 'ReadyToSend'] }
      ];
      (this.deliverys.length > 0) && query.push({ isOr: false, fieldName: "id", op: "notin", values: this.deliverys.map(d => d.id) });
      this.otherDeliverys = await this.$store.dispatch('deliveries/loadItems', { query, withIndicator });
    },

    deliverysToOrder(deliverys) {
      let id = -1;
      return deliverys.reduce((res, delivery, deliveryNumber) => {
        let number = -1;
        let ordersRl = !this.editOrder   //Фильтруем только на странице заказа там могут быть пустые заказы
            ? delivery.ordersRl
            : delivery.ordersRl.filter(rl => (rl.order.elements && rl.order.elements.length > 0)
                                             || rl.order.metaorderId !== this.editOrder.metaorderId);
        ordersRl = ordersRl.filter(rl => !rl.forDelete);
        let rows = ordersRl.map(rl => {
          number++;
          id++;
          return {
            id, number, rl, delivery, deliveryNumber,
            count: ordersRl.length,
            sum: 0
          };
        });
        if (rows.length > 0) {
          rows[ 0 ].sum = ordersRl.reduce((sum, rl) => sum + rl.order.sum, 0);
          rows[ 0 ].currency = this.$getEnumValue('CurrencyEnum',
                                                  this.editOrder
                                                      ? this.editOrder.currency
                                                      : rows[ 0 ].rl.order.metaorder.currency);
        }
        return res.concat(rows);
      }, []);
    },

    onDeliverySearchClick(value) {
      value = value.trim();
      let rxNumber = /^(#)?(?<number>\d+)$/gi;
      let matchNumber = rxNumber.exec(value);
      let query = matchNumber
          ? [{ fieldName: "Id", op: "eq", values: [matchNumber.groups.number * 1] }]
          : [
            { fieldName: "@ordersRl.order.metaorder.user.lName", op: "like", values: [value] },
            { fieldName: "@ordersRl.order.number", op: "like", values: [value] },
            { fieldName: "client.name", op: "like", values: [value] },
            { fieldName: "client.affiliation.name", op: "like", values: [value] },
          ];
      this.loadOtherDeliverys(query);
    },

    changeDeliveryClient(value, oldValue) {
      if (oldValue === value) {
        return;
      }
      this.deliverys.filter(d => !d.clientId || d.clientId == oldValue).forEach(d => {
        d.clientId = value;
        d.client = value ? this.editOrder.client : null;
        if (oldValue) {
          d.address = null;
          d.addressId = null;
        }
      });
    },

    async onBtnAddAddressClick(delivery, id) {
      let address = await this.$showWindowAsync(addressEditor,
                                                {
                                                  id,
                                                  userId: delivery.recipientId,
                                                  clientId: delivery.clientId
                                                });
      if (address) {
        delivery.address = address;
        delivery.addressId = address.id;
      }
    },

    async onEditClient(delivery, id) {
      let client = await this.$showWindowAsync(clientEditor, { id });
      if (!client) {
        return;
      }
      delivery.client = client;
      delivery.clientId = client.id;
      if (!id) {
        delivery.users = null;
        delivery.recipientId = client.clientUserRls.length === 1 ? client.clientUserRls[ 0 ].userId : null;
        delivery.recipient = client.clientUserRls.length === 1 ? client.clientUserRls[ 0 ].user : null;

        delivery.addressId = client.addresses.length === 1 ? client.addresses[ 0 ].addressId : null;
        delivery.address = client.addresses.length === 1 ? client.addresses[ 0 ] : null;
      }
    },

    async onEditUser(delivery, id) {
      let user = await this.$showWindowAsync(userEditor, { id, client: delivery.client });
      if (user) {
        delivery.recipient = user;
        delivery.recipientId = user.id;
      }
    },

    async createCustomsDocs(delivery) {
      await this.saveOrder();
      let file = await this.$showWindowAsync(customsPrint, { deliveryId: delivery.id });
      if (file) {
        //Тут потенциальный баг: saveOrder записывает deliyverys, а потом перечитывает их ассинхронно - теоретически здесь могут быть доставки еще не подтянуты. Хотя это врядли.
        delivery = this.deliverys.find(d => d.id === delivery.id) //в saveOrder доставки перечитались и delivery смотрит не туда
        delivery.files.push(file[ 0 ]);
      }
    }
  },

  computed: {
    tableRows() {
      return this.deliverysToOrder(this.deliverys);
    },

    tableOtherRows() {
      return this.deliverysToOrder(this.otherDeliverys);
    },
  }
}
</script>

<style>
.el-table__expanded-cell {
  padding: 5px !important;
}

.ac-product-popper {
  width: auto !important;
}

.el-table .row-even {
  background: oldlace;
}

.el-table .row-odd {
  background: #f0f9eb;
}

.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: inherit !important;
}

.cell-wo-border {
  border-bottom: none !important;
}

.cell-wo-border > .cell {
  display: none;
}

.cell-other-order {
  background: #cccdb7;
}

.el-table--enable-row-hover .el-table__body tr:hover > td.cell-other-order {
  background: #cccdb7 !important;
}
</style>
