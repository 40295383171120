<template functional>
    <div :class="props.disabled ? 'dis-my-link': 'my-link'" 
         :style="`display:flex ${!props.wordBreak ? ';white-space:nowrap':''}${props.wordBreak? (';word-break:' + props.wordBreak ): '' }`"
         @click="!props.disabled && listeners['click']()">
        <el-popover v-if="props.comment" placement="top-start" trigger="hover">
            <div style="display: flex;flex-direction: column">
                <div style="display: flex;flex-direction: column">
                    <div style="font-size:16px; font-weight: bold; color: #409EFF ">Важный комментарий</div>
                    <pre style="margin:0;border:0;outline: 0" >{{props.comment}}</pre>
                </div>
            </div>
            <i class="el-icon-info" slot="reference" style="cursor: pointer;font-size: 12px;color:#409EFF;margin-right: 3px"></i>
        </el-popover>
        <div v-else-if="props.space" style="width:12px;flex-shrink: 0"></div>
        {{props.name}}
    </div>
</template>

<script>
    export default {
       name: "myLink",
       props: {
            disabled: {default: false},
            comment: {default: ''},
            name: {type:String},
            space: {default: false },
            wordBreak: {default: ''}
       }
    }
</script>

<style>
    .el-popover {
        min-width: 0 !important;
    }

</style>