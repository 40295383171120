<template>
  <div>
    <div
        ref="contentDiv"
        contenteditable="true"
        tabindex="0"
        :class="contentDivClasses"
        v-html="html"
        @keydown="onKeyDown"
        @focus="onFocus"
        @blur="focusOn=false"
        @mouseup="onMouseUp"
        @mousedown="onMouseDown"
        @paste="onPaste"
        @copy="onCopy"
        :style="{borderColor: borderColor}"
    >
    </div>
  </div>
</template>

<script>

export default {
  name: "PrimerEditor",
  data() {
    return {
      cursorPosition: null,
      selectionRange: null,
      focusOn: false,
      undoStack: [],
    }
  },
  props: {
    acceptedChars: [],
    value: { default: {} },
    toUpper: { default: true },
    needModifier: {default: ''},
    canBeEmpty: {default: false}
  },

  mounted() {
    document.oncontextmenu = this.onContextMenu;
  },

  methods: {

    onFocus() {
      this.focusOn = true;
      this.clearSelection();
    },

    onMouseDown() {
      if (event.button !== 0) return;
      this.clearSelection();
    },
    onMouseUp(event) {
      if (event.button !== 0) return;
      if (!this.editView.length) {
        this.cursorPosition = -1;
      } else {
        let idx = this.getElementIndex(event.target);
        this.saveSelectionRange();
        if (this.selectionRange) {
          this.cursorPosition = this.selectionRange.endIndex;
        } else {
          this.cursorPosition = idx >= 0 ? idx : this.editView.length - 1;
        }
        this.$nextTick(() => this.restoreSelectionRange());
      }
    },

    onCopy() {
      let text = '';
      if (window.getSelection) {
        text = window.getSelection().toString();
      } else if (document.selection && document.selection.type !== "Control") {
        text = document.selection.createRange().text;
      }
      navigator.clipboard.writeText(text.replace(/\s/g, ''));
      return false;
    },

    onPaste(event) {
      event.stopPropagation();
      event.preventDefault();
      let clipboardData = event.clipboardData || window.clipboardData;
      let pastedText = clipboardData.getData('Text');
      if (!pastedText) {
        return;
      }
      this.saveState();
      let primer = this.$primerHelper.parse(pastedText, false);
      let result = this.insertElementsToCurrentPosition(this.$primerHelper.buildEditView(primer));
      this.emitResult(result);
    },

    onKeyDown(event) {
      if (!this.selectionRange) {
        this.saveSelectionRange();
      }
      let result = null;
      switch ( event.key ) {
        case 'ArrowDown':
        case 'ArrowUp': {
          this.doArrowPress((toStart) => this.moveCursorByVertically(toStart), event.key === 'ArrowUp', event.shiftKey);
          break;
        }

        case 'ArrowRight':
        case 'ArrowLeft': {
          if ((event.key === 'ArrowLeft' && this.cursorPosition < 0)
              || (event.key === 'ArrowRight' && this.cursorPosition >= this.editView.length - 1)) break;
          this.doArrowPress((toStart) => this.cursorPosition += (toStart ? -1 : 1), event.key === 'ArrowLeft', event.shiftKey);
          break;
        }
        case 'End':
        case 'Home': {
          if (!event.shiftKey) {
            this.moveCursorToLineBorder(event.key === 'Home');
          } else {
            this.cursorPosition = event.key === 'Home' ? -1 : (this.editView.length - 1);
          }
          break;
        }
        case 'Delete': {
          this.saveState();
          result = this.selectionRange
              ? this.deleteSelected()
              : [...this.editView.slice(0, this.cursorPosition + 1), ...this.editView.slice(this.cursorPosition + 2)]
          break;
        }
        case 'Backspace': {
          if (this.cursorPosition < 0) {
            break;
          }
          this.saveState();
          if (this.selectionRange) {
            result = this.deleteSelected();
          } else {
            result = [...this.editView.slice(0, this.cursorPosition), ...this.editView.slice(this.cursorPosition + 1)];
            this.cursorPosition--;
          }
          break;
        }
        default: {
          if (event.key.length > 1) {  //Что б shift-insert не пропало а превратилось в событие paste
            if (event.key === 'Enter') {
              event.preventDefault();
            }
            return;
          }

          if (event.code === 'KeyZ' && event.ctrlKey) {
            this.doUndo();
            event.preventDefault();
            return;
          }

          if (event.code === 'KeyA' && event.ctrlKey) {
            this.doSelectAll();
            this.$nextTick(() => this.restoreSelectionRange());
            event.preventDefault();
            return;
          }
          if (event.ctrlKey) {
            return true;
          }

          let text = this.$primerHelper.translitRuChar(event.key.toUpperCase());
          if (text.trim()) {
            this.saveState();
            result = this.insertElementsToCurrentPosition([{ type: 'char', text }]);
          }
          break;
        }
      }
      if (result) {
        this.emitResult(result);
      }

      event.preventDefault();
    },
    doArrowPress(fn, toStart, shiftIsPress) {
      let saveCursorPosition = this.cursorPosition;
      fn(toStart);
      if (shiftIsPress) {
        this.doSelectByKey(toStart, saveCursorPosition);
        this.$nextTick(() => this.restoreSelectionRange());
      } else {
        this.selectionRange = null;
      }


    },

    clearSelection() {
      this.selectionRange = null;
      window.getSelection().removeAllRanges();
    },

    doSelectAll() {
      if (this.editView.length === 0) return;
      this.selectionRange = {
        startIndex: 0,
        endIndex: this.editView.length - 1
      };
      this.$nextTick(() => this.restoreSelectionRange());
    },

    doSelectByKey(toLeft, initPosition) {
      let pos = this.cursorPosition + (toLeft ? 1 : 0);
      if (!this.selectionRange) {
        let startIndex = initPosition === undefined ? pos
            : (pos > initPosition ? (initPosition + (!toLeft ? 1 : 0)) : pos);
        let endIndex = initPosition === undefined ? pos
            : (pos < initPosition ? initPosition : pos);

        this.selectionRange = { startIndex, endIndex };
        return;
      }
      if (toLeft) {
        if (pos < this.selectionRange.startIndex) {
          this.selectionRange.startIndex = pos;
        } else {
          this.selectionRange.endIndex = pos - 1;
        }
      } else {
        if (pos > this.selectionRange.endIndex) {
          this.selectionRange.endIndex = pos;
        } else {
          this.selectionRange.startIndex = pos + 1;
        }
      }
      if (this.selectionRange.startIndex > this.selectionRange.endIndex) {
        this.selectionRange = null;
      }
    },

    getElementIndex(el) {
      if (!el || (el.classList && el.classList.contains('content-div'))) return -1;
      while (el && !(el.classList && el.classList.contains('element-div'))) {
        el = el.parentNode;
      }
      return el ? [].indexOf.call(this.$refs.contentDiv.children, el) : -1;
    },

    getCurrentElement() {
      return this.getElementByIndex(this.cursorPosition < 0 ? 0 : this.cursorPosition);
    },

    getElementByIndex(idx) {
      return this.$refs.contentDiv.children[ idx ];
    },


    getElementPosition(el) {
      return el?.getBoundingClientRect();
    },

    moveCursorToLineBorder(toLeft) {
      let el = this.getCurrentElement();
      let coords = this.getElementPosition(el);
      let result = this.cursorPosition;
      while (el && this.getElementPosition(el).y === coords.y && ((toLeft && result !== -1) || (!toLeft && result < this.editView.length - 1))) {
        result += toLeft ? -1 : 1;
        el = this.getElementByIndex(result);
      }
      if (result === -1 || result === this.editView.length - 1) {
        this.cursorPosition = result;
      } else {
        this.cursorPosition = result + (toLeft ? 1 : -1);
      }
    },
    
    moveCursorByVertically(toUp) {
      let currentCoords = this.getElementPosition(this.getCurrentElement());
      let lineHeight = this.getLineHeight();
      let el = document.elementFromPoint(currentCoords.left, currentCoords.top + (toUp ? -lineHeight/2 : lineHeight * 1.5));
      if (el.classList.contains('content-div')) {
        this.cursorPosition = this.editView.length - 1;
      }
      let pos = this.getElementIndex(el);
      if (pos >= 0) {
        this.cursorPosition = this.getElementIndex(el);
      }
    },


    saveSelectionRange() {
      this.selectionRange = null;
      let selection = window.getSelection();
      if (selection.rangeCount === 0) return;
      let range = selection.getRangeAt(0);
      if (range.collapsed) return;

      this.selectionRange = {
        startIndex: this.getElementIndex(range.startContainer) + (range.startContainer.textContent.length > range.startOffset ? 0 : 1),
        endIndex: this.getElementIndex(range.endContainer) + (range.endOffset === 0 ? -1 : 0)
      }
    },

    restoreSelectionRange() {
      if (!this.selectionRange) return;
      window.getSelection().removeAllRanges();

      let range = document.createRange();
      range.setStart(this.$refs.contentDiv.childNodes[ this.selectionRange.startIndex ], 0);
      let endNode = this.selectionRange.endIndex >= this.editView.length - 1
          ? this.$refs.contentDiv.lastChild
          : this.$refs.contentDiv.childNodes[ this.selectionRange.endIndex ]
      range.setEndAfter(endNode);
      window.getSelection().addRange(range);
    },

    insertElementsToCurrentPosition(elements) {
      let result = this.selectionRange
          ? this.deleteSelected()
          : [...this.editView];
      result = [
        ...result.slice(0, this.cursorPosition + 1),
        ...elements,
        ...result.slice(this.cursorPosition + 1)]
      this.cursorPosition += elements.length;
      return result;
    },

    insertModifier(modifier, position) {
      this.saveState();
      this.cursorPosition = position
        ? (position === 'left' ? -1 : this.editView.length - 1)
        : this.cursorPosition;  
      
      let result = this.insertElementsToCurrentPosition([modifier]);
      this.selectionRange = null;
      this.$refs.contentDiv.focus();
      this.emitResult(result);
    },

    deleteSelected() {
      if (this.selectionRange) {
        this.cursorPosition = this.selectionRange.startIndex - 1;
        let result = [...this.editView.slice(0, this.selectionRange.startIndex), ...this.editView.slice(this.selectionRange.endIndex + 1)];
        this.clearSelection();
        return result;
      } else {
        return this.editView;
      }
    },

    doUndo() {
      let state = this.undoStack.pop();
      if (state) {
        let result = Object.assign({}, this.value, this.$primerHelper.parseEditView(state.view));
        this.cursorPosition = state.cursor;
        this.$emit('input', result);
      }
    },

    saveState() {
      this.undoStack.push({ cursor: this.cursorPosition, view: this.editView });
    },

    emitResult(src) {
      let result = Object.assign({}, this.value, this.$primerHelper.parseEditView(src));
      this.$emit('input', result);
    },

    getLineHeight() {
      let el = this.$refs.contentDiv.querySelector('.element-div');
      if (!el) return 0;
      return el.offsetHeight;
    },

  },

  computed: {


    editView() {
      return this.$primerHelper.buildEditView(this.value);
    },
    contentDivClasses() {
      let result = ['content-div'];
      let errors = this.$primerHelper.getErrorMessages(this.value, this.needModifier);
      errors = errors.filter(e => e.code !== 'scale');
      if (this.canBeEmpty) {
        errors = errors.filter(e => e.code !== 'empty');
      }
      if (errors.length > 0 ) {
        result.push('content-div-error');
      }
      if (this.focusOn) {
        result.push('content-div-focused')
      }
      return result;
    },
    html() {
      return this.$primerHelper.buildHtml(this.editView, this.focusOn ? this.cursorPosition : null);
    }
  }
}

</script>

<style>

.element-div {
  margin: 1px 0 1px 0;
  border-right: 1px solid transparent;
}

.element-div-error {
  color: red;
}

.element-div-current {
  border-right: solid 1px rgba(0, 255, 0, .75);
  animation: animated-cursor 1000ms steps(29, end) infinite;
}

.element-div-error.element-div-modifier div {
  background-color: #ffcfcf !important;
  color: red;
  border-color: red;
}

.element-div div {
  display: inline;
  font-family: Arial, fantasy;
  font-size: 12px;
  margin: 2px 0 2px 0;
  text-align: center;
}

.element-div-modifier div {
  padding-left: 2px;
  padding-right: 2px;
  user-select: all;
  -moz-user-select: all;
  -ms-user-select: all;
  -webkit-user-select: all;
  margin: 1px 0 1px 0;
  background-color: rgb(236, 245, 255);

  border: solid 1px rgb(198, 226, 255);
  border-radius: 3px;
  color: rgb(64, 158, 255);
  font-family: Arial, fantasy;
  font-size: 12px;
  outline-color: rgb(64, 158, 255);
  text-align: center;
  white-space: nowrap;
}

.element-div-firstInTpl {
  padding-left: 7px;
}

.first-element {
  border-left: 1px solid transparent;
}

.first-element-current {
  border-left: solid 1px black;
  animation: animated-cursor-left 1000ms steps(29, end) infinite;
}

.content-div {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 30px;
  caret-color: transparent;
  outline: 0;
  border: solid 1px #DCDFE6;
  border-radius: 4px;
  line-height: 22px;
}

.content-div-focused {
  border: solid 1px #51A5FC;
}

.content-div-error {
  border: solid 1px red;
}


.cursor-active {
  border-right: solid 1px rgba(0, 255, 0, .75);
  animation: animated-cursor 1000ms steps(29, end) infinite;
}

@keyframes animated-cursor {
  from {
    border-right-color: black
  }
  to {
    border-right-color: transparent;
  }
}

@keyframes animated-cursor-left {
  from {
    border-left-color: black
  }
  to {
    border-left-color: transparent;
  }
}


</style>