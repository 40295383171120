import _ from 'lodash';
import vue from 'vue';

export default {
  state: {
    appIsInit: false,
    loading: 0,
    currentUser:null,
    prices:null,
    enums:[],
    settings:null,
    ourAffiliations: [],
    openWindows:[]
  },
  mutations: {
    setLoading (state, payload) {
        state.loading += payload ? 1 : -1;
    },
    setCurrentUser(state, user) {
        state.currentUser = user;
    },
    
    
    setEnum(state, payload) {
          let idx = _.findIndex(state.enums, e => e.name === payload.name);
          if (idx >= 0) {
              state.enums.splice(idx, 1, payload);
          } else {
              state.enums.push(payload);
          }
      },
      
      
    setEnums(state, payload) {
        state.enums = payload;
    },
    
    setPrices(state, payload) {
      state.prices = payload;
    },
    
    setSettings(state, payload){
        state.settings = payload;
    },
    setOurAffiliations(state, payload) {
        state.ourAffiliations = payload;
    }
  },
  actions: {
      setLoading(context, payload) {
          context.commit('setLoading', payload);
      },
      
      async loadOurAffiliations(context) {
          let query = [{fieldName: 'isEvrogen', op:'eq', values:[true]}];
          let items = await context.dispatch('affiliations/loadItems', { query });
          context.commit('setOurAffiliations', items);
      },
      
      async buildDocs(context, payload) {
          return await vue.prototype.$myHttp.post('/api/reports/buildDocs', payload)
      },
    
      async loadCurrentUser(context) {
          const currentUser =  await vue.prototype.$myHttp.post('/api/managers/currentUser');
          context.commit('setCurrentUser', currentUser);
      },
      
      async objectInfo(context, {id, name} ) {
          return await vue.prototype.$myHttp.post('/api/support/getObjectInfo', {name, id});
      },
    
      async loadEnumValues(context, name) {
          let values;
          switch (name) {
              case 'countries': {
                  values = await vue.prototype.$myHttp.post('/api/countries/findCountries',  {orderBy: 'Name', orderIsDesc: false});
                  break;
              }
              case 'seqFragmentTypes': {
                  values = await vue.prototype.$myHttp.post('/api/sequenceTypes/findSequenceTypes',  {});
                  break;
              }
              case 'mailTemplates': {
                  values = await vue.prototype.$myHttp.post('/api/mailTemplates/findMailTemplates',  {});
                  break;
              }
              case 'deliveryServices': {
                  values = await vue.prototype.$myHttp.post('/api/deliveryServices/findDeliveryServices', {});
                  break;
              }
              case 'mailShortTemplates': {
                  values = await vue.prototype.$myHttp.post('/api/mailShortTemplates/findMailShortTemplates', {});
                  break;
              }
              
              default : {
                  values = await vue.prototype.$myHttp.post('/api/utils/names',  JSON.stringify(name));
                  break;
              }
          }
          let result = {name, values};
          context.commit('setEnum', result );
          return result;
      },
    
      async loadEnums(context) {
          let values = await vue.prototype.$myHttp.post('/api/utils/GetEnums',  JSON.stringify(name));
          context.commit('setEnums', values );
          await Promise.all([
                                context.dispatch('loadEnumValues', 'countries'),
                                context.dispatch('loadEnumValues', 'seqFragmentTypes'),
                                context.dispatch('loadEnumValues', 'mailTemplates'),
                                context.dispatch('loadEnumValues', 'deliveryServices'),
                                context.dispatch('loadEnumValues', 'mailShortTemplates')
                            ]);
      },
    
      async loadChanges(context, payload) {
          const response = await vue.prototype.$myHttp.post('/api/changes/GetChanges', payload);
          return response;
      },
      
      async saveChanges(context, payload) {
          const response = await vue.prototype.$myHttp.post('/api/changes/SaveChanges', payload);
          return response;
      },
    
      async loadPages() {
          return await vue.prototype.$myHttp.post('/api/pages/list');
      },
    
      async loadActions() {
          return await vue.prototype.$myHttp.post('/api/roles/actions');
      },
      
      async loadSettings(context) {
          const result =  await vue.prototype.$myHttp.post('/api/utils/getSettings');
          context.commit('setSettings', result);
      }
  },
  getters: {
    loading (state) {
      return state.loading
    },
    error (state) {
      return state.error
    },
  }
}
