<template>
    <el-select
            v-bind="$attrs"
            v-on="$listeners"
            @change="onChange"
    >
        <el-option 
            v-if="isMultiple && checkbox"
            value="checkbox" :disabled="true">
            <div style="display:flex;justify-content: space-between" @click.stop>
                Все<el-checkbox :value="selected" :indeterminate="indeterminate" @change="changeCheckBox"></el-checkbox>
            </div>
        </el-option>
        <slot></slot>
    </el-select>
</template>

<script>
    export default {
        name: "myElSelect",
        props: {checkbox: {type:Boolean, default:true}},
        data() {
            return {
                selected: false,
                indeterminate: false,
                selectAll: false,
                isWatch: false
            }
        },
        methods: {
            onChange(value) {
                this.indeterminate =  this.isMultiple && value.length > 0 && value.length < this.$slots.default.length  
            },
            
            changeCheckBox(value) {
                this.indeterminate = false;
                this.selected = value;
                let items = value ? this.$slots.default.map(i => i.child.value) : [];
                this.$emit('input', items);
                this.$emit('change', items);
            }
        },
        computed: {
            isMultiple() {
                return typeof this.$attrs.multiple !== 'undefined' && this.$attrs.multiple !== false;
            }
        }
    }
</script>
<style scoped>

</style>