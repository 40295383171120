<template functional>
  <div style="display: flex;flex-direction: column">
    <div style="display: flex">
      <div style="display: flex;flex-shrink: 0; flex-wrap: nowrap;margin-right: 2px"
           :style="{width: props.maxIconsCount * 14 + 'px'}">
        <slot></slot>
        <div style="width:12px;flex-shrink: 0">
          <el-popover v-if="$options.getComment(props.object)" placement="top-start" trigger="hover">
            <div style="display: flex;flex-direction: column">
              <div style="display: flex;flex-direction: column">
                <div style="font-size:16px; font-weight: bold; color: #409EFF ">Важный комментарий</div>
                <pre style="margin:0;border:0;outline: 0">{{ $options.getComment(props.object) }}</pre>
              </div>
            </div>
            <i class="el-icon-info" slot="reference" style="cursor: pointer;font-size: 12px;color:#409EFF;margin-right: 3px"></i>
          </el-popover>
        </div>
      </div>
      <div :class="props.disabled ? 'dis-my-link': 'my-link'"
           :style="`display:flex ${!props.wordBreak ? ';white-space:nowrap':''}${props.wordBreak? (';word-break:' + props.wordBreak ): '' }`"
           @click="!props.disabled && listeners['click']()"
           @mousedown.stop.prevent
           @click.middle.prevent.stop="!props.disabled && $options.openNewWindow(props.object)">
        {{ $options.getTitle(props.object) }}
      </div>
    </div>
    <div style="display: flex;"
         :style="{marginLeft: (props.maxIconsCount * 14 + 2) + 'px'}"
    >
      <slot name="second-row"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "myTypeLink",
  props: {
    maxIconsCount: { default: 1 },
    object: { default: null },
    disabled: { default: false },
    space: { default: true },
    wordBreak: { default: 'break-word' }
  },
  openNewWindow(object) {
    if (object.objectTypeName === 'invoice' && object.hasChildren) {
      window.open(`/stockInvoice/${object.id}`, '_blank');
    } else {
      window.open(`/${object.objectTypeName}/${object.id}`, '_blank');
    }
  },
  getTitle(object) {
    switch ( object?.objectTypeName ) {
      case 'user':
        return object.showName || '-';
      case 'client':
        return (object.name + (object.isPerson ? ' (сам)' : '')) || '-';
      case 'invoice':
        return object.number || '-';
      case 'delivery':
        return '#' + object.id;
      case 'affiliation':
        return object.name || '-';
      default:
        return '';
    }
  },
  getComment(object) {
    switch ( object?.objectTypeName ) {
      case 'affiliation':
      case 'client':
      case 'user':
        return object.importantComment?.trim();
      case 'delivery':
      case 'invoice':
        return object.comment?.trim();
      default:
        return '';
    }
  }
}
</script>

<style>
.el-popover {
  min-width: 0 !important;
}
</style>
