import _ from "lodash";
import {alert} from '@/components/common/dialogs/dialogUtils';
import {store} from '@/store'

export default {
    install (Vue) {
        Vue.prototype.$showWindow = async function(component, params, onClose, sizes) {
            params = params || {};
            let windowName = `wnd_${component.name}_${params.id || params.subOrderId || '_noId'}`;   
            if (store.state.shared.openWindows.indexOf(windowName) >= 0) {
                await alert('Уже есть открытое окно с такой записью.');
                return;
            } else {
                store.state.shared.openWindows.push(windowName);
            }
        
            sizes = sizes ||  component.data().windowSize;
            let myStore = localStorage[ `save_${component.name}_componentWindow` ];
            if (myStore) {
                myStore = JSON.parse(myStore);
                let fndSize = _.find(myStore, s => s.key === 'form_currentSize');
                if (fndSize) {
                    sizes.width = fndSize.value.width + fndSize.value.widthType;
                    sizes.height = fndSize.value.height + fndSize.value.heightType;
                }
            }
        
            this.$modal.show(component, { ...params, onClose },
                             { ...sizes, adaptive: true, clickToClose: false,
                                 resizable: true, draggable: '.window-header',
                                 name: windowName, transition: null, overlayTransition:null},
                             {
                                 'closed': value => {
                                     let idx = store.state.shared.openWindows.indexOf(value.name);
                                     if (idx >= 0) {
                                         store.state.shared.openWindows.splice(idx, 1);
                                     }
                                 }
                             }
            );
            return windowName;
        };
    
        Vue.prototype.$showWindowAsync = async function(component, params, sizes) {
            return new Promise(resolve => {
                this.$showWindow(component, params, result => {
                    resolve(result)
                }, sizes)
            });
        };
    }
}