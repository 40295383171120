import { render, staticRenderFns } from "./confirmWithLogTemplate.vue?vue&type=template&id=7b15ffba&scoped=true"
import script from "./confirmWithLogTemplate.vue?vue&type=script&lang=js"
export * from "./confirmWithLogTemplate.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b15ffba",
  null
  
)

export default component.exports