<template>
    <component-window
            :title="title"
            :lock-manager="null"
            @close="$emit('close'); resolve(true)" >
        <div style="display:flex;flex-direction: column; width:100%;height:100%;justify-content: flex-start ">
            <div v-html="message" style="display: flex;flex: 0 0; padding-bottom: 5px"></div>
            <div style="display: flex;flex: 1 1;height:100%;">
                <el-input type="textarea"
                          style="display: flex"
                          readonly
                          resize = "none"
                          v-model="log"/>
            </div>
        </div>
        <template slot="footer">
            <el-button @click="$emit('close'); resolve(true)"
                       type="success">Ok</el-button>
        </template>
    </component-window>
</template>

<script>
    export default {
        name: 'alertWithLogTemplate',
        props: ['title', 'message', 'resolve', 'log']
    }
</script>

<style scoped>

</style>