<template>
  <component-window
      :title="title"
      @close="onBtnCancelClick"
  >
    <div ref="content" style="font-size: 14px; font-weight: normal">
      <json-view rootKey="Заказ оформленный на сайте" :data="prepare(object)" class="customize"
      />
    </div>
    <div slot="footer" style="display: flex; justify-content: space-between;width: 100%">
      <el-switch v-model="showAllFields" active-text="Показывать все поля"></el-switch>
      <my-el-button @click="onBtnCancelClick" type="warning">Закрыть</my-el-button>
    </div>
  </component-window>
</template>

<script>

import { JSONView } from 'vue-json-component';

export default {
  name: 'JsonViewer',
  props: ['id', 'object', 'onClose'],
  components: { 'json-view': JSONView },
  data() {
    return {
      windowSize: { minHeight: 400, minWidth: 400, height: '95%', width: '95%' },
      showAllFields:false,
      description: new Map([
                             ['/metaorder', { title: 'Заказ' }],
                             ['/metaorder/id', { title: 'Id' }],
                             ['/metaorder/createdAt', { title: 'Дата' }],
                             ['/metaorder/currency', { title: 'Валюта' }],
                             ['/metaorder/orders', { title: 'Подзаказы' }],
                             ['/metaorder/orders/elements', { title: 'Элементы', fn: this.prepareOrderElement }],
          
          
          
          
          
                             ['/metaorder/orders/deadline', { title: 'Дедлайн'}],
                             ['/metaorder/orders/number', { title: 'Номер'}],
                             ['/metaorder/orders/price', {title:'Сумма'}],
                             ['/metaorder/orders/discountPrice', {title: 'Сумма со скидкой'}],
                             ['/metaorder/orders/id', { title: 'Id' }],
                             ['/metaorder/orders/discountPercent', { title: 'Скидка, %' }],
                             
          
                             ['/metaorder/number', { title: 'Номер' }],
                             ['/metaorder/user', { title: 'Заказчик' }],
                             ['/metaorder/user/id', { title: 'Id' }],
                             ['/metaorder/user/fio', { title: 'ФИО' }],
                             ['/metaorder/user/login', { title: 'Логин' }],
                             ['/metaorder/user/oldLogin', { title: 'Старый логин'}],
                             
                             ['/metaorder/user/contacts', { title: 'Контакты', fn: o => o.contact }],
                             ['/metaorder/client', { title: 'Клиент' }],
                             ['/metaorder/client/id', { title: 'Id' }],
                             ['/metaorder/client/', { title: 'Клиент' }],
                             ['/metaorder/client/addresses', { title: 'Адреса', fn: this.prepareAddress }],
                             ['/metaorder/client/affiliation', { title: 'Организация' }],
                             ['/metaorder/client/affiliation/id', { title: 'Id' }],
                             ['/metaorder/client/affiliation/name', { title: 'Название' }],
                             ['/metaorder/client/country', { title: 'Страна', fn: o => o.name }],
                             ['/metaorder/client/name', { title: 'Название' }],

                             ['/metaorder/billingAffiliation', { title: 'Плательщик' }],
                             ['/metaorder/billingAffiliation/id', { title: 'Id' }],
                             ['/metaorder/billingAffiliation/name', { title: 'Название' }],

                             ['/metaorder/ordersSum', { title: 'Сумма' }],
                             ['/metaorder/payType', { title: 'Тип оплаты', fn: o => this.$getEnumValue('PayTypeEnum', o) }],

                             ['/delivery', { title: 'Доставка'}],
                             ['/delivery/id', { title: 'Id' }],
                             ['/delivery/clientId', { title: 'Id клиента' }],
                             ['/delivery/recipientId', { title: 'Id получателя' }],
                             ['/delivery/woDelivery', { title: 'Самовывоз', fn: o =>  o ? 'Да': null }],
                             ['/delivery/address', { title: 'Адрес', fn: this.prepareAddress }],
                           ]
      )
    }
  },
  async mounted() {
    this.$refs.content.querySelectorAll('span.properties')
    .forEach(el =>el.style.display = 'none');
  },

  methods: {
    onBtnCancelClick() {
      this.$emit('close');
    },

    prepare(srcObj, path = '') {
      let res = Object.entries(srcObj)
      .reduce((acc, [key, value]) => {
        if (!value || (Array.isArray(value) && !value.length)) return acc;

        let description = this.description.get(`${path}/${key}`);
        if (!this.showAllFields && !description) return acc;
        let name = description?.title || `${path}/${key}`;
        let val = this.parseValue(value, `${path}/${key}`, this.showAllFields ? null : description?.fn);
        if (val !== null) {
          acc.push([name, val]);
        }
        return acc;
      }, [])
      return Object.fromEntries(res);
    },
    parseValue(value, path, fn) {
      if (typeof value === 'object') {
        return Array.isArray(value)
            ? value.map(v => fn ? fn(v) : this.prepare(v, path))
            : fn ? fn(value) : this.prepare(value, path);
      }
      return fn ? fn(value) : `${value}`;
    },

    prepareAddress(src) {
      return src ? this.$addressToString(src) : null;
    },

    prepareOrderElement(el) {

      let result = {
        Id: el.id,
        'Цена': el.price,
      };
      if (el.discountPrice) {
        result['Цена со cкидкой'] = el.discountPrice;
      }

      if (el.erpId !== undefined) {
        let primerView = this.$primerHelper.buildEditView(el);

        result[ 'Название' ] = el.name;
        result[ 'Номер в заказе' ] = el.numberInOrder;
        result[ 'Шкала' ] = el.scale;
        result[ 'Праймер' ] = this.$primerHelper.buildText(primerView, true);
        if (el.optionsCleaning) result[ 'Очистка' ] = 'Да';
        if (el.optionsKeen) result[ 'Кинирование' ] = 'Да';
        return result;
      }
      result['Количество'] = el.countAll;
      result['CAT'] = el.product?.cat;
      if (el.countDiscount) {
        result['Количество со скидкой'] = el.countDiscount;
      }
      if (el.bulk) {
        result['Балк'] = el.bulk.cat;
      }

      return result;
    }
  },

}
</script>

<style lang="scss">
.chevron-arrow {
  border-right-width: 1px !important;
  border-bottom-width: 1px !important;

}

.customize {

}
</style>