<template functional>
    <div v-if="props.comment && props.comment.replace(/\s/g, '').length > 0">
        <el-popover v-if="props.comment.split('\n').length > 1 || props.comment.trim().length > props.length" placement="top-start" trigger="hover">
            <div style="font-size:16px; font-weight: bold; color: #409EFF ">Важный комментарий</div>
            <pre style="margin:0;border:0;outline: 0">{{props.comment}}</pre>
            <el-tag slot="reference"
                    type="warning">
              <i class="el-icon-warning" style="color:red"></i>
              {{props.comment.split('\n').filter(s => s.trim().length > 0)[0].substring(0, props.length) }} ...
            </el-tag>
        </el-popover>
        <el-tag v-else type="warning">
          <i class="el-icon-warning" style="color:red"></i>
          {{props.comment}}
        </el-tag>
    </div>
</template>

<script>
    export default {
       name: "myComment",
       props: {
            comment: {default: ''},
            length: {default: 20}
       }
    }
</script>

<style>
    .el-popover {
        min-width: 0 !important;
    }
</style>
