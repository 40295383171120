import { render, staticRenderFns } from "./MoveOrdersWindow.vue?vue&type=template&id=0fdd5eea&scoped=true"
import script from "./MoveOrdersWindow.vue?vue&type=script&lang=js"
export * from "./MoveOrdersWindow.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fdd5eea",
  null
  
)

export default component.exports