<template>
    <el-popover v-if="changeState && !(changeState === 'update' && onlyInsert) " placement="top-start" trigger="hover">
        <div v-if="changeState === 'update'" style="display: flex;flex-wrap: nowrap;align-items: center">
            <div style="margin-right: 10px">Запись измненена</div>
            <my-el-button check @click="confirmUpdateChange">Принять все изменения</my-el-button>
        </div>
        <div v-else style="display: flex;flex-wrap: nowrap;align-items: center">
            <div style="margin-right: 10px">Новая запись</div>
            <my-el-button check @click="confirmNewChange"></my-el-button>
        </div>
        <i :class="changeState === 'update' ? 'el-icon-success' : 'el-icon-circle-plus'" slot="reference" style="cursor: pointer;font-size: 12px;color:silver;margin-right: 2px"></i>
    </el-popover>
</template>

<script>
    import _ from 'lodash';
    import {alert} from '../common/dialogs/dialogUtils';
    export default {
        name: 'changePopup',
        props: {
            onlyInsert: {default: false},
            changes: {default: null},
            field: {default: ''},
            id: {default: ''},
            updateBase: {default: false}
        },
        computed: {
            fullFieldName() {
                return this.field + ( this.id ? `:(${this.id})` : '');
            },
            changeState() {
                if (!this.changes) {
                    return '';
                }
                let selFn = ch =>
                    !ch.forDelete
                    &&  ch.objectAction === 'insert'
                    &&  ( (this.fullFieldName &&  ch.fieldName.indexOf(this.fullFieldName) === 0 )
                           || (!this.fullFieldName &&  !ch.fieldName ));


                if ( this.changes.some( selFn)) {
                    return 'insert';
                }

                selFn = ch =>
                       !ch.forDelete
                    && !ch.processed
                    &&  ch.objectAction !== 'delete'
                    &&  ( !this.fullFieldName  ||  ch.fieldName.indexOf(this.fullFieldName) === 0 );
                if ( this.changes.some( selFn )) {
                    return 'update';
                }
                return '';
            }
        },

        methods: {
            async confirmNewChange() {
                let fndIdx = _.findIndex(this.changes, ch => ch.objectAction === 'insert' && ch.fieldName === this.fullFieldName);
                this.changes[fndIdx].forDelete = true;
                if (this.updateBase) {
                    try {
                        await this.$store.dispatch('saveChanges', [ this.changes[ fndIdx ] ]);
                    } catch(ex) {
                        this.changes[fndIdx].forDelete = false;
                        alert("Ошибка сохранения: " + ex.message);
                        return;
                    }
                    this.changes.splice(fndIdx, 1);
                }
            },

            async confirmUpdateChange() {
               let fndChanges = this.changes
                  .filter(ch =>
                         !ch.forDelete
                      && !ch.processed
                      && ch.objectAction !== 'delete'
                      && (!this.fullFieldName || ch.fieldName.indexOf(this.fullFieldName) === 0));
               fndChanges.forEach(ch => {
                   (ch.objectAction === 'insert') && (ch.forDelete = true);
                   (ch.objectAction === 'update') && (ch.processed = true);
               });
               if (this.updateBase) {
                   try {
                   await this.$store.dispatch('saveChanges',  fndChanges );
                   } catch(ex) {
                       fndChanges.forEach(ch => ch.processed = false);
                       alert("Ошибка сохранения: " + ex.message);
                   }
               }
            },
        }
    }


</script>

<style scoped>

</style>
