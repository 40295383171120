<template>
  <el-card style="display: flex; flex-direction: column; width: 100%;margin-top: 10px">
    <div style="text-align:center; color: #333333;font-size: 18px;fill: #333333;font-family: 'Lucida Grande', 'Lucida Sans Unicode', Arial, Helvetica, sans-serif;">
      {{ title }}
    </div>
    <div v-if="!outerFilter" style="display: flex;flex-wrap: nowrap" >
      <div style="display: flex;flex-direction: column;flex-shrink: 0;  padding-bottom:3px; position: sticky; top: 0;z-index: 100; background-color: white">
        <div style="display: flex;margin-bottom: 5px;align-items: center">
          <div style="display: flex;align-items: center">
            C
            <el-date-picker
                style="width: 150px;margin-left: 5px;margin-right: 10px"
                v-model="filter.range[0]"
                type="date"
                :editable="true"
                default-time="00:00:00"
                :picker-options="{firstDayOfWeek: 1}"

            >
            </el-date-picker>
            По
            <el-date-picker
                style="width: 150px;margin-left: 5px;margin-right: 10px"
                v-model="filter.range[1]"
                type="date"
                :editable="true"
                default-time="23:59:59"
                :picker-options="{firstDayOfWeek: 1}"
            >
            </el-date-picker>
          </div>
          <my-el-select
              v-model="filter.clientType"
              collapse-tags
              style="width:200px;margin-left: 20px"
              placeholder="Тип клиента"
              :value="filter.clientType"
              clearable>
            <el-option label="Внешний" value="outer"/>
            <el-option label="Внутренний" value="inner"/>
          </my-el-select>
          <div v-if="!filter.clientType || filter.clientType === 'outer'" style="margin-left: 20px">
            <my-selector
                type="client"
                v-model="filter.client"
                with-find-text
                @enter-find-text="filter.clientText = $event"
            />
          </div>
          <div v-if="!filter.clientType || filter.clientType === 'outer'" style="margin-left: 20px">
            <my-selector
                type="user"
                v-model="filter.user"
                with-find-text
                @enter-find-text="filter.userText = $event"
            />
          </div>
          <div v-if="!filter.clientType || filter.clientType === 'outer'" style="margin-left: 20px" multiple>
            <my-selector
                type="affiliation"
                v-model="filter.affiliation"
                with-find-text
                @enter-find-text="filter.affiliationText = $event"
            />
          </div>
          <div v-if="filter.clientType === 'inner'" style="margin-left: 20px">
            <el-select v-model="filter.clients" multiple placeholder="Клиенты" value-key="id" clearable>
              <el-option
                  v-for="client in innerClients"
                  :key="client.id"
                  :label="client.name"
                  :value="client.id">
                <div>{{ client.name }}</div>
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
    </div>
    <div style="width: 1200px;margin: auto">
      <Highcharts :options="chartOptions" ref="chart"/>
    </div>
    <el-table :data="tableData"
              style="width: 100%;font-size: 16px"
              :border="true"
              :span-method="spanMethod"
              :cell-style="cellStyleMethod"
              :row-style="({row}) => row.name === 'Итого' ? {backgroundColor: '#F4F4F5'} : null "
    >
      <el-table-column width="10" fixed="left">
        <template slot-scope="scope">
          {{ scope.row.name ? '' : 'Остальные:' }}
        </template>
      </el-table-column>
      <el-table-column width="150" label="Операция" fixed="left">
        <div slot-scope="scope" style="word-break: break-word">
          {{ scope.row.name }}
        </div>
      </el-table-column>
      <template v-for="hdrRow in rowForTableHeader">
        <el-table-column v-for="quarter in hdrRow.quarters"
                         align="right"
                         :label="`${quarter.quarter}кв-${hdrRow.year.toString().substring(2)}`"
                         width="80"
                         :key="quarter.quarter + ' ' + hdrRow.year">
          <template slot-scope="scope">
            <div v-if="scope.row.name !== 'Итого'" style="white-space: nowrap;font-size: 12px">
              {{ !scope.row.name ? '' : getQuarterValue(scope.row, hdrRow.year, quarter.quarter).toLocaleString() }}
            </div>
            <div v-else style="white-space: nowrap;font-size: 12px">
              {{ sumByQuarter(hdrRow.year, quarter.quarter).toLocaleString() }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
            width="80"
            align="right"
            :key="`sumClmn_${hdrRow.year}`">
          <template v-slot:header>
            <div style="font-weight: bold; color: black" v-html="`Итого за ${hdrRow.year}`"/>
          </template>
          <div slot-scope="scope" style="font-weight: bold; white-space: nowrap;font-size: 12px"
          >
            <template v-if="scope.row.name !== 'Итого'">
              {{ !scope.row.name ? '' : getYearSum(scope.row, hdrRow.year).toLocaleString() }}
            </template>
            <template v-else>
              {{ sumByYear(hdrRow.year).toLocaleString() }}
            </template>
          </div>
        </el-table-column>
      </template>
    </el-table>
  </el-card>
</template>

<script>
import { genComponent } from "vue-highcharts";
import Highcharts from "highcharts";

import loadExporting from 'highcharts/modules/exporting'
import loadOfflineExporting from 'highcharts/modules/offline-exporting'
import _ from "lodash";

loadExporting(Highcharts);
loadOfflineExporting(Highcharts);


export default {
  name: "QuarterGroupsChartSequence",
  components: {
    Highcharts: genComponent('Highcharts', Highcharts)
  },
  props: {
    currency: {default: 'rub'},
    showTop: { default: 0 },
    addSumRow: { default: false, type: Boolean },
    outerFilter: { default: null },
    title: {default: 'Отчет по операциям (По дате завершения заказа)'},
    productType: {default: ''},
  },
  data() {
    return {
      data: [],
      filter: {
        range: [new Date((new Date()).getFullYear() - 3, 0, 1), new Date()],
        clientType: 'outer',
        client: null,
        clientText: '',
        affiliation: null,
        affiliationText: '',
        clients: [],
        user: null,
        userText: '',
        clientFndText: '',
      },
      innerClients: [],
    }
  },
  watch: {
    preparedFilter: {
      handler: function() {
        this.load()
      },
      deep: true
    }
  },

  async mounted() {
    const query = [{ fieldName: 'IsSpecialClient', op: 'eq', values: [true] }];
    this.innerClients = await this.$store.dispatch('clients/loadItems', { query });
    await this.load();
  },
  methods: {
    async load() {
      let filter = _.cloneDeep(this.preparedFilter);
      filter.reportType = this.productType;
      this.data = await this.$store.dispatch('stateReports/getGroupQuarterYearAndPrmReport', filter);
    },

    spanMethod({ rowIndex, columnIndex }) {
      // Строка-заголовок 'Остальные:'
      if (rowIndex === this.showTop) {
        return columnIndex === 0
            ? { rowspan: 1, colspan: this.columnCount + 3 }
            : { rowspan: 0, colspan: 0 }
      }
      // Обычные строки
      if (rowIndex < this.showTop || columnIndex !== 0) {
        return { rowspan: 1, colspan: 1 }
      }
      // Первая строка из осталorьных
      if (rowIndex === this.showTop + 1) {
        return { rowspan: this.data.length - this.showTop + 1, colspan: 1 }
      }
      // Не первые строки из остальных
      return { rowspan: 0, colspan: 0 }
    },

    cellStyleMethod({ row, columnIndex, rowIndex }) {
      return columnIndex !== 0 || rowIndex === 10
          ? null
          : { backgroundColor: this.chartColors()[ row.name ] || this.chartColors()[ 'Остальные' ] };
    },

    chartColors() {
      let res = this.$refs.chart.chart.series.map(s => [s.name, s.color]);
      return Object.fromEntries(res);
    },

    getQuarterValue(row, year, quarter) {
      let fndYear = row.years.find(yearRow => yearRow.year === year);
      return fndYear.quarters.find(qrRow => qrRow.quarter === quarter).sum[ this.currency ];
    },

    getYearSum(row, year) {
      return row.years.find(yearRow => yearRow.year === year).allSum[ this.currency ];
    },

    sumByQuarter(year, quarter) {
      return this.data
      .map(row => row.years.find(rowYear => rowYear.year === year).quarters
      .find(rowQuarter => rowQuarter.quarter === quarter)
          .sum[ this.currency ])
      .reduce((acc, sum) => acc + sum, 0);
    },
    sumByYear(year) {
      return this.data
      .map(row => row.years.find(rowYear => rowYear.year === year)
          .allSum[ this.currency ])
      .reduce((acc, sum) => acc + sum, 0);
    },

    buttons() {
      return {
        buttons: {
          contextButton: {
            menuItems: [
              { separator: true },
              {
                text: 'Печать',
                onclick: function() {
                  this.print();
                }
              },
              {
                text: 'Скачать',
                onclick: function() {
                  this.exportChart({ type: 'image/jpeg' });
                }
              }]
          }
        }
      }
    },
  },

  computed: {
    preparedFilter() {
      if (this.outerFilter) {
        return this.outerFilter;
      }

      return {
        range: this.filter.range,
        clientType: this.filter.clientType,
        clientId: this.filter.client?.id,
        clientText: this.filter.clientText,
        affiliationId: this.filter.affiliation?.id,
        affiliationText: this.filter.affiliationText,
        clientIds: this.filter.clients,
        userId: this.filter.user?.id,
        userText: this.filter.userText,
      }
    },


    preparedData() {
      return this.data.map(row => ({
        name: row.name,
        data: row.years.reduce((acc, year) => acc.concat(year.quarters.map(q => q.sum[ this.currency ])), [])
      }));
    },

    chartOptions() {
      return {
        exporting: this.buttons('currency'),
        title: {
          text: ''
        },
        xAxis: {
          categories: this.chartCategories,
          gridLineWidth: 1,
          title: { text: 'Кварталы' }
        },
        yAxis: {
          min: 0,
          title: {
            text: `Продажи, ${this.$getEnumValue('CurrencyEnum', this.currency)}`
          }
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0
          },
          series: {
            marker: {
              symbol: 'circle'
            }
          }
        },
        legend: {
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'middle'
        },
        series: this.chartSeries
      }
    },

    chartCategories() {
      return !this.data?.length
          ? null
          : this.data[ 0 ].years.reduce((acc, year) =>
                                            acc.concat(year.quarters.map(q => `${q.quarter}кв-${year.year.toString().substring(2)}`)),
                                        []);
    },

    chartSeries() {
      let result = this.preparedData;
      if (this.showTop !== 0 && this.preparedData.length > this.showTop) {
        result = result.filter((_, idx) => idx < this.showTop);
        let otherLine = {
          name: 'Остальные',
          data: _.zipWith(...this.preparedData.filter((_, idx) => idx >= this.showTop).map(r => r.data), (...args) => _.sum(args))
        }
        result.push(otherLine);
      }
      return result;
    },

    tableData() {
      let result = this.data.length <= this.showTop
          ? this.data
          : this.data.filter((_, idx) => idx < this.showTop)
          .concat([{ name: '', data: [], sum: 0 }])
          .concat(this.data.filter((_, idx) => idx >= this.showTop));
      if (this.addSumRow) {
        result = result.concat([{ name: 'Итого', data: [], sum: 0 }]);
      }
      return result;
    },

    rowForTableHeader() {
      return this.tableData?.length
          ? this.tableData[ 0 ].years
          : [];
    },

    columnCount() {
      return this.tableData?.length
          ? this.tableData[ 0 ].years.length + this.tableData[ 0 ].years.reduce((acc, year) => acc + year.quarters.length, 0)
          : 0;
    },
  }
}
</script>

<style scoped>

</style>
