<template>
    <div class="draggable" :class="{dragging}" :style="{top:top + 'px', left:left + 'px', zIndex:z, width:width}"  @mousedown="updateZ">
        <div class="header" @mousedown="dragStart">
            {{header}}
            <i @click="$emit('close')" style="margin-left: 10px;cursor: pointer;color: initial" class="el-icon-close"></i>
        </div>
        <div class="content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    let z = 1;
    export default {
        name: "FloatWindow",
        props:{header : {type: String}, width: {default: '370px'}},
        data(){
            return {
                top:0,
                left:0,
                z:1,
                dragging:false
            }
        },
        methods:{
            move(e){
                if(e.pageY > 0 && e.pageY < document.body.clientHeight){
                    this.top += e.movementY
                }
                if(e.pageX > 0 && e.pageX < document.body.clientWidth){
                    this.left += e.movementX
                }
            },
            dragStart(){
                this.dragging = true;
                document.addEventListener('mousemove', this.move);
                document.addEventListener('mouseup', this.dragStop);
                document.addEventListener('mouseleave', this.dragStop);
            },
            dragStop(){
                this.dragging = false
                document.removeEventListener('mousemove', this.move);
                document.addEventListener('mouseup', this.dragStop);
                document.removeEventListener('mouseleave', this.dragStop);
            },
            updateZ(){
                this.z = z += 1
            }
        }
    }
</script>

<style scoped>
    body {
        overflow-x: hidden;
    }
    .draggable {
        position: relative;
        background: white;
       /* box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5); */
        border-radius: 3px;
        box-shadow: 0 20px 60px -2px rgba(27, 33, 58, 0.4);
        transition: box-shadow 0.2s;
        margin-bottom: 10px;
    }
    .draggable.dragging {
        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.5);
        z-index: 200;
    }
    .draggable .header {
        display: flex;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;

        margin: 5px;
        justify-content: space-between;
        padding: 0 0 5px;
        border-bottom: 1px solid rgb(64, 158, 255);
        font-size: 16px;
        font-weight: bold;
        color: rgb(64, 158, 255);
    
        cursor: move;
        user-select: none;
    }
    .draggable .content {
        padding: 0 5px 5px 5px;
    }
</style>