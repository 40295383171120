import vue from 'vue';

export default function buildMergeUsers()
{
    return {
        namespaced: true,
        actions: {
            async GetClientsIdsWithSingleUsers(context, payload) {
                return await vue.prototype.$myHttp.post('/api/mergeUsers/GetClientsIdsWithSingleUsers', payload);
            },
            async DoMergeAndDelete(context, payload) {
                return await vue.prototype.$myHttp.post('/api/mergeUsers/DoMergeAndDelete', payload);
            }
        }
    };
}
