<template>
    <el-table
            :data="data ? data.filter(r => !r.forDelete) : []"
            style="width: 100%;font-size: 16px;flex-grow: 1"
            height="100%"
            :border="true"
            v-ls-saver:billingTable
    >
        <el-table-column label="Клиент" :resizable="true" v-if="!!affiliationId || affiliationId === 0 ">
            <template slot-scope="scope">
                <el-form v-if="currentEdit.id === scope.row.id" label-width="0" :model="currentEdit" :rules="rules">
                    <el-form-item prop="client">
                        <my-selector
                                type="client"
                                v-model="currentEdit.client"
                                placeholder=""
                                buttons
                                no-edit
                                @change="currentEdit.clientId = currentEdit.client ? currentEdit.client.id : ''"
                                @edit="onEditClient(currentEdit.clientId, currentEdit)"
                                @add="onEditClient(0, currentEdit)"
                        />
                    </el-form-item>
                </el-form>
                <div v-else class="my-link" @click="onEditClient(scope.row.clientId, scope.row)">
                    {{scope.row.client ? scope.row.client.name : ''}}
                </div>
            </template>
        </el-table-column>
        <el-table-column label="Организация" :resizable="true" v-if="!!clientId || clientId === 0">
            <template slot-scope="scope">
                <el-form v-if="currentEdit.id === scope.row.id" label-width="0" :model="currentEdit" :rules="rules">
                    <el-form-item prop="affiliation">
                        <my-selector
                                type="affiliation"
                                v-model="currentEdit.affiliation"
                                placeholder=""
                                buttons
                                no-edit
                                @change="currentEdit.affiliationId = currentEdit.affiliation ? currentEdit.affiliation.id : ''"
                                @edit="onEditAffiliation(currentEdit.affiliationId, currentEdit)"
                                @add="onEditAffiliation(0, currentEdit)"
                        />
                    </el-form-item>

                </el-form>
                <div v-else class="my-link" @click="onEditAffiliation(scope.row.affiliationId, scope.row)">
                    {{scope.row.affiliation ? scope.row.affiliation.name : ''}}
                </div>
            </template>
        </el-table-column>
        <el-table-column label="Контакт" :resizable="true">
            <template slot-scope="scope">
                <div style="display: flex;flex-wrap: wrap">
                    <template v-for="(userRl) in currentEdit.usersRl.filter( r => !r.forDelete)">
                        <el-tag
                                v-if="currentEdit.id === scope.row.id"
                                @close="removeUser(userRl)"
                                closable
                                :key="userRl.userId"
                                style="margin-right: 10px">
                            {{userRl.user.showName}}
                        </el-tag>
                    </template>
                    <template v-for="(userRl, idx) in scope.row.usersRl.filter( r => !r.forDelete)">
                        <div v-if="currentEdit.id !== scope.row.id"
                             :key="userRl.userId + '_'"
                             style="display:flex;margin-right: 20px;flex-shrink: 0">
                            <div :class="currentEdit.id || currentEdit.id === 0 ? '': 'my-link'" @click="onEditUser(userRl.userId, userRl)">
                                {{userRl.user.showName}}
                            </div>
                            <div v-html="idx < scope.row.usersRl.filter( r => !r.forDelete).length - 1 ? ',' :''"></div>
                        </div>
                    </template>
                </div>
                <div v-if="currentEdit.id === scope.row.id" style="display: flex;flex-wrap: nowrap;justify-content: stretch;margin-top: 5px">
                    <el-form label-width="0" :model="model" style="flex-grow: 1" :rules="rules">
                        <el-form-item prop="user">
                            <my-selector
                                    type="user"
                                    v-model="model.user"
                                    placeholder="Контактное лицо"
                                    buttons
                                    no-edit
                                    @add="onEditUser(0, model)"
                            />
                        </el-form-item>
                    </el-form>
                    <my-el-button plus :disabled="!canAddUser()" @click="addUser"></my-el-button>
                </div>
            </template>
        </el-table-column>
        <el-table-column label="" width="110">
            <template slot-scope="scope">
                <template v-if="currentEdit.id !== scope.row.id">
                    <my-el-button edit key="btn1" :disabled=" !!(currentEdit.id || currentEdit.id === 0 || lockRecordId === scope.row.id)" @click="onClickEditRow(scope.row)"/>
                    <my-el-button delete key="btn2" :disabled="!!(currentEdit.id  || currentEdit.id === 0 || lockRecordId === scope.row.id)" @click="onClickDeleteRow(scope.row)"/>
                </template>
                <template v-else>
                    <my-el-button cancel key="btn3" @click="onBtnCancelEditRow"/>
                    <my-el-button check key="btn4" :disabled="!!checkRow()" @click="onBtnApplyEditRow(scope.row)"/>
                </template>
            </template>
            <template v-slot:header >
                <my-el-button type="success" :disabled="!!(currentEdit.id || currentEdit.id === 0)" @click="onBtnAddRow">Добавить</my-el-button>
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
    import _ from 'lodash';
    import { confirm } from '@/components/common/dialogs/dialogUtils';
    import tableSaver from '@/utils/tableSaver';
    import affiliationEditor from "@/components/usersClientsAffiliation/AffiliationEditor";
    import clientEditor from "@/components/usersClientsAffiliation/ClientEditor";

    import userEditor from '@/components/usersClientsAffiliation/UserEditor';


    export default {
        name: "ClientAffiliationTable",
        mixins: [tableSaver],
        props: ['clientId', 'affiliationId', 'data', 'lockRecordId'],
        data() {
            let validateAffiliation = async(rule, value, callback) => {
                let message = this.checkAffiliation();
                message
                    ? callback(new Error(message))
                    : callback();
            };
            let validateClient = async(rule, value, callback) => {
                let message = this.checkClient();
                message
                    ? callback(new Error(message))
                    : callback();
            };
            let validateUser = async(rule, value, callback) => {
                if (this.model.user && this.currentEdit.usersRl.some(u => !u.forDelete && u.userId === this.model.user.id)) {
                    callback(new Error('Пользователь уже есть в списке.'));
                    return;
                }
                callback();
            };

            return {
                currentEdit: { usersRl: [], affiliation: null, client: null },
                model: { user: null },
                rules: {
                    user: [{ validator: validateUser }],
                    client: [this.$validateRuleRequired, { validator: validateClient }],
                    affiliation: [this.$validateRuleRequired, { validator: validateAffiliation }]
                }
            }
        },
        methods: {
            canAddUser() {
                return this.model.user &&
                    this.currentEdit.usersRl.every(r => r.forDelete || r.userId !== this.model.user.id);
            },

            addUser() {
                if (!this.model.user) {
                    return;
                }
                this.currentEdit.usersRl.push({
                                                  userId: this.model.user.id,
                                                  user: this.model.user,
                                                  AffiliationClientRlId: this.currentEdit.id,
                                                  forDelete: false
                                              });
                this.model.user = null;
            },
            async removeUser(userRl) {
                if (!await confirm('Удалить пользователя из списка?')) {
                    return;
                }
                userRl.forDelete = true;
            },


            onClickEditRow(row) {
                this.currentEdit = _.cloneDeep(row)
            },

            async onClickDeleteRow(row) {
                if (!await confirm(`Удалить ${(this.affiliationId || this.affiliationId === 0) ? 'клиента' : 'организацию'} из списка?`)) {
                    return;
                }
                if (row.id > 0) {
                    row.forDelete = true;
                } else {
                    let idx = _.findIndex(this.data, rl => rl.id === row.id);
                    this.data.splice(idx, 1);
                }
            },

            onBtnCancelEditRow() {
                if (this.currentEdit.id === 0) {
                    let idx = _.findIndex(this.data, el => el.id === 0);
                    this.data.splice(idx, 1);
                }
                this.currentEdit = { usersRl: [], affiliation: null, client: null };
            },

            onBtnApplyEditRow(row) {
                let idx = _.findIndex(this.data, rl => rl.id === row.id);
                let newVal = _.cloneDeep(this.currentEdit);
                if (newVal.id === 0) {
                    let min = _.minBy(this.data, rl => rl.id);
                    newVal.id = min.id >= 0 ? -1 : (min.id--);
                }

                this.data.splice(idx, 1, newVal);
                this.currentEdit = { usersRl: [], affiliation: null, client: null };
            },

            onBtnAddRow() {
                this.currentEdit = {
                    id: 0,
                    forDelete: false,
                    affiliation: null,
                    affiliationId: this.affiliationId,
                    client: null,
                    clientId: this.clientId,
                    usersRl: []
                };
                this.data.push(this.currentEdit);
            },

            async onEditAffiliation(id, row) {
              let doEditAffiliation = async (id, row) => {
                let affiliation = await this.$showWindowAsync(affiliationEditor, { id, lockBillingRecord: row.id });
                if (affiliation) {
                  if (id !== 0) {
                    let rl = _.find(affiliation.billingClientsRl, rl => !rl.forDelete && rl.clientId === row.clientId);
                    if (rl) {
                      row.user = rl.user;
                    } else if (id > 0) {
                      let idx = _.findIndex(this.data, d => d.id === row.id);
                      this.data.splice(idx, 1);
                      return;
                    }
                  }
                  row.affiliation = affiliation;
                  row.affiliationId = affiliation.id;
                }
              }


              await this.$confirm(`Внимание!<br/> Корректировка приведет к переименованию этой организации у всех клиентов.<br/>
                                        Если нужно поменять организацию, а не переименовать ее, необходимо стереть текущий вариант, затем:<br/>
                                        <ul>
                                        <li>либо выбрать нужную организацию из выпадающего списка,</li>
                                        <li>либо добавить новую организацию (+)</li>
                                        </ul>`,'',
                                          {
                                            dangerouslyUseHTMLString: true,
                                            confirmButtonText: 'Все понятно',
                                            showCancelButton: false,
                                            async callback(value) { value === 'confirm' && await doEditAffiliation(id, row)  }});
            },

            async onEditClient(id, row) {
                let client = await this.$showWindowAsync(clientEditor, { id, lockBillingRecord: row.id });
                if (client) {
                    if (id !== 0) {
                        let rl = _.find(client.billingClientsRl, rl => !rl.forDelete && rl.affiliationId === row.affiliationId);
                        if (rl) {
                            row.user = rl.user;
                            row.userId = rl.userId;
                        } else if (id > 0) {
                            let idx = _.findIndex(this.data, d => d.id === row.id);
                            this.data.splice(idx, 1);
                            return;
                        }
                    }
                    row.client = client;
                    row.clientId = client.id;
                }
            },

            async onEditUser(id, row) {
                let user = await this.$showWindowAsync(userEditor, { id });
                if (user) {
                    row.user = user;
                    row.userId = user.id;
                    if (id !== 0) {
                        this.data.reduce((acc, row) => acc.concat(row.usersRl.filter(rl => rl.userId === user.id)), []).forEach(rl => rl.user = user);
                    }
                }
            },

            checkRow() {
                return this.checkAffiliation() + this.checkClient();
            },

            checkAffiliation() {
                if (!(this.currentEdit.affiliationId || this.currentEdit.affiliationId === 0)) {
                    return 'Поле должно быть заполнено.'
                }
                if (this.affiliationId || this.affiliationId === 0) {
                    return '';
                }
                return this.data.some(rl => !rl.forDelete && rl.id !== this.currentEdit.id && rl.affiliationId === this.currentEdit.affiliationId) ? 'Организация уже есть в списке.' : '';
            },

            checkClient() {
                if (!(this.currentEdit.clientId || this.currentEdit.clientId === 0)) {
                    return 'Поле должно быть заполнено.'
                }
                if (this.clientId || this.clientId === 0) {
                    return '';
                }
                return this.data.some(rl => !rl.forDelete && rl.id !== this.currentEdit.id && rl.clientId === this.currentEdit.clientId) ? 'Клиент уже есть в списке.' : '';
            },
        }
    }
</script>

<style scoped>

</style>
