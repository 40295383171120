<template>
  <component-window
      :title="id ? 'Редактирование счета' : 'Добавление счета'"
      lock-name="invoice"
      :watch-object="editInvoice"
      :isWatch="lockerIsWatch && !isPaid"
      :show-refresh-button="true"
      @click-refresh-button="loadInvoice(editInvoice.id)"
      @close="onBtnCancelClick"
      :maximize="!isTabView"
      :close="canClose"
      ref="window"
  >
    <el-tabs v-model="activeTab" style="display:flex; flex-direction:column;width: 100%;height:99%" type="border-card">
      <el-tab-pane label="Поля" name="fields" style="height: 100%">
        <div style="display: flex;height: 100%">
          <div style="display:flex;flex-direction: column; width:70%">
            <el-form :rules="rules" label-width="100px" :model="editInvoice" ref="editForm" label-position="right">
              <el-form-item label="Сумма" tab="fields" prop="sum">
                <div style="display: flex;justify-content: space-between">
                  <div style="display: flex ">
                    <div style="display:flex;flex-wrap:nowrap;width:130px;">
                      <my-price-input
                          v-if="editInvoice.isPrepayment"
                          class="left-number-input"
                          style="width:90px"
                          v-model="editInvoice.sum">
                      </my-price-input>


                      <template v-else>{{ $roundFmt(calcSumByElements()) }}</template>
                      <div style="margin-left: 3px">
                        {{ $getEnumValue('CurrencyEnum', editInvoice.currency) }}
                      </div>
                    </div>
                    <el-form-item v-if="!editInvoice.isChild" label="Предоплата" label-width="110px" style="margin-bottom: 0px">
                      <el-switch
                          v-model="editInvoice.isPrepayment"
                          @change="onPrepaymentChange"
                      ></el-switch>
                    </el-form-item>
                  </div>
                  <div v-if="editInvoice.isChild" style="display: flex;flex-wrap: nowrap">
                    <span style="margin-right: 5px">Этот счет - часть сток счета</span>
                    <my-link :name="editInvoice.parent.number"
                             @click="showStockInvoice(editInvoice.parentId)"
                    ></my-link>
                  </div>
                  <div style="display: flex;align-items: center">
                    <one-c-label
                        show-bases-in-label
                        v-if="editInvoice.oneCId||editInvoice.oneCIdNomotech"
                        :evrogen="editInvoice.oneCBase === 'evrogen'"
                        :nomotech="editInvoice.oneCBase === 'nomotech'"
                    ></one-c-label>
                    <el-tag v-if="editInvoice.isSber" size="mini" style="margin-left: 5px;">Оплата сбербанк-онлайн</el-tag>

                  </div>
                </div>
              </el-form-item>
              <el-form-item label="Номер счета" tab="fields" prop="number">
                <el-input v-model="editInvoice.number"></el-input>
              </el-form-item>
              <el-form-item label="Дата счета" prop="createdAt">
                <div style="display: flex;">
                  <div style="width: 120px;font-weight: bold">
                    <el-date-picker
                        v-model="editInvoice.createdAt"
                        type="date"
                        :picker-options="{firstDayOfWeek: 1}"
                        placeholder="Выбрать">
                    </el-date-picker>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="Поставщик" prop="from">
                <my-selector
                    type="affiliation"
                    v-model="editInvoice.from"
                    placeholder=""
                    :selector="onAutocompleteFromSearch"
                    :disabled="lockFromField"
                    @change="onChangeAcField('fromId', $event )"
                />
              </el-form-item>
              <el-form-item label="Клиент" prop="client">
                <my-selector
                    :disabled="showFrom === 'client'"
                    type="client"
                    :buttons="true"
                    v-model="editInvoice.client"
                    :placeholder="false"
                    @edit="onEditClient(editInvoice.client.id)"
                    @add="onEditClient(0)"
                    @change="onChangeAcField('clientId', $event )"
                />
              </el-form-item>
              <el-form-item label="Плательщик" prop="to">
                <div style="display: flex;flex-direction: column">
                  <my-selector
                      type="affiliation"
                      v-model="editInvoice.to"
                      :buttons="true"
                      :placeholder="false"
                      @edit="onEditTo(editInvoice.to.id)"
                      @add="onEditTo(0)"
                      @change="onChangeAcField('toId', $event )"
                  />
                  <div style="display: flex; justify-content: flex-end">
                    <contracts-view :affiliation="editInvoice.to" style="align-self: flex-end"></contracts-view>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="Комментарий" tab="fields" prop="comment">
                <el-input type="textarea"
                          :autosize="{ minRows: 1, maxRows: 6}"
                          v-model="editInvoice.comment"/>
              </el-form-item>
            </el-form>
            <div class="my-form-label" style="display:flex; justify-content:space-between;align-items: center;margin-bottom: 10px">Платежи
              <my-el-button
                  v-if="!editInvoice.isChild"
                  type="success"
                  @click="onClickPay"
                  :disabled="!!checkCanPay"
                  :dis-popover="checkCanPay"
              >Внести платеж
              </my-el-button>
            </div>
            <el-table :data="editInvoice.payments"
                      style="font-size: 16px"
                      height="100%"
                      :border="true"
                      :default-sort="{prop: 'payDate', order: 'descending'}"
            >
              <el-table-column prop="payDate" label="Дата" width="100">
                <template slot-scope="scope">
                  <my-date :date="scope.row.payDate"></my-date>
                </template>
              </el-table-column>
              <el-table-column width="90" prop="sum" label="Сумма">
                <div slot-scope="scope">
                  {{ $roundFmt(scope.row.sum) }}
                </div>
              </el-table-column>
              <el-table-column width="90" prop="sum" label="Комисcия">
                <div slot-scope="scope">
                  {{ $roundFmt(scope.row.bankCommission) }}
                </div>
              </el-table-column>
              <el-table-column width="100" prop="oneCNumber" label="Номер в 1С">
                <div slot-scope="scope" style="white-space: nowrap" v-html="scope.row.oneCNumber">
                </div>
              </el-table-column>


              <el-table-column width="150" prop="operator" label="Оператор"></el-table-column>
              <el-table-column prop="comment" label="Комментарий"></el-table-column>
              <el-table-column width="63">
                <template slot-scope="scope">
                  <my-el-button delete v-if="!editInvoice.isChild" @click="deletePayment(scope.row)"></my-el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div style="width:30%;margin-left: 10px;display: flex;flex-direction: column;justify-content: stretch;height: 100%">
            <div style="display:flex;flex-direction:column;height: 50%">
              <div v-if="!isPaid && !editInvoice.isSber" style="display: flex;margin-bottom: 3px">
                <my-el-autocomplete
                    key-field="number"
                    v-model="findOrder"
                    :selectFunction="onAutocompleteOrderSearch"
                    :edit="false"
                    :add="false"
                    :clear="true"
                    placeholder="Номер заказа"
                >
                  <template slot-scope="{ item }">
                    <div>{{ item.number }}</div>
                  </template>
                </my-el-autocomplete>
                <my-el-button plus :disabled="!findOrder" @click="addOrderToInvoice"></my-el-button>
              </div>
              <el-table :data="metaorderRls"
                        style="font-size: 16px"
                        height="100%"
                        :border="true"
              >
                <el-table-column prop="metaorder.number" label="Заказ">
                  <my-metaorder-number slot-scope="scope"
                                       :disabled="metaorder && metaorder.id == scope.row.metaorderId"
                                       :metaorder="scope.row.metaorder"
                                       @end-edit="onOrderEditorClose(scope.row, $event)"
                  />
                </el-table-column>
                <el-table-column width="90" label="Сумма">
                  <template slot-scope="scope">
                    {{ $roundFmt(scope.row.metaorder.ordersSum + scope.row.metaorder.deliverySum) }}
                  </template>
                </el-table-column>
                <el-table-column width="60px">
                  <template slot-scope="scope">
                    <my-el-button v-if="!(metaorder && metaorder.id === scope.row.metaorderId)" delete @click="deleteOrderFromInvoice(scope.row)"></my-el-button>
                  </template>
                </el-table-column>
                <div slot="append" style="display: flex;margin-left: 3px;margin-top: 3px">
                </div>
              </el-table>
              <div style="height:20px"></div>
            </div>
            <div style="display:flex;flex-direction:column;height: 50%;justify-content: flex-end;">
              <div style="display: flex;flex-direction: column; align-items: flex-end;margin:5px 0 5px 0">
                <my-el-button type="success" @click="onCreatePdf">Создать документ</my-el-button>
              </div>
              <div style="display:flex;flex-direction:column;height: 100%">
                <file-table :files="editInvoice.files" :file-type="['invoice', 'invoiceBank', 'invoiceTranslate', 'act']" with-types with-date></file-table>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Элементы счета" name="elements" style="height: 100%">
        <div style="display: flex;flex: 0 1;flex-direction:column; height: 100%">
          <el-form style="display:flex;justify-content: flex-end; width:100%" label-position="right">
            <el-form-item style="margin-bottom: 0" label-width="200px" label="Сумма по элементам счета:">
              <div style="display: flex">
                <div style="font-weight: bold">{{ $roundFmt(calcSumByElementsComp) }}</div>
                <div v-if="editInvoice.metaorderRls.length > 0 && calcSumByElementsComp.toFixed(2) !== calcSumByOrders.toFixed(2)"
                     style="font-weight: bold;font-size: 17px; color: red; margin-left: 15px">
                  не совпадает
                </div>
                <el-form-item v-if="editInvoice.metaorderRls.length > 0" style="margin-bottom: 0" label-width="140px" label="Сумма по заказам:">
                  <div style="font-weight: bold">{{ $roundFmt(calcSumByOrders) }}</div>
                </el-form-item>
              </div>
            </el-form-item>
          </el-form>

          <el-form style="display:flex;height: 100%;width:100%" :model="editInvoice" ref="itemsForm">
            <el-table
                :data="notDeletedInvoiceElements"
                style="width: 100%;font-size: 16px"
                :border="true"
                ref="dataTable"
                height="100%"
                width="100%"
                :default-sort="{prop: 'order.number'}"
                v-ls-saver:invoiceTbl
            >
              <el-table-column width="150" prop="orderNumber" label="Заказ">
                <template slot-scope="scope">
                  <div v-if="scope.row.order">
                    {{ scope.row.order.number }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="150" label="#CAT" :style="'vertical-align:top'">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.cat" :readonly="scope.row.sourceKey.startsWith('product:')"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="Название">
                <template slot-scope="scope">
                  {{ scope.row.name }}
                </template>
              </el-table-column>
              <el-table-column width="100" label="Кол-во">
                <template slot-scope="scope">
                  <el-form-item
                      style="margin-bottom: 0"
                      :prop="'elements.' + scope.$index + '.count'"
                      :rules="rules.sum">
                    <el-input v-model="scope.row.count"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column width="110" label="Цена">
                <template slot-scope="scope">
                  <my-price-input
                      class="left-number-input"
                      style="width:90px"
                      v-model="scope.row.price">
                  </my-price-input>
                </template>
              </el-table-column>
              <el-table-column width="110" label="Цена со скидкой">
                <template slot-scope="scope">
                  <my-price-input
                      class="left-number-input"
                      style="width:90px"
                      v-model="scope.row.discountPrice">
                  </my-price-input>
                </template>
              </el-table-column>
              <el-table-column width="100" label="Сумма">
                <template slot-scope="scope">
                  {{ $roundFmt(scope.row.discountPrice * scope.row.count) }}
                </template>
              </el-table-column>
              <el-table-column width="55">
                <template slot-scope="scope">
                  <my-el-button delete @click="deleteInvoiceElement(scope.row)"></my-el-button>
                </template>
                <template v-slot:header>
                  <my-el-button
                      :disabled="editInvoice.payments.length > 0"
                      :dis-popover="editInvoice.payments.length > 0 ? 'По счету были платежи': ''"
                      plus @click="addInvoiceElement"></my-el-button>
                </template>
              </el-table-column>

            </el-table>
          </el-form>
        </div>
      </el-tab-pane>
      <el-tab-pane name="newOrderButtonTab"
                   disabled="true"
                   v-if="editInvoice.metaorderRls.length > 0 && calcSumByElementsComp.toFixed(2) !== calcSumByOrders.toFixed(2)">
        <el-tag slot="label"
                size="Medium"
                style="margin-left: 30px;font-size: 14px;background-color: red;color:white;">Сумма по элементам не совпадает с суммой заказа
        </el-tag>
      </el-tab-pane>
    </el-tabs>
    <template slot="footer" slot-scope="{lockerState}">
      <div style="display: flex;justify-content: space-between;width:100%">
        <div style="display: flex">
          <my-el-button delete
                        v-if="!!id"
                        :disabled="!!errorsForDeleteInvoiceButton(lockerState)"
                        :dis-popover="errorsForDeleteInvoiceButton(lockerState)"
                        @click="onBtnDeleteClick"
                        style="margin-right: 15px"></my-el-button>
        </div>
        <div style="display:flex">
          <my-el-button v-if="canClose" @click="onBtnCancelClick" type="warning" align="right">Отменить</my-el-button>
          <my-el-button
              :disabled="!!errorsForSaveInvoiceButton(lockerState)"
              :dis-popover="errorsForSaveInvoiceButton(lockerState)"
              @click="onBtnSaveClick(false)"
              type="success">Сохранить
          </my-el-button>
          <my-el-button
              v-if="canClose"
              :disabled="!!errorsForSaveInvoiceButton(lockerState)"
              :dis-popover="errorsForSaveInvoiceButton(lockerState)"
              @click="onBtnSaveClick(true)"
              type="success">Сохранить и закрыть
          </my-el-button>
        </div>
      </div>
    </template>
  </component-window>
</template>

<script>
import { alert, confirm } from '@/components/common/dialogs/dialogUtils';
import clientEditor from '@/components/usersClientsAffiliation/ClientEditor';
import affiliationEditor from '@/components/usersClientsAffiliation/AffiliationEditor';
import tableSaver from '@/utils/tableSaver';
import invoiceElementSelector from '@/components/invoices/InvoiceElementSelector';
import * as invoiceHelper from '@/utils/invoiceHelper';
import FileTable from "@/components/common/fileTable";
import InvoicePrint from "@/components/invoices/InvoicePrint";
import stockInvoiceEditor from '@/components/invoices/StockInvoiceEditor';
import confirmInvoicePay from '@/components/invoices/ConfirmInvoicePay';
import MyMetaorderNumber from "@/components/common/myMetaorderNumber";
import OneCLabel from "@/components/common/oneCLabel";
import contractsView from "@/components/usersClientsAffiliation/contractsView";

export default {
  name: 'InvoiceEditor',
  components: { OneCLabel, MyMetaorderNumber, FileTable, contractsView },
  props: ['id', 'showFrom', 'client', 'to', 'metaorder', 'onClose', 'showConfirmPayWindow', 'sum', 'isSber', 'number', 'isTabView'],
  mixins: [tableSaver],
  data() {
    return {
      findOrder: null,
      lockerIsWatch: false,
      windowSize: { minHeight: 400, minWidth: 400, height: '90%', width: '50%' },
      activeTab: 'fields',
      editInvoice: {
        id: 0,
        isPrepayment: false,
        createdAt: new Date(),
        number: '',
        metaorderRls: [],
        from: null,
        client: null,
        to: null,
        sum: 0,
        payDate: null,
        payments: [],
        elements: [],
        files: [],
        isSber: false,
        delivery: null,
        deliveryId: null
      },
      fromAffiliations: [],
      rules: {
        from: [this.$validateRuleRequired],
        number: [this.$validateRuleRequired],
        client: [this.$validateRuleRequired],
        to: [this.$validateRuleRequired],
        sum: [this.$validateRuleRequired, this.$validateRuleNumber?.(true, false, true)],
        shortName: [this.$validateRuleRequired],
        country: [this.$validateRuleRequired],
        currency: [this.$validateRuleRequired],
        createdAt: [this.$validateRuleRequired]
      },
      lockFromField: false
    };
  },

  async mounted() {
    this.fromAffiliations = await this.$store.dispatch('affiliations/loadItems', { query: [{ fieldName: 'isEvrogen', op: 'eq', values: [true] }] })
    if (this.id) {
      await this.loadInvoice(this.id);
    } else {
      this.editInvoice.isSber = this.isSber;
      this.editInvoice.number = this.number;
      this.editInvoice.client = this.client;
      this.editInvoice.clientId = this.client?.id;
      this.editInvoice.currency = this.client?.currency;
      this.editInvoice.delivery = this.delivery;
      this.editInvoice.deliveryId = this.delivery?.id;


      this.editInvoice.to = this.to;
      this.editInvoice.toId = this.to?.id;

      if (this.metaorder) {
        this.editInvoice.currency = this.metaorder.currency;
        this.editInvoice.metaorderRls.push(
            {
              invoiceId: 0,
              metaorder: this.metaorder,
              metaorderId: this.metaorder.id
            });
        this.editInvoice.elements.push(
            ...(await invoiceHelper.createInvoiceElements(this.metaorder, this.editInvoice.id, this))
            .map(el => Object.assign(el, { invoiceId: this.editInvoice.id }))
        );
      }
      this.editInvoice.sum = this.sum;
      if (this.editInvoice.currency !== 'rub') {
        this.editInvoice.from = this.fromAffiliations[ 0 ];
        this.editInvoice.fromId = this.fromAffiliations[ 0 ].id;
      }
    }
    if (this.isTabView) {
      document.title = 'С: ' + this.editInvoice.number;
    }

    await this.$refs.editForm.validate();
  },
  watch: {
    'editInvoice.number': function(val) {
      let fndAffiliation = this.$findOurAffiliationByInvoiceNumber(val);
      if (fndAffiliation) {
        this.editInvoice.from = fndAffiliation;
        this.editInvoice.fromId = fndAffiliation.id;
        this.lockFromField = true;
      } else {
        this.editInvoice.from = null;
        this.editInvoice.fromId = null;
        this.lockFromField = false;
      }
    }
  },

  methods: {
    async deletePayment(payment) {
      if (!await confirm('Отменить платеж?')) {
        return;
      }
      let bindNextTime = null;
      if (payment.oneCId) {
        bindNextTime = await confirm('Этот платеж получен из 1С. Привязать его опять при следующей обработке?', 'Привязать');
      }
      try {
        await this.$store.dispatch('invoices/rejectPay', { id: payment.id, bindNextTime });
        this.editInvoice.payments = this.editInvoice.payments.filter(pm => pm.id !== payment.id);
        await this.$refs.window.unlock();
      } catch (ex) {
        await alert(`Ошибка отмены платежа. ${ex.message}`);
      }
    },


    async showStockInvoice(id) {
      await this.$showWindowAsync(stockInvoiceEditor, { id });
    },
    onPrepaymentChange(value) {
      if (!value) {
        this.editInvoice.sum = 1;
      } else {
        this.editInvoice.sum = this.calcSumByElementsComp;
      }
      this.$refs.editForm.validate();
    },

    async onCreatePdf() {
      if (!await this.validate()) {
        return;
      }
      if (!await this.doSaveInvoice()) {
        return;
      }

      let files = await this.$showWindowAsync(InvoicePrint, { invoice: this.editInvoice });
      if (files) {
        this.lockerIsWatch = false;
        this.editInvoice.files.push(...files);
        this.lockerIsWatch = true;
      }
    },

    async addInvoiceElement() {
      let elements = await this.$showWindowAsync(invoiceElementSelector, { currency: this.editInvoice.currency });
      if (elements) {
        this.editInvoice.elements.push(...elements);
      }
    },

    async deleteInvoiceElement(invoiceElement) {
      if (!await confirm('Удалить элемент?')) {
        return;
      }
      if (!invoiceElement.id) {
        this.editInvoice.elements = this.editInvoice.elements.filter(el => el !== invoiceElement);
      } else {
        invoiceElement.forDelete = true;
      }
    },

    onOrderEditorClose(rl, metaorder) {
      if (metaorder) {
        rl.metaorder = metaorder;
      }
    },

    async deleteOrderFromInvoice(orderRl) {
      if (await confirm(`Удалить заказ ${orderRl.metaorder.number} из счета?`)) {
        if (orderRl.id) {
          orderRl.forDelete = true;
        } else {
          this.editInvoice.metaorderRls = this.editInvoice.metaorderRls.filter(rl => rl.metaorderId !== orderRl.metaorderId);
        }
      }
    },

    async addOrderToInvoice() {
      if (!this.editInvoice.currency) {
        this.editInvoice.currency = this.findOrder.currency;
      } else if (this.editInvoice.currency !== this.findOrder.currency) {
        await alert(`Валюта заказа (${this.$getEnumValue('CurrencyEnum', this.findOrder.currency)})
                                 отличется от валюты счета (${this.$getEnumValue('CurrencyEnum', this.editInvoice.currency)}) - не буду добавлять.`);
        return;
      }

      this.editInvoice.metaorderRls.push(
          {
            invoiceId: this.editInvoice.id,
            metaorder: this.findOrder,
            metaorderId: this.findOrder.id,
            forDelete: false
          });

      let response = await this.$store.dispatch('metaorders/loadItem', this.findOrder.id);
      let addOrder = response.metaorder;
      this.editInvoice.elements.push(...(await invoiceHelper.createInvoiceElements(addOrder, this.editInvoice.id, this)));
      this.findOrder = null;
    },

    async loadInvoice(id) {
      this.lockerIsWatch = false;
      try {
        this.editInvoice = await this.$store.dispatch('invoices/loadItem', id);
      } catch (ex) {
        alert(`Ошибка загрузки счета: ${ex.message}`);
      } finally {
        this.lockerIsWatch = true;
      }
    },

    async onClickPay() {
      if (!await this.validate()) {
        return;
      }

      let dateAndSum = await this.$showWindowAsync(confirmInvoicePay, {
        id: 0,
        invoice: this.editInvoice
      });

      if (!dateAndSum) {
        return;
      }

      if (!await this.doSaveInvoice()) {
        return;
      }

      try {
        let payment = await this.$store.dispatch('invoices/doPay',
                                                 { invoice: this.editInvoice, ...dateAndSum });
        this.editInvoice.payments.push(payment);
        this.onClose && this.onClose({ invoice: this.editInvoice, isPay: true });
        this.$emit('close', this.editInvoice);
      } catch (ex) {
        alert("Во время записи оплаты счета возникла ошибка: " + ex.message);
      } finally {
        this.lockerIsWatch = true;
      }
    },

    onAutocompleteFromSearch(query, cb) {
      cb(this.fromAffiliations)
    },

    async onAutocompleteOrderSearch(query, cb) {
      let filter = {
        query: [
          { fieldName: 'number', op: 'like', values: [query?.trim() || ''] },
          { isOr: false, fieldName: 'payState', op: 'eq', values: ['notPaid'] }
        ]
      };
      if (query && query.length > 3) {
        let response = await this.$store.dispatch('metaorders/loadItems', filter);
        cb(response.filter(r => !this.editInvoice.metaorderRls.filter(rl => !rl.forDelete).some(rl => rl.metaorderId === r.id)));
      } else {
        cb([]);
      }
    },

    onChangeAcField(fieldName, obj) {
      this.editInvoice[ fieldName ] = obj?.id;
      if (fieldName === 'clientId') {
        this.editInvoice.currency = obj?.currency;
      }
    },

    async onEditClient(id) {
      let client = await this.$showWindowAsync(clientEditor, { id });
      if (client) {
        this.editInvoice.client = client;
        this.editInvoice.currency = client.currency;
      }
    },

    async onEditTo(id) {
      let to = await this.$showWindowAsync(affiliationEditor, { id });
      if (to) {
        this.editInvoice.to = to;
      }
    },

    async onBtnDeleteClick() {
      if (!await confirm('Удалить счет?', 'Удалить')) {
        return;
      }
      try {
        await this.$store.dispatch('invoices/deleteItem', this.editInvoice)
      } catch (ex) {
        await alert(ex.message);
        return;
      }
      this.onClose && this.onClose({ isDelete: true });
      this.$emit('close');
    },

    async doSaveInvoice() {
      this.lockerIsWatch = false;
      try {
        if (!this.editInvoice.isPrepayment) {
          this.editInvoice.sum = this.calcSumByElementsComp;
        }
        this.editInvoice = (await this.$store.dispatch('invoices/saveItem', this.editInvoice));
        await this.$refs.window.unlock();
      } catch (ex) {
        await alert(ex.message);
        return false;
      } finally {
        this.lockerIsWatch = true;
      }
      return true;
    },

    async onBtnSaveClick(withClose) {
      if (!await this.validate()) {
        return;
      }
      await this.doSaveInvoice();
      if (!withClose) {
        return;
      }

      if (this.isTabView) {
        window.close();
        return;
      }

      this.onClose && this.onClose({ invoice: this.editInvoice });
      this.$emit('close', this.editInvoice);
    },

    async validate() {
      try {
        await this.$refs.editForm.validate()
      } catch (ex) {
        this.activeTab = 'fields';
        return false;
      }
      try {
        await this.$refs.itemsForm.validate();
      } catch (ex) {
        this.activeTab = 'elements';
        return false;
      }
      return true;
    },

    onBtnCancelClick() {
      if (this.isTabView) {
        window.close();
        return;
      }

      this.onClose && this.onClose(null);
      this.$emit('close', null);
    },

    errorsForDeleteInvoiceButton(lockerState) {
      if (this.editInvoice.isChild) {
        return 'Этот счет является частью другого счета.'
      }

      if (this.isSber) {
        return 'Счет привязан к платежу сбербанк.'
      }
      if (!lockerState.canEdit) {
        return 'Запись редактирует другой пользователь.'
      }
      if (this.editInvoice && this.editInvoice.payments.length > 0) {
        return 'Платежи по счету есть. Нельзя удалять с платежами счет.'
      }
      return '';
    },

    errorsForSaveInvoiceButton(lockerState) {
      if (!lockerState.canEdit) {
        return 'Запись редактирует другой пользователь.'
      }
      return '';
    },
    calcSumByElements() {
      let t = Math.round(this.calcSumByElementsComp * 100) / 100;
      this.editInvoice.sum = t;
      return this.editInvoice.sum;
    }
  },

  computed: {

    canClose() {
      return !(this.isTabView && !opener);
    },

    checkCanPay() {
      if (this.isPaid) {
        return 'Счет уже оплачен.';
      }
      if (!this.editInvoice.isPrepayment && !this.editInvoice.elements.some(el => !el.forDelete)) {
        return 'В счете нет элементов.';
      }
      if (this.editInvoice.isPrepayment && !this.editInvoice.sum) {
        return 'Не задана сумма.';
      }
      return "";
    },

    calcSumByElementsComp() {

      let t = this.editInvoice.elements
      .filter(el => !el.forDelete)
      .reduce((acc, el) => acc + el.count * el.discountPrice, 0) || 0;
      return t;
    },
    calcSumByOrders() {
      return Math.round((this.editInvoice.metaorderRls.reduce((acc, rl) => acc + rl.metaorder.ordersSum + rl.metaorder.deliverySum, 0)) * 100) / 100;
    },

    notDeletedInvoiceElements() {
      return this.editInvoice.elements.filter(el => !el.forDelete);
    },

    metaorderRls() {
      return this.editInvoice.metaorderRls.filter(rl => !rl.forDelete)
    },

    isPaid() {
      return (this.editInvoice.payments.length > 0 && this.leftToPaid <= 0);
    },

    leftToPaid() {
      let sum = this.editInvoice.isPrepayment ? this.editInvoice.sum : this.calcSumByElementsComp;
      return sum - this.editInvoice.payments.reduce((acc, p) => acc + p.sum, 0);
    }
  }
}
</script>
