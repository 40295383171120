import vue from 'vue';

export default function buildPriceStore(store){
    store.state.sequencePrices = {};
    store.state.primerPrices = {};
    store.mutations.setSequencePrices = (state, prices) => state.sequencePrices = prices;
    store.mutations.setPrimerPrices = (state, prices) => state.primerPrices = prices;

    store.actions.calcPrimerPrice = async (context, primerAndUserId) => await vue.prototype.$myHttp.post('/api/Prices/CalcPrimerPrice', primerAndUserId);
    
    store.actions.getSequencePrice = async (context) =>  context.commit('setSequencePrices', await vue.prototype.$myHttp.post('/api/Prices/GetSequencePrice'));
    store.actions.saveSequencePrice = async (context, prices) => { 
        await vue.prototype.$myHttp.post('/api/Prices/SaveSequencePrice', prices);
        context.commit('setSequencePrices', prices);
    };
    
    store.actions.getPrimerPrice = async (context) =>  context.commit('setPrimerPrices', await vue.prototype.$myHttp.post('/api/Prices/GetPrimerCharsPrices'));
    store.actions.savePrimerPrice = async (context, prices) => {
        await vue.prototype.$myHttp.post('/api/Prices/SavePrimerCharsPrices', prices);
        context.commit('setPrimerPrices', prices);
    };
    store.actions.saveCats = async (context, data) =>  await vue.prototype.$myHttp.post('/api/Prices/SaveCats', data);
    return store;
}