<template>
  <div style="display:flex;height: 100%;width: 100%">
    <div style="display: flex;flex-direction: column;width:100%;height: 100%">
      <div style="display: flex;justify-content: flex-end;padding-bottom: 5px;align-items: flex-end;flex-wrap: wrap">
        <slot>
          <div></div>
        </slot>
        <div style="display: flex;flex-grow: 2"></div>
        <el-pagination align="right"
                       @size-change="onPagerSizeChange"
                       @current-change="onCurrentPageChange"
                       :current-page.sync="filter.currentPage"
                       :page-sizes="[25, 50, 100, 200, 300, 400]"
                       :page-size="filter.pageSize"
                       layout="sizes, prev, pager, next"
                       :total="filter.countAll">
        </el-pagination>
      </div>
      <el-table :data="orders"
                ref="orders"
                style="font-size: 16px"
                height="100%"
                :border="true"
                row-key="id"
                :default-sort="{prop: 'createdAt', order: 'descending'}"
                @select="onSelectOrders"
                @select-all="onSelectOrders"
                v-ls-saver:clientTbl
      >
        <el-table-column type="selection" width="30">
        </el-table-column>


        <el-table-column label="Номер" prop="number" width="170">
          <template slot-scope="scope">
            <div style="display: flex;flex-wrap: wrap" :ref="`anchorColumn_${scope.row.id}`">
              <div style="justify-content: flex-start;display: flex;align-items: center;flex-wrap: nowrap">
                <my-metaorder-number
                    :metaorder="scope.row"
                    :set-client="!!client"
                    :set-user="!!user"
                    @begin-edit="$emit('begin-edit', $event)"
                    @end-edit="$emit('end-edit', null);"
                />
                <i v-if="scope.row.isDone" class="el-icon-success" slot="reference" style="padding-left: 5px; cursor: pointer;font-size: 12px;color:#67c23a"></i>
              </div>
              <div style="justify-content: flex-start;display: flex;align-items: center;flex-wrap: nowrap">
                <el-tag style="margin-left: 3px"
                        v-for="type in scope.row.orderTypes"
                        :key="type"
                >{{ type }}
                </el-tag>
              </div>
            </div>
          </template>
          <template v-slot:header>
            <div style="display: flex;justify-content: space-between;align-items: center;padding: 0">Номер
              <my-el-button plus @click="onAddOrder"></my-el-button>
              <my-el-button arrow
                            :disabled="selectedOrders && selectedOrders.length === 0"
                            @click="moveOrdersToOtherClient"></my-el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Подзаказы" width="140">
          <template v-slot="scope">
            <div v-for="order in scope.row.orders" :key="order.id">
              {{ order.number }}
            </div>
          </template>
        </el-table-column>

        <el-table-column v-if="!user" label="ФИО">
          <div slot-scope="scope" style="display: flex; flex-direction: column" :ref="`user_${scope.row.id}`">
            <div style="display: flex; justify-content: space-between">
              <my-type-link :object="scope.row.user"
                            @click="showUserWindow(scope.row)"
              ></my-type-link>

              <div style="display: flex;">
                <i v-if="scope.row.user && scope.row.user.isChecked"
                   class="el-icon-circle-check"
                   style="font-weight: bold;color:green; align-self: center;margin-right: 10px;margin-left: 5px"></i>
              </div>
            </div>
            <div style="display: flex;margin-top: 3px;margin-left: 10px">
              <labels :labels="scope.row.labels"></labels>
            </div>
          </div>
        </el-table-column>
        <el-table-column v-if="!client" label="Клиент">
          <div slot-scope="scope" style="display: flex;justify-content: space-between" :ref="`client_${scope.row.id}`">
            <div>
              <my-type-link :object="scope.row.client"
                            :max-icons-count="2"
                            @click="showClientWindow(scope.row)">
                <my-client-debt-warning :client="scope.row.client"></my-client-debt-warning>
                <template v-slot:second-row>
                  <div style="font-size: 12px;line-height: initial; word-break: break-word;" v-html="buildAffiliationName(scope.row)"></div>
                </template>
              </my-type-link>
            </div>
            <div style="display: flex;flex-direction: column;justify-content: center; align-items: flex-end">
              <el-tag v-if="scope.row.client && scope.row.client.isSpecialConditions"
                      color="blue" style="color: white;margin-bottom: 3px">Спец. условия
              </el-tag>

              <i v-if="scope.row.client && scope.row.client.isCheck"
                 :class="scope.row.client.defaultPayType === 'fromAccount' ? 'el-icon-circle-check' : 'el-icon-check'"
                 style="font-weight: bold;color:green; margin-right: 10px;margin-left: 5px"></i>
            </div>
          </div>
        </el-table-column>
        <el-table-column label="Счета" width="150px">
          <div slot-scope="scope" style="display: flex; flex-wrap: wrap;" :ref="`invoice_${scope.row.id}`">
            <div v-for="rl in scope.row.invoiceRls" style="margin-right: 15px" :key="rl.id">
              <my-type-link :object="rl.invoice"
                            @click="showInvoiceWindow(rl.invoiceId)"
              ></my-type-link>
            </div>
            <div style="word-break: keep-all" v-html="scope.row.oldInvoices"></div>
          </div>
        </el-table-column>

        <el-table-column label="Дата" width="100" prop="createdAt">
          <template slot-scope="scope">
            <my-date :date="scope.row.createdAt"/>
          </template>
        </el-table-column>
        <el-table-column label="Сумма" width="80">
          <div slot-scope="scope" style="white-space: nowrap">
            {{ $roundFmt(scope.row.sum) }} {{ $getEnumValue('CurrencyEnum', scope.row.currency) }}
          </div>
        </el-table-column>
        <el-table-column label="Оплачено" width="80">
          <div slot-scope="scope" style="display: flex;align-items: center">
            <div style="white-space: nowrap">{{ $roundFmt(scope.row.paySum) }}</div>
            <my-bank-commission :commission="scope.row.payCommission * 1"></my-bank-commission>
          </div>
        </el-table-column>
      </el-table>
    </div>
    <change-monitor name="orderEditor" :items="monitoringItems" @change="onChangesElements" ref="changeMonitor"></change-monitor>
  </div>
</template>
<script>
import _ from 'lodash';
import { alert } from '@/components/common/dialogs/dialogUtils';
import orderEditor from '@/components/order/OrderEditor';
import clientEditor from '@/components/usersClientsAffiliation/ClientEditor';
import userEditor from '@/components/usersClientsAffiliation/UserEditor';
import tableSaver from '@/utils/tableSaver';
import invoiceEditor from '@/components/invoices/InvoiceEditor';
import labels from '@/components/common/Labels';
import moveOrdersWindow from '@/components/usersClientsAffiliation/MoveOrdersWindow';
import ChangeMonitor from "@/components/common/changeMonitor";
import MyTypeLink from "@/components/common/myTypeLink";
import MyMetaorderNumber from "@/components/common/myMetaorderNumber";
import MyBankCommission from "@/components/common/MyBankCommission";
import MyClientDebtWarning from "@/components/common/myClientDebtWarning";


export default {
  name: "OrderTable",
  components: { MyClientDebtWarning, MyBankCommission, MyMetaorderNumber, MyTypeLink, ChangeMonitor, labels },
  props: ['query', 'user', 'client', 'orderId', 'withMove'],
  mixins: [tableSaver],
  data() {
    return {
      orders: [],
      modifiers: [],
      findText: '',
      filter: {
        currentPage: 1,
        pageSize: 25,
        fields: "",
        orderBy: 'CreatedAt',
        orderIsDesc: true,
        query: [],
        countAll: 0,
      },
      selectedOrders: [],
      localStorageFields: ['filter.pageSize'],
      changeMonitor: null
    }
  },

  async mounted() {
    if (this.query) {
      this.filter.query = this.query;
      await this.loadOrders();
    }
    if (this.orderId) {
      await this.$showWindowAsync(orderEditor, { id: this.orderId, user: this.user, client: this.client });
      this.$emit('end-edit', null);
    }
  },

  watch: {
    async query(query) {
      this.filter.query = query;
      this.filter.currentPage = 1;
      await this.loadOrders();
    }
  },

  methods: {

    onChangesElements(data) {
      let monitoringFields = [
        { name: 'user', path: 'user' },
        { name: 'client', path: 'client' },
        { name: 'affiliation', path: 'client.affiliation', refName: 'client' }
      ];

      this.orders = this.$refs.changeMonitor.prepareList(data, this.orders, 'metaorder', monitoringFields);
      this.$refs.orders.clearSelection();
      let selOrders = this.orders.filter(o => this.selectedOrders?.some(so => so.id === o.id));
      this.$nextTick(() =>
                         selOrders.forEach(row => {
                           this.$refs.orders.toggleRowSelection(row, true);
                         }));


      let forAnimationIds = [];

      if (data.invoice) {
        forAnimationIds = this.prepareChangedInvoices(data.invoice);
        let refs = Object.entries(this.$refs)
        .filter(entry => forAnimationIds.includes(entry[ 0 ]))
        .map(entry => entry[ 1 ]?.closest('td')).filter(el => el);
        this.$refs.changeMonitor.animateElements(refs);
      }
    },

    prepareChangedInvoices(data) {
      let newInvoices = data.update || [];
      if (data.insert?.length) {
        newInvoices.push(...data.insert.filter(i => newInvoices.every(ni => ni.id !== i.id)));
      }
      let idsForAnimation = [];
      let allRlsForDelete = [];
      if (newInvoices.length) {
        newInvoices.forEach(newInvoice => {
          newInvoice.metaorderRls.forEach(rl => {
            let fndOrder = this.orders.find(o => o.id === rl.metaorderId);
            if (!fndOrder) {
              return;
            }
            idsForAnimation.push(fndOrder.id);
            let fndInvoiceRl = fndOrder.invoiceRls.find(rl => rl.invoiceId === newInvoice.id);
            if (fndInvoiceRl) {
              fndInvoiceRl.invoice = newInvoice;
            } else {
              fndOrder.invoiceRls.push(
                  {
                    id: rl.id,
                    invoiceId: newInvoice.id,
                    invoice: newInvoice
                  }
              )
            }
          })
        })
        let allNewRlId = newInvoices.reduce((acc, i) => acc.concat(i.metaorderRls.map(rl => rl.id)), [])
        allRlsForDelete = this.orders.reduce((acc, o) =>
                                                 acc.concat(o.invoiceRls.filter(rl => newInvoices.some(ni => ni.id === rl.invoiceId)
                                                     && !allNewRlId.includes(rl.id))
                                                            .map(rl => ({ orderId: o.id, rl }))
                                                 ), []);
      }
      if (data.delete?.length) {
        let rlForDelete = this.orders.reduce((acc, o) =>
                                                 acc.concat(o.invoiceRls
                                                            .filter(rl => data.delete.some(di => di === rl.invoiceId))
                                                            .map(rl => ({ orderId: o.id, rl }))
                                                 ), []);
        allRlsForDelete.push(...rlForDelete);
      }

      allRlsForDelete.forEach(rlWithOrdId => {
        rlWithOrdId.rl.invoice = null;
        rlWithOrdId.rl.invoiceId = null;
        idsForAnimation.push(rlWithOrdId.orderId);
      });
      return idsForAnimation.map(id => `invoice_${id}`)
    },

    async showInvoiceWindow(id) {
      await this.$showWindowAsync(invoiceEditor, { id });
    },

    async moveOrdersToOtherClient() {
      let newIds = await this.$showWindowAsync(moveOrdersWindow, { orders: this.selectedOrders });
      if (newIds) {
        try {
          await this.$store.dispatch('metaorders/moveMetaorders', {
            clientId: newIds.client.id,
            userId: newIds.user.id,
            payerId: newIds.payer.id,
            metaorders: this.selectedOrders
          });
        } catch (ex) {
          alert(ex);
          return;
        }
        this.selectedOrders = [];
        this.$refs.orders.clearSelection();
      }
    },

    onSelectOrders(orders) {
      this.selectedOrders = orders;
    },

    async onPagerSizeChange(newPageSize) {
      this.filter.pageSize = newPageSize;
      await this.loadOrders();
      this.filter.currentPage = 1;
    },

    async onCurrentPageChange(newCurrentPage) {
      this.filter.currentPage = newCurrentPage;
      await this.loadOrders();
    },

    async loadOrders() {
      try {
        let response = await this.$store.dispatch('metaorders/loadItems', this.filter);
        this.orders.length = 0;
        this.orders.push(...response.items);
        this.filter.countAll = response.count;
        this.$emit('load', { count: this.filter.countAll, items: response.items });
        this.$nextTick(() => {
          if (this.$refs.orders) {
            this.$refs.orders.bodyWrapper.scrollTop = 0;
          }
        });
      } catch (ex) {
        await alert(ex.message);
      }
    },

    async onAddOrder() {
      await this.$showWindowAsync(orderEditor, { id: 0, user: this.user, client: this.client });
      this.$emit('end-edit', null);
    },

    async updateOrderInList(metaorderId) {
      const filter = { query: [{ fieldName: 'Id', op: 'eq', values: [metaorderId] }] };
      const orders = await this.$store.dispatch('metaorders/loadItems', filter);
      const listOrders = this.orders.filter(o => o.metaorderId === metaorderId);

      listOrders.forEach(listOrder => {
        if (!this.orders.some(o => o.id === listOrder.id)) {
          const idx = _.findIndex(this.orders, r => r.id === listOrder.id);
          this.orders.splice(idx, 1);
        }
      });

      orders.forEach(o => {
        const idx = _.findIndex(this.orders, r => r.id === o.id);
        if (idx < 0) {
          this.orders.push(o);
        } else {
          this.orders.splice(idx, 1, o);
        }
      });
    },

    buildClientName(order) {
      let result = order.client?.name;
      if (result && order.client.isPerson) {
        result += ' (cам)'
      }
      return result || '';
    },

    buildAffiliationName(metaorder) {
      let aff = metaorder?.client?.affiliation;
      return !aff ? '' : aff.name + (aff.isPerson ? ' (сам)' : '');
    },

    async showUserWindow(row) {
      await this.$showWindowAsync(userEditor, { id: row.user.id });
    },

    async showClientWindow(row) {
      await this.$showWindowAsync(clientEditor, { id: row.client.id });
    }
  },

  computed: {
    monitoringItems() {
      return {
        metaorder: {
          actions: ['insert', 'update', 'delete'],
          ids: this.orders.map(o => o.id),
          query: this.filter.query,
          insertExist: true
        },
        invoice: {
          actions: ['insert', 'update', 'delete'],
          ids: [...(new Set(this.orders.reduce((acc, o) => acc.concat(o.invoiceRls.map(i => i.invoiceId)), [])))],
          insertExist: true,
          noFilterDeleted: true
        },

        user: {
          actions: ['update'],
          ids: [...(new Set(this.orders.map(o => o.userId)))],
        },

        client: {
          actions: ['update'],
          ids: [...(new Set(this.orders.map(o => o.clientId)))],
        },

        affiliation: {
          actions: ['update'],
          ids: [...(new Set(this.orders.map(o => o.client?.affiliationId)))],
        },
      }
    }
  }
}
</script>

<style>
@keyframes backgroundColorAnimation {
  from {
    background-color: #b8e8a1;
  }
  to {
    background-color: white;
  }
}
</style>
