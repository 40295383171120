<template>
  <component-window
      :title="id ? 'Редактирование организации' : 'Добавление организации'"
      lock-name="affiliation"
      :watch-object="editAffiliation"
      :isWatch="lockerIsWatch"
      :show-refresh-button="true"
      @click-refresh-button="loadAffiliation(editAffiliation.id)"
      @close="onBtnCancelClick"
      ref="compWindow"
      :maximize="!isTabView"
      :close="canClose"
  >
    <el-tabs v-model="activeTab" style="display:flex; flex-direction:column;height:99%" type="border-card">
      <el-tab-pane label="Поля" name="fields" style="height:100%;overflow-y: auto;padding-right: 5px">
        <el-form status-icon :rules="rules" label-width="170px" :model="editAffiliation" ref="editForm">
          <el-form-item label="Физ. лицо">
            <div style="display: flex;justify-content:space-between;flex-wrap: nowrap; align-items: center">
              <div style="display: flex;flex-wrap: nowrap; align-items: center">
                <el-switch v-model="editAffiliation.isPerson"/>
                <div style="white-space: nowrap; margin-left: 30px">Это Евроген
                  <el-switch v-model="editAffiliation.isEvrogen" style="margin-left: 10px"/>
                </div>
                <div style="white-space: nowrap; margin-left: 30px">Для таможенных документов
                  <el-switch v-model="editAffiliation.forCustomsDoc" style="margin-left: 10px"/>
                </div>
                <div style="white-space: nowrap; margin-left: 30px">Не показывать цены в письмах SEQ и на сайте
                  <el-switch v-model="editAffiliation.noShowSeqPricesInMails" style="margin-left: 10px"/>
                </div>

              </div>
              <div>
                <one-c-label
                    show-bases-in-label
                    v-if="editAffiliation.oneCId||editAffiliation.oneCIdNomotech"
                    :evrogen="!!editAffiliation.oneCId"
                    :nomotech="!!editAffiliation.oneCIdNomotech"
                ></one-c-label>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="ИНН" prop="inn" v-if="!editAffiliation.isPerson">
            <div style="display: flex;flex-wrap: nowrap">
              <el-input v-model="editAffiliation.inn" style="width:150px"/>
              <div style="display: flex;justify-content: space-between;flex-wrap: nowrap">
                <el-form-item label="КПП" label-width="50px" prop="kpp" tab="fields" style="margin-bottom: 0">
                  <el-input v-model="editAffiliation.kpp" style="width:150px"/>
                </el-form-item>
                <el-form-item label="OKPO" label-width="50px" prop="okpo" tab="fields" style="margin-bottom: 0;margin-left: 15px">
                  <el-input v-model="editAffiliation.okpo" style="width:150px"/>
                </el-form-item>
                <el-form-item label="ОГРН" label-width="50px" prop="ogrn" tab="fields" style="margin-bottom: 0;margin-left: 15px">
                  <el-input v-model="editAffiliation.ogrn" style="width:150px"/>
                </el-form-item>
              </div>
            </div>
          </el-form-item>


          <el-form-item label="Название" prop="name" tab="fields">
            <div style="display: flex;justify-content: stretch">
              <div style="width: 40%">
                <el-input v-model="editAffiliation.name"/>
              </div>
              <el-form-item label="Название (En)" prop="nameEn" tab="fields" style="width: 60%;margin-bottom: 0">
                <el-input v-model="editAffiliation.nameEn"/>
              </el-form-item>
            </div>
          </el-form-item>
          <el-form-item label="Краткое название" prop="shortName" tab="fields">
            <el-input v-model="editAffiliation.shortName"/>
          </el-form-item>

          <el-form-item label="Адрес" prop="address">
            <el-input type="textarea"
                      :autosize="{ minRows: 1, maxRows: 6}"
                      v-model="editAffiliation.address">
            </el-input>
          </el-form-item>
          <el-form-item label="Комментарий" prop="comment">
            <el-input type="textarea"
                      :autosize="{ minRows: 1, maxRows: 6}"
                      v-model="editAffiliation.comment">
            </el-input>
          </el-form-item>
          <el-form-item label="Важный комментарий" prop="comment">
            <el-input type="textarea"
                      :autosize="{ minRows: 1, maxRows: 6}"
                      v-model="editAffiliation.importantComment">
            </el-input>
          </el-form-item>


          <el-form-item label="Адрес (En)" prop="address">
            <el-input v-model="editAffiliation.addressEn"/>
          </el-form-item>

          <el-form-item label="Юридический адрес" prop="legalAddress">
            <el-input v-model="editAffiliation.legalAddress"/>
          </el-form-item>

          <el-form-item label="Юридический адрес (En)" prop="legalAddressEn">
            <el-input v-model="editAffiliation.legalAddressEn"/>
          </el-form-item>

          <el-form-item label="Банк" prop="bankName" tab="fields">
            <div style="display: flex;justify-content: stretch">
              <div style="width: 40%">
                <el-input v-model="editAffiliation.bankName"/>
              </div>
              <el-form-item label="Банк (En)" prop="bankNameEn" tab="fields" style="width: 60%;margin-bottom: 0">
                <el-input v-model="editAffiliation.bankNameEn"/>
              </el-form-item>
            </div>
          </el-form-item>

          <el-form-item label="Счета" prop="bankAccount">
            <div style="display: flex;justify-content: flex-start ">
              <el-form-item label="RUB" label-width="40px" prop="bankAccount.ru" style="margin: 0 20px 0 0; width: 33%">
                <el-input v-model="editAffiliation.bankAccount.rub"/>
              </el-form-item>
              <el-form-item label="USD" label-width="40px" prop="bankAccount.ru" style="margin: 0 20px 0 0; width: 33%">
                <el-input v-model="editAffiliation.bankAccount.usd"/>
              </el-form-item>
              <el-form-item label="EUR" label-width="40px" prop="bankAccount.ru" style="margin: 0 0 0 0; width: 33%">
                <el-input v-model="editAffiliation.bankAccount.eur"/>
              </el-form-item>
            </div>
          </el-form-item>

          <el-form-item label="Телефон" prop="bankAccount">
            <div style="display: flex;justify-content: stretch">
              <el-form-item prop="phone" style="margin-bottom: 0">
                <el-input v-model="editAffiliation.phone" style="width: 200px"/>
              </el-form-item>
              <el-form-item label="Факс" prop="fax" style="margin-bottom: 0">
                <el-input v-model="editAffiliation.fax" style="width: 200px"/>
              </el-form-item>
            </div>
          </el-form-item>

          <el-form-item label="Адрес банка" prop="bankAddress">
            <el-input v-model="editAffiliation.bankAddress"/>
          </el-form-item>
          <el-form-item label="Адрес банка(En)" prop="bankAddressEn">
            <el-input v-model="editAffiliation.bankAddressEn"/>
          </el-form-item>

        </el-form>
      </el-tab-pane>
      <el-tab-pane :label="`Клиенты (${ editAffiliation.clients ? editAffiliation.clients.length: '0'})`" name="clients" style="height:100%;">
        <el-table
            v-if="editAffiliation.clients && editAffiliation.clients.length > 0"
            :data="editAffiliation.clients"
            style="width: 100%;font-size: 16px"
            height="100%"
            :border="true"
        >
          <el-table-column label="Название" :resizable="true">
            <template slot-scope="scope">
              {{ scope.row.name }}
            </template>
          </el-table-column>
          <el-table-column width="55">
            <template slot-scope="scope">
              <my-el-button edit @click="onClientEdit(scope.row.id)"/>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane :label="`Плательщик для (${ editAffiliation.billingClientsRl ? editAffiliation.billingClientsRl.length: '0'})`" name="clientsBilling" style="height:100%;">
        <client-affiliation-table :data="editAffiliation.billingClientsRl" :lock-record-id="lockBillingRecord_ || lockBillingRecord"
                                  :affiliationId="editAffiliation.id"></client-affiliation-table>
      </el-tab-pane>
      <el-tab-pane :label="`Договоры (${editAffiliation.contracts.length})`" name="contracts" style="height:100%;">
        <el-table
            :data="editAffiliation.contracts"
            style="width: 100%;font-size: 16px;flex-grow: 1"
            height="100%"
            :border="true">
          <el-table-column label="Договор" width="200">
            <template slot-scope="scope">
              <my-link :name="scope.row.name" @click="onBtnShowContract(scope.row)"></my-link>
            </template>
            <template v-slot:header>
              <div style="display: flex;align-items: center;padding: 0;justify-content: space-between">
                Договор
                <my-el-button plus @click="onBtnShowContract(null)"></my-el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Дата" width="100">
            <template slot-scope="scope">
              <my-date :date="scope.row.date"></my-date>
            </template>
          </el-table-column>
          <el-table-column label="Активен" width="75" align="center">
            <i slot-scope="scope" v-if="scope.row.isActive" class="el-icon-circle-check" style="font-weight: bold; color: green;"></i>
          </el-table-column>

          <el-table-column label="Комментарий">
            <template slot-scope="scope">
              {{ scope.row.comment }}
            </template>
          </el-table-column>
          <el-table-column label="Файлы" width="200">
            <div slot-scope="scope" style="display: flex;flex-direction: column">
              <div
                  v-for="file in scope.row.files"
                  :key="file.id"
                  style="display: flex;">
                <div class="my-link"
                     @click="onBtnDownloadFileClick(file)"
                     v-html="file.showName"></div>
              </div>
            </div>
          </el-table-column>
          <el-table-column label="" width="55">
            <template slot-scope="scope">
              <my-el-button delete @click="onDeleteContract(scope.row)"/>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="Евроген" v-if="editAffiliation.isEvrogen">
        <el-form label-width="130px">
          <el-form-item label="Почтовый индекс:">
            <div style="display: flex;flex-wrap: nowrap">
              <el-input v-model="editAffiliation.postalCode" style="width: 200px;margin-right: 20px"></el-input>
              <div style="white-space: nowrap">Контактное лицо:</div>
              <el-input v-model="editAffiliation.contact" style="margin-left: 10px;margin-right: 20px"></el-input>
              <div style="white-space: nowrap">Контактное лицо (En):</div>
              <el-input v-model="editAffiliation.contactEn" style="margin-left: 10px"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="Директор:">
            <div style="display: flex">
              <el-input placeholder="Фамилия" v-model="editAffiliation.directorLName"></el-input>
              <el-input placeholder="Имя" v-model="editAffiliation.directorFName" style="margin-left: 10px"></el-input>
              <el-input placeholder="Отчество" v-model="editAffiliation.directorMName" style="margin-left: 10px;margin-right: 20px"></el-input>
              <div style="white-space: nowrap">Директор (En):</div>
              <el-input v-model="editAffiliation.directorEn" style="margin-left: 10px"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="Бухгалтер:">
            <div style="display: flex">
              <el-input v-model="editAffiliation.accountant" style=";margin-right: 20px"></el-input>
              <div style="white-space: nowrap">Бухгалтер (En):</div>
              <el-input v-model="editAffiliation.accountantEn" style="margin-left: 10px"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="Префикс(ы) счетов">
            <el-input v-model="editAffiliation.invoicePrefixes"></el-input>
          </el-form-item>
        </el-form>
        <div style="display: flex;flex-wrap: nowrap">
          <div style="width: 50%;display: flex;flex-direction: column;justify-content: flex-start;align-items: start;margin-right: 20px">
            RU:
            <file-table :files="editAffiliation.stampFileRu" :multi-file="false"/>
            <img v-bind:src="getStampData(editAffiliation.stampFileRu)" style="margin-top:20px;border: #1e90ff 1px solid;align-self: center"/>
          </div>
          <div style="width: 50%;display: flex;flex-direction: column;justify-content: flex-start;align-items: start;margin-left: 20px">
            EN:
            <file-table :files="editAffiliation.stampFileEn" :multi-file="false"/>
            <img v-bind:src="getStampData(editAffiliation.stampFileEn)" style="margin-top:20px;border: #1e90ff 1px solid;align-self: center"/>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Слияние" name="merge" style="height:100%;">
        <el-row style="height:100%" type="flex">
          <el-col :span="10" style="height: 100%;display: flex;flex-direction: column;">
            <div style="display: flex">
              <el-radio v-model="mergeType" label="thisToOther">Влить в выбранный</el-radio>
              <el-radio v-model="mergeType" label="otherToThis">Слить в текущий</el-radio>
            </div>
            <el-input style="padding:10px 0 10px 0" placeholder="Поиск по названию" v-model="mergeFindText" @input="onMergeFindTextChange"></el-input>
            <el-table
                :select-on-indeterminate="false"
                ref="mergeTable"
                :data="mergeItems"
                highlight-current-row
                @current-change="onMergeCurrentRowChange"
                @select="onMergeRowSelect"
                @select-all="onSelectionAll"
                style="width: 100%;font-size: 16px"
                height="100%"
                :border="true"
            >
              <el-table-column type="selection" width="55"></el-table-column>
              <el-table-column property="name" label="Название">
                <div slot-scope="scope" style="display: flex">
                  <my-type-link :object="scope.row"
                                @click="onEditAffiliation(scope.row.id)"
                  ></my-type-link>
                  <div style="display: flex;flex-grow: 1;justify-content: flex-end">
                    <one-c-label
                        show-bases-in-label
                        v-if="scope.row.oneCId||scope.row.oneCIdNomotech"
                        :evrogen="!!scope.row.oneCId"
                        :nomotech="!!scope.row.oneCIdNomotech"
                    ></one-c-label>

                  </div>
                </div>


              </el-table-column>
              <el-table-column property="inn" label="ИНН" width="120"></el-table-column>
            </el-table>
          </el-col>
          <el-col :span="14" style="padding-left: 40px">
            <my-el-button
                :disabled="!mergeSelectedItems || mergeSelectedItems.length === 0"
                @click="onBtnDoMergeClick" type="primary" style="margin-top: 30px">
              {{ mergeType !== 'otherToThis' ? 'Влить в выбранный' : 'Слить в текущий' }}
            </my-el-button>
            <table class="withBottomBorder" style="width:100%;margin-top: 36px;border-top:solid #EBEEF5 1px;">
              <tr>
                <td rowspan="2" style="width: 100px">1C Евроген</td>
                <td style="width: 100px">Текущий</td>
                <td>{{ editAffiliation.oneCId }}</td>
              </tr>
              <tr>
                <td>Выбранный</td>
                <td>{{ mergeCurrentItem ? mergeCurrentItem.oneCId : '' }}</td>
              </tr>
              <tr>
                <td colspan="3" style="height: 20px"></td>
              </tr>
              <tr>
                <td rowspan="2" style="width: 100px">1C Номотек</td>
                <td style="width: 100px">Текущий</td>
                <td>{{ editAffiliation.oneCIdNomotech }}</td>
              </tr>
              <tr>
                <td>Выбранный</td>
                <td>{{ mergeCurrentItem ? mergeCurrentItem.oneCIdNomotech : '' }}</td>
              </tr>
              <tr>
                <td colspan="3" style="height: 20px"></td>
              </tr>


              <tr>
                <td rowspan="2" style="width: 100px">Персон.</td>
                <td style="width: 100px">Текущий</td>
                <td>{{ editAffiliation.isPerson ? 'Да' : 'Нет' }}</td>
              </tr>
              <tr>
                <td>Выбранный</td>
                <td>{{ mergeCurrentItem ? (mergeCurrentItem.isPerson ? 'Да' : 'Нет') : '' }}</td>
              </tr>
              <tr>
                <td colspan="3" style="height: 20px"></td>
              </tr>
              <tr>
                <td rowspan="2">Название</td>
                <td>Текущий</td>
                <td>{{ editAffiliation.name }}</td>
              </tr>
              <tr>
                <td>Выбранный</td>
                <td>{{ mergeCurrentItem ? mergeCurrentItem.name : '' }}</td>
              </tr>
              <tr>
                <td colspan="3" style="height: 20px"></td>
              </tr>
              <tr>
                <td rowspan="2">Короткое название</td>
                <td>Текущий</td>
                <td>{{ editAffiliation.shortName }}</td>
              </tr>
              <tr>
                <td>Выбранный</td>
                <td>{{ mergeCurrentItem ? mergeCurrentItem.shortName : '' }}</td>
              </tr>
              <tr>
                <td colspan="3" style="height: 20px"></td>
              </tr>
              <tr>
                <td rowspan="2">ИНН</td>
                <td>Текущий</td>
                <td>{{ editAffiliation.inn }}</td>
              </tr>
              <tr>
                <td>Выбранный</td>
                <td>{{ mergeCurrentItem ? mergeCurrentItem.inn : '' }}</td>
              </tr>
              <tr>
                <td colspan="3" style="height: 20px"></td>
              </tr>
              <tr>
                <td rowspan="2">КПП</td>
                <td>Текущий</td>
                <td>{{ editAffiliation.kpp }}</td>
              </tr>
              <tr>
                <td>Выбранный</td>
                <td>{{ mergeCurrentItem ? mergeCurrentItem.kpp : '' }}</td>
              </tr>

            </table>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
    <template slot="footer" slot-scope="{lockerState}">
      <div style="display: flex;justify-content: space-between;width:100%">
        <div style="display: flex">
          <my-el-button delete
                        v-if="!!id"
                        :disabled="!!errorsForDeleteAffiliationButton(lockerState)"
                        :dis-popover="errorsForDeleteAffiliationButton(lockerState)"
                        @click="onBtnDeleteClick"
                        style="margin-right: 15px"></my-el-button>
          <change-button :changes="changes"/>
        </div>
        <div style="display:flex">
          <my-el-button v-if="canClose" @click="onBtnCancelClick" type="warning">Отменить</my-el-button>
          <my-el-button :disabled="(!!id) && !lockerState.canEdit" @click="onBtnSaveClick(false)" type="success">Сохранить</my-el-button>
          <my-el-button v-if="canClose" :disabled="(!!id) && !lockerState.canEdit" @click="onBtnSaveClick(true)" type="success">Сохранить и закрыть</my-el-button>
        </div>
      </div>
    </template>
  </component-window>
</template>

<script>
import { alert, confirm } from '@/components/common/dialogs/dialogUtils';
import _ from 'lodash';
import clientEditor from "@/components/usersClientsAffiliation/ClientEditor";
import changeButton from '@/components/usersClientsAffiliation/ChangeButton';
import ClientAffiliationTable from "./ClientAffiliationTable";
import fileTable from '@/components/common/fileTable';
import OneCLabel from '@/components/common/oneCLabel';
import myTypeLink from '@/components/common/myTypeLink';
import affiliationEditor from '@/components/usersClientsAffiliation/AffiliationEditor';
import contractEditor from "@/components/usersClientsAffiliation/ContractEditor";


export default {
  name: 'AffiliationEditor',
  props: ['id', 'addBillingClient', 'onClose', 'lockBillingRecord', 'isTabView'],
  components: { myTypeLink, OneCLabel, changeButton, ClientAffiliationTable, fileTable },
  data() {
    return {
      lockerIsWatch: false,
      windowSize: { minHeight: 400, minWidth: 400, height: '80%', width: '900' },
      changes: [],
      activeTab: 'fields',

      mergeItems: [],
      mergeType: 'thisToOther',
      mergeFindText: null,
      mergeCurrentItem: null,
      mergeSelectedItems: null,

      editAffiliation: { id: 0, billingClientsRl: [], bankAccount: {}, contracts: [] },
      editClientId: null,
      lockBillingRecord_: null,
      rules: {
        name: [this.$validateRuleRequired],
        shortName: [this.$validateRuleRequired]
        //inn: [this.$validateRuleRequired, { type: 'string', pattern: /^\d{10}$/, message: 'Ошибочный инн' }],
        //kpp: [ { type: 'string', pattern: /^\d{9}$/, message: 'Ошибочный кпп' }]
      }
    };
  },
  watch: {
    mergeType() {
      this.mergeSelectedItems = null;
      this.$refs.mergeTable.clearSelection();
    }
  },

  async mounted() {
    if (!this.id) {
      if (this.addBillingClient) {
        this.editAffiliation.billingClientsRl.push({
                                                     affiliationId: 0,
                                                     id: -1,
                                                     clientId: this.addBillingClient.id,
                                                     client: this.addBillingClient,
                                                     usersRl: []
                                                   });
        this.lockBillingRecord_ = -1;
      }
      return;
    }
    await this.loadAffiliation(this.id);
    this.mergeFindText = this.editAffiliation.name;
    this.onMergeFindTextChange();
    if (this.isTabView) {
      document.title = 'О: ' + this.editAffiliation.name;
    }

    this.changes = await this.$store.dispatch('loadChanges', { type: 'affiliation', id: this.id });
  },

  methods: {
    async onBtnDownloadFileClick(file) {
      if (file.dataInBase64) {
        this.$myHttp.downloadFromDataLink(file.dataInBase64, file.showName, true);
      } else {
        await this.$myHttp.postWithDownload(`/api/files/getFile?id=${file.id}`,null,file.showName);
      }
    },


    async onDeleteContract(contract) {
      if (await confirm('Удалить договор?')) {
        if (!this.editAffiliation.id) {
          this.editAffiliation.contracts = this.editAffiliation.contracts.filter(c => c.id !== contract.id);
        } else {
          contract.forDelete = true;
          await this.onBtnSaveClick(false);
        }

        await this.$store.dispatch('deleteContract', { id: contract.id });
        this.editAffiliation.contracts = this.editAffiliation.contracts.filter(c => c.id !== contract.id);
      }
    },

    async onBtnShowContract(contract) {
      let resultContract = await this.$showWindowAsync(contractEditor, { contract });
      if (resultContract) {
        if (resultContract.id === 0) {
          this.editAffiliation.contracts.push(resultContract);
        } else {
          let index = this.editAffiliation.contracts.findIndex(c => c.id === resultContract.id);
          if (index >= 0) {
            this.editAffiliation.contracts.splice(index, 1, resultContract);
          }
        }
        await this.onBtnSaveClick(false);
      }
    },


    async onEditAffiliation(id) {
      await this.$showWindowAsync(affiliationEditor, { id });
    },

    async loadAffiliation(id) {
      this.lockerIsWatch = false;
      this.editAffiliation = await this.$store.dispatch("affiliations/loadItem", id);
      this.lockerIsWatch = true;
    },

    async onBtnSaveClick(withClose) {
      try {
        await this.$refs.editForm.validate()
      } catch (ex) {
        let attrs = _.find(this.$refs.editForm.fields, f => f.validateState === 'error').$attrs;
        if (attrs && attrs.tab) {
          this.activeTab = attrs.tab;
        }
        if (!withClose) {
          await alert("Не удалось сохранить организацию - на форме есть ошибки.");
        }
        return;
      }
      try {
        this.lockerIsWatch = false;
        this.editAffiliation = (await this.$store.dispatch('affiliations/saveItem', { affiliation: this.editAffiliation, changes: this.changes }));
      } catch (ex) {
        await alert(ex.message);
        return;
      }
      if (withClose) {
        if (this.isTabView) {
          window.close();
          return;
        }

        this.onClose && this.onClose(this.editAffiliation);
        this.$emit('close', this.editAffiliation);
      } else {
        this.lockerIsWatch = true;
        await this.$refs.compWindow.unlock();
      }
    },

    onBtnCancelClick() {
      if (this.isTabView) {
        window.close();
        return;
      }

      this.onClose && this.onClose(null);
      this.$emit('close');
    },

    async onClientEdit(id) {
      let client = await this.$showWindowAsync(clientEditor, { id });
      if (client) {
        const clientIdx = _.findIndex(this.editAffiliation.clients, item => item.id === client.id);
        if (client.affiliation.id !== this.editAffiliation.id) {
          this.editAffiliation.clients.splice(clientIdx, 1);
        } else {
          this.editAffiliation.clients.splice(clientIdx, 1, client);
        }
      }
    },

    onMergeFindTextChange: _.debounce(async function() {
      this.mergeSelectedItems = [];
      let query = [
        { fieldName: 'Name', op: 'like', values: [this.mergeFindText] },
        { isOr: false, fieldName: 'Id', op: 'neq', values: [this.editAffiliation.id] },
      ]
      this.mergeItems = await this.$store.dispatch('affiliations/loadItems', { query });
    }, 500),

    onMergeCurrentRowChange(row) {
      this.mergeCurrentItem = row;
    },

    onSelectionAll(rows) {
      if (this.mergeType !== 'thisToOther') {
        this.mergeSelectedItems = rows;
      } else {
        this.$refs.mergeTable.clearSelection();
      }
    },

    onMergeRowSelect(rows, row) {
      if (this.mergeType === 'thisToOther') {
        if (this.mergeSelectedItems && row === this.mergeSelectedItems[ 0 ]) {
          this.mergeSelectedItems = null;
        } else {
          this.$refs.mergeTable.clearSelection();
          this.$refs.mergeTable.toggleRowSelection(row);
          this.mergeSelectedItems = [row];
        }
      } else {
        this.mergeSelectedItems = rows;
      }
    },
    async onBtnDoMergeClick() {
      const message =
          "Организация(и):<br/>" +
          this.mergeSelectedItems.map(r => `${r.name}  ${r.inn ? ('(' + r.inn + ')') : ''}<br/>`).join('') +
          " будут удалены. Продолжить?";


      if (!await confirm(message)) {
        return;
      }
      try {
        let data = this.mergeType === 'otherToThis'
            ? { sourceIds: this.mergeSelectedItems.map(i => i.id), targetId: this.editAffiliation.id }
            : { sourceIds: [this.editAffiliation.id], targetId: this.mergeSelectedItems[ 0 ].id };
        await this.$store.dispatch('affiliations/doMerge', data);
      } catch (ex) {
        await alert(ex.message);
        return;
      }
      this.$refs.mergeTable.clearSelection();

      if (this.mergeType === "otherToThis") {
        await this.loadAffiliation(this.editAffiliation.id);
        await this.onMergeFindTextChange();
        this.mergeSelectedItems = [];
        return;
      }
      this.onBtnCancelClick();
    },

    errorsForDeleteAffiliationButton(lockerState) {
      if (!lockerState.canEdit) {
        return 'Запись редактирует другой пользователь.'
      }
      if (this.editAffiliation.clients && this.editAffiliation.clients.length > 0) {
        return 'Есть клиенты относящиеся к этой организации'
      }
      if (this.editAffiliation.contracts.length > 0) {
        return 'Есть договора относящиеся к этой организации. Нужно сначала их удалить.';
      }
      return '';
    },

    async onBtnDeleteClick() {
      if (!await confirm('Удалить организацию?', 'Удалить')) {
        return;
      }
      try {
        await this.$store.dispatch('affiliations/deleteItem', this.editAffiliation)
      } catch (ex) {
        await alert(ex.message);
        return;
      }
      this.onClose && this.onClose([this.editAffiliation, true]);
      this.$emit('close');
    },

    getStampData(srcArray) {
      if (!srcArray || srcArray.length === 0) {
        return "";
      }
      return 'data:image/jpeg;base64,' + srcArray[ 0 ].dataInBase64;
    }
  },

  computed: {
    canClose() {
      return !(this.isTabView && !opener);
    }
  }

}
</script>

<style>
.withBottomBorder td {
  border-bottom: solid #EBEEF5 1px;
  border-right: solid #EBEEF5 1px;
}

.el-tabs__content {
  height: 100%;
}
</style>
