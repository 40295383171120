<template>
    <component-window
            title="Куда переносить заказы"
            :maximize="false"
            @close="onBtnCancelClick">
        <div style="display: flex; flex-direction: column;height: 100%">
            <el-form :model="form" ref="form" label-width="0" label-position="top">
                <el-form-item label="Клиент" prop="client" :rules="[{ required: true, message: 'Поле должно быть заполнено.' }]">
                    <div style="display: flex;flex-wrap: nowrap;align-items: center">
                        <my-selector
                                type="client"
                                v-model="form.client"
                                :selector="onAutocompleteClientSearch"
                                buttons
                                @edit="onEditClient(form.client.id)"
                                @add="onEditClient(0)"
                        />
                        <div style="margin-left: 10px">
                            <el-switch
                                    v-model="allClients"
                                    active-text="Все"
                                    @change="onChangeAllClient"
                            ></el-switch>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="Заказчик" prop="client" :rules="[{ required: true, message: 'Поле должно быть заполнено.' }]">
                    <my-selector
                            type="user"
                            :selector="onAutocompleteUserSearch"
                            v-model="form.user"
                            :buttons='!user'
                            @edit="onEditUser(form.user.id)"
                            @add="onEditUser(0)"
                    />
                </el-form-item>
                <el-form-item label="Плательщик" prop="client" :rules="[{ required: true, message: 'Поле должно быть заполнено.' }]">
                    <div style="display: flex;flex-wrap: nowrap;align-items: center">
                    <my-selector
                            type="affiliation"
                            :selector="onAutocompletePayerSearch"
                            v-model="form.payer"
                    />
                        <div style="margin-left: 10px">
                            <el-switch
                                    v-model="allAffiliation"
                                    active-text="Все"
                            ></el-switch>
                        </div>
                    </div>
                </el-form-item>
            </el-form>
        </div>
        <template slot="footer">
            <div style="display:flex;justify-content: space-between;width: 100%">
                <div>
                    <my-el-button @click="onBtnCancelClick" type="warning">Отменить</my-el-button>
                </div>
                <div>
                    <my-el-button @click="onBtnSelect" type="success">Перенести</my-el-button>
                </div>
            </div>
        </template>
    </component-window>
</template>

<script>
    import clientEditor from '@/components/usersClientsAffiliation/ClientEditor';
    import userEditor from "@/components/usersClientsAffiliation/UserEditor";

    export default {
        name: 'MoveOrdersWindow',
        props: ['onClose', 'orders'],
        data() {
            return {
                clients: [],
                users: [],
                payers: [],
                form: { client: null, user: null, payer: null },
                allClients: false,
                allAffiliation: false,
                windowSize: { minHeight: 350, minWidth: 550, height: '350', width: '550' }
            }
        },
        async mounted() {
            await this.setDefaultClient();
        },
        
        watch: {
            'form.client': async function(newClient) {
                if (!newClient) {
                    this.users = [];
                    this.form.user = null;
                    this.form.payer = null;
                    return;
                }
                await this.loadUsers();  
                await this.setPayer();
            }
        },
        
        methods: {
            async onEditClient(id) {
                let client = await this.$showWindowAsync(clientEditor, { id });
                if (client) {
                    this.form.client = client;
                }
            },
    
            async onEditUser(id) {
                let user = await this.$showWindowAsync(userEditor, { id });
                if (user) {
                    this.form.user = user;
                }
            },
            
            setPayer() {
                if (this.form.client.billingAffiliationsRl.length === 1) {
                    this.form.payer = this.form.client.billingAffiliationsRl[ 0 ].affiliation;
                } else if (this.form.client.billingAffiliationsRl.length === 0) {
                    this.form.payer = this.form.client.affiliation;
                } else {
                    this.form.payer = null;
                }
            },
            
            async onChangeAllClient(value) {
                this.form.client = null;
                this.form.user = null;
                if (!value) {
                    await this.setDefaultClient();
                } 
            },
            
            async setDefaultClient() {
                const query = [{ isOr: false, fieldName: '@clientUserRls.userId', op: 'in', values: this.orderUserIds }];

                this.clients = await this.$store.dispatch('clients/loadItems', { query });
    
                if (this.clients.length === 1) {
                    this.form.client = this.clients[ 0 ];
                }
                if (this.clients.length === 2 && this.orderClientIds.length === 1 ) {
                    this.form.client = this.clients.find(c => c.id !== this.orderClientIds[0]);
                }
            },
            
            async loadUsers() {
                if (this.form.client) {
                    const query = [{ fieldName: '@clientUserRls.clientId', op: 'eq', values: [this.form.client.id] }];
                    this.users = await this.$store.dispatch('users/loadItems', { query });
                } else {
                    this.users = [];
                }
                switch(this.users.length) {
                    case 0: {
                        this.form.user = null;
                        return;
                    }
                    case 1: {
                        this.form.user = this.users[0];
                        return;
                    }
                    default: {
                        if (this.orderUserIds.length === 1) {
                            this.form.user = this.users.find(u => u.id === this.orderUserIds[0]);
                            return;
                        }
                        this.form.user = null;
                    }
                }
            },
    
            async onAutocompleteClientSearch(search, cb) {
                search = search || ''; 
                if (!this.allClients) {
                  cb(this.clients);  
                } else {
                    if (!search) {
                        cb([]);
                    } else {
                        const query = [{ fieldName: 'name', op: 'like', values: [search.trim()] }];
                        cb(await this.$store.dispatch('clients/loadItems', { query }));
                    }
                }
            },
            
            async onAutocompleteUserSearch(query, cb) {
                cb(this.users || []);
            },
            async onAutocompletePayerSearch(search, cb) {
                search = search?.trim() || '';
                if (this.form.client) {
                    if (!this.allAffiliation) {
                        let result = this.form.client.billingAffiliationsRl.map(rl => rl.affiliation);
                        if (this.form.client.affiliation && !result.some(a => a.id === this.form.client.affiliation.id) ) {
                            result.push(this.form.client.affiliation);
                        }
                        cb(!search ? result : result.filter(a => a.name.toLowerCase().includes(search.toLowerCase()) ) );
                    } else {
                        if (!search) {
                            cb([]); 
                            return;
                        }
                          let query = [ { fieldName: 'name', op: 'like', values: [ search ] } ];
                          let result = (await this.$store.dispatch('affiliations/loadItems', {query}));
                          cb(result);
                    }
                } else {
                    cb([])
                }
            },
            
            onBtnCancelClick() {
                this.onClose(null);
                this.$emit('close');
            },
            
            async onBtnSelect() {
                try {
                    await this.$refs.form.validate();
                } catch (ex) {
                    return;
                }
                this.onClose(this.form);
                this.$emit('close');
            }
        },
        computed: {
            orderUserIds() { return [...new Set(this.orders.map(o => o.userId))]; },
            orderClientIds() { return [...new Set(this.orders.map(o => o.clientId))];}
        }
    }
</script>

<style scoped>

</style>