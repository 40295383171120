<template>
  <div>
    <my-el-autocomplete
        v-if="!findText"
        :type="type"
        :disabled="disabled"
        :key-field="keyFields"
        :selectFunction="doSearch"
        :edit="buttons && !noEdit"
        :add="!disabled && buttons"
        :clear="!disabled"
        :value="value"
        v-on="$listeners"
        ::select-when-unmatched="false"
        :placeholder="computedPlaceHolder"
        @new-window="onClickNewWindow"
        @enter="setFindText"
        @edit="onClickEdit"
        @change-find-text="$emit('change-find-text', $event)"
    >
      <template slot-scope="{ item }">
        <template v-if="type==='user'">
          <div style="display: flex;flex-direction: column;padding: 6px 0 6px 0">
            <div style="display: flex; justify-content: space-between;align-items: center">
              <div style="display: flex; line-height: normal;font-weight: bold;margin-right: 15px">{{ item.fio }}</div>
              <div style="display: flex;flex-wrap: nowrap">
                <i v-if="item.isChecked" class="el-icon-circle-check" style="font-weight: bold;color:green; margin-right: 5px; align-self: center;"></i>
              </div>
            </div>
            <div>
              <div v-if="item.login || item.oldLogin" style="font-size: 12px; line-height: initial; word-break: break-word;font-family: Helvetica Neue,Arial,Helvetica,sans-serif;">
                {{ `Логин: ${item.login || item.oldLogin}`}}
                </div>
            </div>
          </div>
        </template>
        <template v-else-if="type==='client'">
          <div style="display: flex;flex-direction: column;margin: 6px 0 6px 0;">
            <div style="display: flex; justify-content: space-between;align-items: center">
              <div style="display: flex; line-height: normal;font-weight: bold;margin-right: 15px;word-break: break-word; white-space: break-spaces">{{ item.showName }}</div>
              <div style="display: flex;flex-wrap: nowrap">
                <i v-if="item.isCheck"
                   :class="item.defaultPayType === 'fromAccount' ? 'el-icon-circle-check' : 'el-icon-check'"
                   style="font-weight: bold;color:green; margin-right: 5px; align-self: center;"></i>
                <div><my-client-debt-warning :client="item"></my-client-debt-warning>  ID: {{ item.id }}</div>
              </div>
            </div>
            <div>
              <div style="font-size: 12px; line-height: initial; word-break: break-word; white-space: break-spaces; font-family: Helvetica Neue,Arial,Helvetica,sans-serif;">{{ item.affiliation ? `${item.affiliation.name}` : '' }}</div>
            </div>
          </div>
        </template>
        <template v-else-if="type==='affiliation'">
          <div style="display: flex; justify-content: space-between;align-items: center;margin: 6px 0 6px 0; max-width: 1200px" :key="item.id">
            <div style="line-height: normal;font-weight: bold; white-space: break-spaces;margin-right: 15px">{{ item.name }}</div>
            <div style="display: flex;flex-wrap: nowrap; width: 50px; justify-content: flex-end;">
              <one-c-label
                  v-if="item.oneCId || item.oneCIdNomotech"
                  :evrogen="!!item.oneCId"
                  :nomotech="!!item.oneCIdNomotech"
              />
            </div>
          </div>
        </template>
      </template>
    </my-el-autocomplete>
    <el-input v-if="withFindText && findText"
              v-model="findText"
              @input="onInputText"
              @keydown.native.enter="setFindText(findText)"
    >
      <i slot="suffix" @click="setFindText('')" class="el-input__icon el-icon-close"></i>
    </el-input>
  </div>
</template>

<script>
import { alert } from '@/components/common/dialogs/dialogUtils';
import OneCLabel from "@/components/common/oneCLabel";
import MyClientDebtWarning from "@/components/common/myClientDebtWarning";

export default {
  name: "MySelector",
  components: { MyClientDebtWarning, OneCLabel },
  props: {
    value: { default: null, type: Object },
    type: { default: '', type: String },
    buttons: { default: false, type: Boolean },
    noEdit: { default: false, type: Boolean },
    disabled: { default: false, type: Boolean },
    placeholder: { default: '', type: String },
    selector: { type: Function },
    withFindText: { default: false, type: Boolean }
  },
  data() {
    return {
      findText: '',
      ph: {
        user: 'Заказчик',
        client: 'Клиент',
        affiliation: 'Организация'
      },
      fn: {
        user: this.userSearch,
        client: this.clientSearch,
        affiliation: this.affiliationSearch
      },
      kf: {
        user: 'showName',
        client: 'showName',
        affiliation: 'name'
      }
    }
  },

  methods: {
    setFindText(value) {
      if (!this.withFindText) {
        return;
      }
      this.findText = value;
      this.$emit('change-find-text', value)
      this.$emit('enter-find-text', value)
    },
    onInputText(value) {
      if (!this.withFindText || value.trim()) {
        return;
      }
      this.findText = '';
      this.$emit('change-find-text', '')
      this.$emit('enter-find-text', '')
     },

    onClickNewWindow() {
      window.open(`/${this.type}/${this.value.id}`, '_blank');
    },

    async doSearch(query, callback) {
      query = query?.trim() || '';
      if (this.selector) {
        await this.selector(query, callback);
        return;
      }
      let result;
      try {
        result = await this.fn[ this.type ](query);
      } catch (ex) {
        callback([]);
        await alert(`Ошибка получения элементов. ${ex.message}`);
        return;
      }
      callback(result);
    },
    async userSearch(query) {
      query = query.trim();
      const filter = {
        orderBy: 'lName',
        orderIsDesc: false,
        pageSize: 200,
        currentPage: 1,
      };
      if (query) {
        if (query[0]==='*') {
          filter.query = [{ fieldName: 'fio', op: 'like', values: [query.substring(1)] }];
        } else {
          filter.query = [{ fieldName: 'fio', op: 'startsWith', values: [query] }];
        }
      }
      return (await this.$store.dispatch('users/loadItems', filter)).items;
    },

    async clientSearch(searchText) {
      searchText = searchText?.trim() || '';
      const filter = {
        orderBy: 'Name',
        orderIsDesc: false,
        pageSize: 50,
        currentPage: 1,
      };

      let isIdFind = (/^\d+$/gi).test(searchText);
      if (isIdFind) {
        filter.query = [{ fieldName: 'id', op: 'eq', values: [searchText] }];
      } else {
        if (searchText) {
          filter.query = [{ fieldName: 'name', op: 'like', values: [searchText] }];
        } else {
          filter.query = [{ fieldName: 'name', op: 'neq', values: [''] }];
        }
      }
      return (await this.$store.dispatch('clients/loadItems', filter)).items;

    },
    async affiliationSearch(searchText) {
      const filter = {
        orderBy: 'Name',
        orderIsDesc: false,
        pageSize: 50,
        currentPage: 1,
      };
      if (searchText) {
        filter.query = [{ fieldName: 'name', op: 'like', values: [searchText] }];
      } else {
        filter.query = [{ fieldName: 'name', op: 'neq', values: [''] }];
      }
      return (await this.$store.dispatch('affiliations/loadItems', filter)).items;
    }
  },
  computed: {
    computedPlaceHolder() {
      if (this.placeholder === false) {
        return '';
      }
      return this.placeholder
          ? this.placeholder
          : this.ph[ this.type ];
    },
    keyFields() {
      return this.kf[ this.type ];
    }
  }
}
</script>

<style scoped>

</style>
