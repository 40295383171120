import { store } from '@/store';
export default {
    install (Vue) {
        Vue.prototype.$getEnumValue = function(enumName, enumId) {
            let enm = store.state.shared.enums.find(e => e.name === enumName);
            if (!enm) {
                return '';
            }
            let result = enm.values.find(e => e.value === enumId);
            return result ? result.name : '';
        };
    
        Vue.prototype.$getEnum = function(enumName) {
            let res = store.state.shared.enums.find(e => e.name === enumName);
            return res ? res.values : [];
        };
        Vue.getEnum = Vue.prototype.$getEnum;
        
        Vue.prototype.$deliveryServiceById = function(id) {
            return this.$getEnum('deliveryServices').find(d => d.id === id);
        }
    
    }
}