import _ from 'lodash';
import moment from 'moment-business-days';

export function createNewSubOrder( metaorder, deliveries, deadlines,  orderType, special='', orderState='') {
    let state = orderState ? orderState : ( ['seq', 'ngs'].includes(orderType) ? 'waitDelivery' : 'newOrder');

    let order = newSubOrder(metaorder, orderType, state, deadlines, special === 'forSequence');
    metaorder.orders.push( order );

    let deliveryType = 'simple';
    deliveryType = ['seq', 'ngs'].includes(order.type) ? 'reverse' : deliveryType;
    deliveryType = order.forSequence ? 'forSequence' : deliveryType;
    let delivery = findOrCreateDelivery(metaorder, deliveries, deliveryType);
    addOrderToDelivery(delivery, order, metaorder);
    if (order.type === 'ngs') {
        let delivery = findOrCreateDelivery(metaorder, deliveries, 'forNgs');
        addOrderToDelivery(delivery, order, metaorder);
    }
    
    
    return order;
}
export function createNewSubOrderForSplitSeq( metaorder, srcOrder, deliveries, deadlines, withNewDelivery ) {
    let state = withNewDelivery ? 'waitDelivery' : srcOrder.state;
    let order = newSubOrder(metaorder, srcOrder.type, state, deadlines, false);
    metaorder.orders.push( order );
    
    let delivery = null;
    if (withNewDelivery) {
        delivery = createDelivery(deliveries, 'reverse', metaorder);
    } else {
        delivery = deliveries.find(dlv => dlv.type === 'reverse' && dlv.ordersRl.some(rl => rl.orderId === srcOrder.id ));
    }
    addOrderToDelivery(delivery, order, metaorder);
    return order;
}

export function createNewSubOrderForSplitPdtOrPrimer( metaorder, srcOrder, deliveries, deadlines, withNewDelivery, type ) {
    let order = newSubOrder(metaorder, type, srcOrder.state, deadlines, false);
    order.discountPercent = srcOrder.discountPercent;
    metaorder.orders.push( order );
    
    let newDelivery;
    let srcDelivery = deliveries.find(dlv => dlv.type === 'simple' && dlv.ordersRl.some(rl => rl.orderId === srcOrder.id ));
    if (withNewDelivery) {
        newDelivery = createDelivery(deliveries, 'simple', metaorder)
    } else {
        newDelivery = srcDelivery;
    }
    addOrderToDelivery(newDelivery, order, metaorder);
    return order;
}


function addOrderToDelivery(delivery, order, metaorder) {
    delivery.ordersRl.push({
                               orderId: order.id,
                               deliveryId: delivery.id,
                               order,
                               delivery,
                               price: metaorder?.client?.defaultDeliveryPrice || 0,
                               forDelete: false
                           });
}

function newSubOrder(metaorder, orderType, orderState, deadlines, isForSequence) {
    let minId = _.min( metaorder.orders.map( o => o.id) );
    minId =  (! minId ) || minId > 0 ? -1 : (minId - 1);
    return {
        id: minId,
        metaorderId: metaorder.id,
        type: orderType,
        state:  orderState,
        forSequence: isForSequence,
        deadline: deadlines[orderType] ? moment().businessAdd(deadlines[orderType]).format('YYYY-MM-DDT00:00:00') : null,
        elements:[],
        orderFiles: [],
        price:0,
        discountPrice:0,
        handPrice: 0,
        discountPercent: getDefaultDiscount(metaorder.client, orderType),
        noWaitSequenceResult: metaorder.user?.defaultNoWaitSequenceResult || false,
        primers: orderType === 'seq' ? [] : undefined,
        mailsForResult: '',
        prior: metaorder.orders.length === 0 ? 0 : (_.max( metaorder.orders.map( o => o.prior) ) + 1),
        pcrCount: 0,
        dnaExtractCount: 0,
        projectNumber:'',
        executorPrice:0
    };
}


function getDefaultDiscount(client, orderType) {
    if (!client) return 0;
    switch(orderType) {
        case 'mst':
        case 'sst': return client.simplePrimerDiscount * 1;
        case 'pdt': return client.productDiscount * 1;
        case 'seq': return client.sequenceDiscount * 1;
        default: return 0;
    }
}

export function findOrCreateDelivery( metaorder, deliveries, deliveryType ) {
    //Выбираем доставки с подходящим isReverse и в подходящем состоянии
    let findFn = d => d.type === deliveryType
        && ( ( d.type === 'reverse' && d.state === 'waitDelivery'  )
            || (d.type !== 'reverse' && ( d.state === 'inCompile' || d.state === 'readyToSend' )));
    
    let delivery = _.minBy(deliveries.filter( findFn )  , r => r.id);
    
    (! delivery) && (delivery = createDelivery(deliveries, deliveryType, metaorder));
    return delivery;
}
function getNewDeliveryId(deliveries) {
    let minId = _.min(deliveries.map(d => d.id));
    return (!minId || minId > 0) ? -1 : (minId - 1);
}

export function createDelivery(deliveries, deliveryType,  metaorder) {
    let delivery = {
        id: getNewDeliveryId(deliveries),
        state: deliveryType === 'reverse' ? 'waitDelivery' : 'inCompile',
        type: deliveryType,
        clientId: metaorder?.clientId,
        client: metaorder?.client,
        recipient: metaorder?.user,
        recipientId: metaorder?.userId,
        addressId: metaorder?.client?.addresses.length === 1 ? metaorder.client.addresses[0].id : null,
        address: metaorder?.client?.addresses.length === 1 ? metaorder.client.addresses[0] : null,
        calcDeliveryPrice: metaorder?.client?.defaultCalcDeliveryPrice,
        ordersRl: [],
        files: [],
        stateLog: [],
        woDelivery: deliveryType === 'reverse'
    };
    if (!delivery.address) {
        if (metaorder?.user?.oldPdUserJson) {
            delivery.oldAddress = JSON.parse(metaorder.user.oldPdUserJson).info_c;
        }
        if (metaorder?.user?.oldRuUserJson) {
            delivery.oldAddress = JSON.parse(metaorder.user.oldRuUserJson).info_c;
        }
    }
    deliveries.push(delivery);
    return delivery;
}

export function removeOrder(id, metaorder, deliverys) {
    const idx = _.findIndex(metaorder.orders, o => o.id === id );
    if (metaorder.orders[idx].id > 0) {
        metaorder.orders[idx].state = 'deleted';
    } else {
        metaorder.orders.splice(idx, 1);
    }
    //TODO: Переделать удаление доставки на изменение state = deleted
    let delivery = deliverys.find(d => d.ordersRl.some( o => o.orderId === id ));
    if (!delivery) {
        return;
    }
    let rl = delivery.ordersRl.find(rl => rl.orderId === id);
    if (id < 0) {
        delivery.ordersRl = delivery.ordersRl.filter(rl => rl.orderId !== id);
    } else {
      rl.forDelete = true;
    }
    if (!delivery.ordersRl.some(rl => !rl.forDelete)) {
        if (delivery.id < 0 ) {
            let id = deliverys.findIndex(d => d.id === delivery.id);
            deliverys.splice(id, 1);
        } else {
            delivery.forDelete = true;
        }
    }
}

export  async function loadPrimerPrice(vue, primers, discountPercent ) {
    primers = Array.isArray(primers) ? primers : [primers];
    const prices = await vue.$store.dispatch('prices/calcPrimerPrice', { primers, discountPercent });
    primers.forEach( primer => {
        primer.price = prices[primer.id].price;
        primer.discountPrice = prices[primer.id].discountPrice;
        primer.priceCalcStrategy = prices[primer.id].priceCalcGroup;
    } );
}

export function recalcPrimerOrder( order ) {
    let fltElements = order.elements.filter(o => !o.forDelete);
    order.containsPrimersWithClean = fltElements.some(item => item.optionsCleaning);
    order.containsPrimersWithKeen = fltElements.some(item => item.optionsKeen);
    order.containsPrimersWithResidiment = fltElements.some(item => item.optionsResidiment);
    order.price = Math.round(_.sumBy(fltElements, r => r.price) * 100) / 100;
    order.discountPrice = Math.round(_.sumBy(fltElements, r => r.discountPrice) * 100) / 100;
    order.primersRealPrice = Math.round(_.sumBy(fltElements, r => r.discountPrice) * 100) / 100;
}

let noCalcStates = ['delete', 'error', 'canceled'];
let calcOptionsInAnyState = ['cleanGel','plasmidExtract', 'cleanFromPiece'];


export function seqOrderSummary( order ) {
    let calcDiscount = (price) =>  (price - Math.round(price / 100 * (order.discountPercent || 0) * 100) / 100);
    
    let result = order.elements
                    .filter(fr => !fr.forDelete && fr.option && fr.option !== 'none')
                    .filter(fr => fr.primersRls.some(rl => !noCalcStates.includes(rl.state))
                                  || ( fr.primersRls.every(rl => noCalcStates.includes(rl.state)) && calcOptionsInAnyState.includes(fr.option)))
                    .reduce((acc, fr) => {
                        let fndItem = acc.find(i => i.key === fr.option);
                        if (!fndItem) {
                            fndItem = {key: fr.option,  sum: 0, discountSum: 0, count: 0, price: fr.optionPrice, discountPrice: fr.optionPrice };
                            acc.push(fndItem);
                        }
                        fndItem.sum += fr.optionPrice;
                        fndItem.discountSum += fr.optionPrice;
                        fndItem.count ++;
                        return acc;
                    }, [] );
    result.push({key: 'fragments', count: order.elements.filter(fr => !fr.forDelete).length, sum: 0, discountSum: 0, price: 0, discountPrice: 0});
    result.push({key: 'primers', count: order.primers?.filter(e => !e.forDelete)?.length, sum: 0, discountSum: 0, price: 0, discountPrice: 0});

    let count = order.elements
            ?.filter(fr => !fr.forDelete
                            && fr.action === 'fragmentAnalyze'
                            && fr.primersRls.some(rl => !rl.forDelete && !noCalcStates.includes(rl.state)))?.length || 0;
    result.push({key: 'fragmentAnalyze',
                 count,
                 sum: count * order.elementFragmentAnalyzePrice,
                 discountSum: count * calcDiscount(order.elementFragmentAnalyzePrice),
                 price: order.elementFragmentAnalyzePrice,
                 discountPrice: calcDiscount(order.elementFragmentAnalyzePrice)
                });
    
    result.push( Object.assign(
        order.elements
         ?.filter(fr => !fr.forDelete && fr.action !== 'fragmentAnalyze' )
         ?.reduce((acc, fr) => {
            let count = fr.primersRls.filter(rl => !rl.forDelete && !noCalcStates.includes(rl.state)).length;
            acc.count += count;
            acc.sum += order.elementSequencePrice * count;
            acc.discountSum += calcDiscount(order.elementSequencePrice) * count;
            return acc;
        }, {count: 0, sum: 0, discountSum: 0 }) || {count: 0, sum: 0, discountSum: 0},
        {
            key: 'sequence',
            price: order.elementSequencePrice,
            discountPrice: calcDiscount(order.elementSequencePrice)
        }
    ));
    
    result.push({ key: 'dnaExtract',
                    count: order.dnaExtractCount || 0,
                    sum: order.dnaExtractPrice * order.dnaExtractCount || 0,
                    discountSum: order.dnaExtractPrice * order.dnaExtractCount || 0,
                    price: order.dnaExtractPrice,
                    discountPrice: order.dnaExtractPrice,
                });
    result.push({ key: 'primerDesign',
                    count: order.primerDesignCount || 0,
                    sum: order.primerDesignPrice * order.primerDesignCount || 0,
                    discountSum: order.primerDesignPrice * order.primerDesignCount || 0,
                    price: order.primerDesignPrice,
                    discountPrice: order.primerDesignPrice,
                });
    result.push({ key: 'contigCompile',
                    count: order.contigCompileCount || 0,
                    sum: order.contigCompilePrice * order.contigCompileCount || 0,
                    discountSum: order.contigCompilePrice * order.contigCompileCount || 0,
                    price: order.contigCompilePrice,
                    discountPrice: order.contigCompilePrice,
                });
    
    
    
    
    const pcrCount = order.elements.filter(fr => !fr.forDelete && fr.withPcr &&
                                                  fr.primersRls.some(rl => !rl.forDelete && !['delete', 'error'].includes(rl.state))
                                          ).length + (order.pcrCount  || 0);
    
    result.push({ key: 'pcr',
                  count: pcrCount,
                  sum: order.pcrPrice * pcrCount,
                  discountSum: order.pcrPrice * pcrCount,
                  price: order.pcrPrice,
                  discountPrice: order.pcrPrice  });
    return result;
}



