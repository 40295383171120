import vue from 'vue';

export default function buildStateReportsStore() {
    return {
        namespaced: true,
        actions: {
            async primerSum(context, params) {
                return await vue.prototype.$myHttp.post('/api/reports/getPrimerSum', params);
            },
            
            async synthesisSum(context, params) {
                return await vue.prototype.$myHttp.post('/api/reports/getPrimerSynthesisSum', params);
            },
            
            async getConsumables() {
                return await vue.prototype.$myHttp.post('/api/reports/GetConsumables');
            },
            //--------------------------------------------------------
            async getInvoicesInfo(context, params) {
                return await vue.prototype.$myHttp.post('/api/reports/GetInvoicesInfo', params);
            },
            //--------------------------------------------------------
            async getYearGroupReport(context, params) {
                return await vue.prototype.$myHttp.post('/api/reports/getYearGroupReport', params);
            },
            async getQuarterGroupReport(context, params) {
                return await vue.prototype.$myHttp.post('/api/reports/getQuarterGroupReport', params);
            },
    
            async getGroupQuarterYearAndPrmReport(context, params) {
                return await vue.prototype.$myHttp.post('/api/reports/getGroupQuarterYearAndPrmReport', params);
            },
            async getYearGroupByGroupsReport(context, params) {
                return await vue.prototype.$myHttp.post('/api/reports/getYearGroupByGroupsReport', params);
            },
            async getClientMonthGroupReport(context, params) {
                return await vue.prototype.$myHttp.post('/api/reports/getClientMonthGroupReport', params);
            },
            async getManagerPlates(context, params) {
                return await vue.prototype.$myHttp.post('/api/reports/getManagerPlates', params);
            },
            async getSequenceQuarterReport(context, params) {
                return await vue.prototype.$myHttp.post('/api/reports/GetSequenceQuarterReport', params);
            },
            async getPrimerQuarterReport(context, params) {
                return await vue.prototype.$myHttp.post('/api/reports/getPrimerQuarterReport', params);
            },
    
            async getNgsQuarterReport(context, params) {
                return await vue.prototype.$myHttp.post('/api/reports/getNgsQuarterReport', params);
            },
            
    
            async GetSequenceOrderDynamicDataReport(context, params) {
                return await vue.prototype.$myHttp.post('/api/reports/GetSequenceOrderDynamicDataReport', params);
            },

            async calcSeqOrderByOptionAndOperator(context, params) {
                return await vue.prototype.$myHttp.post('/api/reports/calcSeqOrderByOptionAndOperator', params);
            },
        }
    }
}
