<template>
  <div style="display:flex;flex-direction: column; width: 50%;margin-right: 5px">
    <div style="display: flex; justify-content: space-between">
      <div style="display: flex;flex-direction: column;margin-bottom: 2px">
        <my-el-find-input v-model="find"
                          style="width: 200px;margin-bottom: 2px"
                          :placeholder="isRight ? 'Счет, заказ, подзаказ, доставка' : 'Заказ, подзаказ, доставка'"
                          with-clear
                          @change="find=$event"
                          @find="filter.currentPage = 1; loadItems()"
        ></my-el-find-input>
        <el-date-picker
            v-model="dateRange"
            type="daterange"
            start-placeholder="С"
            end-placeholder="По"
            :editable="false"
            :default-time="['00:00:00', '23:59:59']"
            style="max-width:200px"
            @change="filter.currentPage = 1; loadItems()"
            :picker-options="{firstDayOfWeek: 1}"
        >
        </el-date-picker>
      </div>
      <div style="display: flex;flex-direction: column;justify-content: flex-end">
        <el-pagination align="right"
                       @size-change="onPagerSizeChange"
                       @current-change="onCurrentPageChange"
                       :current-page.sync="filter.currentPage"
                       :page-sizes="[25, 50, 100, 200, 300, 400]"
                       :page-size="filter.pageSize"
                       layout="sizes, prev, pager, next"
                       :total="count">
        </el-pagination>
      </div>
    </div>
    <el-table :data="items"
              style="font-size: 16px"
              :border="true"
              height="100%"
              :default-sort="{prop: 'createdAt', order: 'descending'}"
              v-ls-saver:[tableSaveName]
    >
      <el-table-column prop="createdAt" width="120" title="Дата">
        <template slot-scope="scope">
          <my-date :date="scope.row.createdAt"></my-date>
        </template>
      </el-table-column>
      <el-table-column width="110" label="Сумма">
        <template slot-scope="scope">
          <template v-if="(!scope.row.key) && ((!isGroup) || ['correction_', 'toClient_', 'fromClient_'].some(s => scope.row.key.startsWith(s)))">
            <a class="my-link" @click="editComment(scope.row)">{{ $roundFmt(scope.row.sum + (scope.row.commission || 0) ) }}</a>
          </template>
          <a v-else class="dis-my-link">{{ $roundFmt(scope.row.sum) }}</a>
          <el-popover v-if="scope.row.comment" placement="top-start" trigger="hover">
            <div style="display: flex;flex-direction: column">
              <pre style="outline: 0">{{ scope.row.comment }}</pre>
            </div>
            <i class="el-icon-info"
               slot="reference"
               style="cursor: pointer;font-size: 12px;margin-left: 3px;color: rgb(64, 158, 255)"
            ></i>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <template v-if="scope.row.key && isGroup">
            <div v-if="scope.row.key.startsWith('correction_')" style="display: flex; justify-content: space-between;align-items: center">
              Корректировка
              <my-el-button delete @click="rejectCorrection(scope.row)"></my-el-button>
            </div>
            <div v-if="scope.row.key.startsWith('toClient_')" style="display: flex; justify-content: space-between;align-items: center">
              <div>Передача денег клиенту: <a v-if="scope.row.relatedClient" class="my-link" @click="showClient(scope.row.relatedClient.id)">{{ scope.row.relatedClient.name }}</a></div>
              <my-el-button delete @click="rejectCorrection(scope.row)"></my-el-button>

            </div>
            <div v-if="scope.row.key.startsWith('fromClient_')" style="display: flex; justify-content: space-between;align-items: center">
              <div>Получение денег от клиента: <a v-if="scope.row.relatedClient" class="my-link" @click="showClient(scope.row.relatedClient.id)">{{ scope.row.relatedClient.name }}</a></div>
              <my-el-button delete @click="rejectCorrection(scope.row)"></my-el-button>
            </div>
            <div v-else-if="scope.row.key.startsWith('o_')" style="display: flex">
              <template v-if = "scope.row.metaorder.payType === 'fromInvoice' || scope.row.metaorder.payType === 'fromSber' ">
                Оплата по безналу/карте
              </template>
              <template v-else>
                <template v-if = "scope.row.records && scope.row.records.some(r => r.type === 'toOrderFromAccount')">
                  Оплата с вн. счета
                </template>
                <template v-else>
                  Вн. счет, НЕ оплачено
                </template>
              </template>
              <my-metaorder-number
                  style="margin-left: 5px;"
                  :metaorder="scope.row.metaorder"/>
            </div>
            <div v-else-if="scope.row.key.startsWith('i_')" style="display: flex">
              по счету
              <div class="my-link" style="margin-left: 3px" @click="showInvoice(scope.row.invoice.id)">{{ scope.row.invoice ? scope.row.invoice.number : '' }}</div>
            </div>
          </template>
          <template v-else>
            <div v-if="scope.row.type === 'toOrder' || scope.row.type === 'toOrderFromAccount'" style="display: flex">
              <div v-if="scope.row.deliveryId" style="display: flex">
                <span style="margin-right: 5px">
                <template v-if="scope.row.type === 'toOrder'">
                  {{ ['fromInvoice','fromSber'].includes(scope.row.metaorder.payType) ? 'Оплата по безналу/карте (доставка)' : 'Вн. счет, НЕ оплачено (доставка)' }}
                </template>
                <template v-else>
                  Оплата с вн. счета (доставка)
                </template>
                </span>
                <my-link :name="`#${scope.row.deliveryId}`" @click="showDelivery(scope.row.deliveryId)"></my-link>
                <span style="margin-left: 5px">для заказа</span>
                <order-number
                    style="margin-left: 5px;"
                    :order="scope.row.order"
                    :metaorder="scope.row.metaorder"/>
              </div>
              <div v-else style="display: flex">
                <template v-if="scope.row.type === 'toOrder'">
                  {{ ['fromInvoice','fromSber'].includes(scope.row.metaorder.payType) ? 'Оплата по безналу/карте' : 'Вн. счет, НЕ оплачено' }}
                </template>
                <template v-else>
                  Оплата с вн. счета
                </template>
                <order-number
                    style="margin-left: 5px;"
                    :order="scope.row.order"
                    :metaorder="scope.row.metaorder"/>
              </div>
            </div>

            <div v-if="scope.row.type === 'fromInvoice'" style="display: flex">
              Поступление по счету №
              <div class="my-link" style="margin-left: 3px" @click="showInvoice(scope.row.invoiceId)">{{ scope.row.invoice.number }}</div>
            </div>
            <div v-if="scope.row.type === 'fromInvoiceToOrder'" style="display: flex">
              Оплата заказа
              <my-metaorder-number
                  style="margin-left: 5px;margin-right: 5px"
                  :metaorder="scope.row.metaorder"/>
              по счету
              <div class="my-link" style="margin-left: 3px" @click="showInvoice(scope.row.invoiceId)">{{ scope.row.invoice.number }}</div>
            </div>
            <div v-if="scope.row.type === 'fromSberToOrder'" style="display: flex">
              Оплата заказа
              <my-metaorder-number
                  style="margin-left: 5px;margin-right: 5px"
                  :metaorder="scope.row.metaorder"/>
              через сбербанк-онлайн (счет
              <div class="my-link" style="margin-left: 3px" @click="showInvoice(scope.row.invoiceId)">{{ scope.row.invoice.number }}</div>
              )
            </div>
            <div v-if="scope.row.type === 'correction'" style="display: flex; justify-content: space-between;align-items: center">
              Корректировка
              <my-el-button delete @click="rejectCorrection(scope.row)"></my-el-button>
            </div>
            <div v-if="scope.row.type === 'toClient'" style="display: flex; justify-content: space-between;align-items: center">
              <div>Передача денег клиенту: <a class="my-link" @click="showClient(scope.row.relatedClientId)">{{ scope.row.relatedClient.name }} </a></div>
              <my-el-button delete @click="rejectCorrection(scope.row)"></my-el-button>
            </div>
            <div v-if="scope.row.type === 'fromClient'" style="display: flex; justify-content: space-between;align-items: center">
              <div>Получение денег от клиента: <a class="my-link" @click="showClient(scope.row.relatedClientId)">{{ scope.row.relatedClient.name }}</a></div>
              <my-el-button delete @click="rejectCorrection(scope.row)"></my-el-button>
            </div>
          </template>
        </template>
        <template v-slot:header>
          <div style="display: flex;flex-wrap: nowrap;justify-content: space-between;align-items: center">
            <div style="display: flex; align-items: center;white-space: nowrap">Сумма с учетом фильтра: <span style="margin-left: 5px;color:black">{{ $roundFmt(sum) }}</span></div>
            <my-el-select
                v-model="isChangeBalance"
                style="width:150px;margin-left:20px;margin-right:20px;padding-bottom: 3px"
                placeholder="Влияние на баланс"
                @change="filter.currentPage = 1;loadItems()"
                :value="isChangeBalance">
              <el-option label="Все" :value="null"/>
              <template v-if="!isRight">
                <el-option label="Оплата с вн. счета" :value="0"/>
                <el-option label="Оплата по безналу/карте" :value="1"/>
                <el-option label="Вн. счет, НЕ оплачено" :value="2"/>
                <el-option label="Корректировка/передача" :value="3"/>
              </template>
              <template v-else>
                <el-option label="Влияет на вн.счет" :value="true"/>
                <el-option label="Не влияет на вн.счет" :value="false"/>
              </template>
            </my-el-select>

            <div style="text-align: right">
              По {{ isRight ? 'счетам' : 'заказам' }}
              <el-switch v-model="isGroup" @change="filter.currentPage = 1;loadItems()" style="margin-left: 5px"/>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <change-monitor name="paymentTable" :items="monitoringItems" @change="onChangesElements" ref="changeMonitor"></change-monitor>
  </div>
</template>

<script>
import tableSaver from '@/utils/tableSaver';
import clientEditor from '@/components/usersClientsAffiliation/ClientEditor';
import invoiceEditor from '@/components/invoices/InvoiceEditor';
import deliveryEditor from '@/components/deliveries/DeliveryEditor';
import MyMetaorderNumber from "@/components/common/myMetaorderNumber";
import OrderNumber from '@/components/common/orderNumber';
import { confirm } from '@/components/common/dialogs/dialogUtils';
import ChangeMonitor from "@/components/common/changeMonitor";

let editCommentWindow = {
  name: 'editCommentWindow',
  template: `
    <component-window
        title="Редактирование комментария"
        :maximize="false"
        @close="onBtnCancelClick">
    <div style="display: flex; flex-direction: column;height: 100%">
      <div style="height: 100%">
        <el-form label-position="left" style="height:100%;display: flex;flex-direction: column">
          <el-form-item label="Комментарий" label-position="left" size="100%" prop="comment" style="height:100%;display: flex;flex-direction: column">
            <el-input type="textarea" autosize v-model="comment" style="height: 100%"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <template slot="footer">
      <div style="display:flex;justify-content: space-between;width: 100%">
        <div>
          <my-el-button @click="onBtnCancelClick" type="warning">Отменить</my-el-button>
        </div>
        <div>
          <my-el-button @click="onBtnSet" type="success">Сохранить</my-el-button>
        </div>
      </div>
    </template>
    </component-window>`,
  props: ['onClose', 'comment'],
  data() {
    return {
      comment: '',
      windowSize: { minHeight: 250, minWidth: 600, height: '250', width: '600' }
    }
  },
  methods: {
    onBtnCancelClick() {
      this.onClose(null);
      this.$emit('close');
    },
    async onBtnSet() {
      this.onClose(this.comment);
      this.$emit('close');
    }
  }
};


export default {
  name: 'PayLogTable',
  components: { ChangeMonitor, OrderNumber, MyMetaorderNumber },
  props: ['isRight', 'clientId', 'needReload'],
  mixins: [tableSaver],
  data() {
    return {
      items: [],
      count: 0,
      sum: 0,
      isGroup: true,
      dateRange: null,
      find: '',
      isChangeBalance: null,
      filter: {
        currentPage: 1,
        pageSize: 25,
        orderBy: 'Id',
        orderIsDesc: true,
        query: []
      }
    }
  },
  watch: {
    needReload() {
      this.loadItems()
    },
    clientId: {
      handler: function() {
        this.loadItems()
      },
      immediate: true
    },
  },

  async created() {
    if (this.clientId) {
      await this.loadItems();
    }
  },

  methods: {
    async onChangesElements() {
      await this.loadItems()
    },

    async rejectCorrection(row) {
      if (await confirm('Отменить операцию?')) {
        this.$emit('reject-correction', row.id || row.records[ 0 ].id);  //В разных режимах отображения таблицы id достается из разных мест
      }
    },

    async onPagerSizeChange(newPageSize) {
      this.filter.pageSize = newPageSize;
      this.filter.currentPage = 1;
      await this.loadItems();
    },

    async onCurrentPageChange(newCurrentPage) {
      this.filter.currentPage = newCurrentPage;
      await this.loadItems();
    },

    async loadItems() {
      if (!this.clientId) {
        return;
      }
      this.filter.query = [
        { fieldName: 'clientId', op: 'eq', values: [this.clientId] },
        { isOr: false, fieldName: 'sum', op: this.isRight ? 'gt' : 'lt', values: [0] },
      ];
      this.dateRange && this.filter.query.push({ isOr: false, fieldName: 'createdAt >= $0 && createdAt <= $1', op: 'query', values: this.dateRange });
      if (this.find.trim()) {
        let addQuery = [
          { fieldName: 'order.number', op: 'like', values: [this.find.trim()] },
          { fieldName: 'metaorder.number', op: 'like', values: [this.find.trim()] },
          { fieldName: 'metaorder.user.LName', op: 'like', values: [this.find.trim()] },
        ];
        this.isRight && addQuery.push({ fieldName: 'invoice.number', op: 'like', values: [this.find.trim()] });
        this.filter.query.push({ isOr: false, op: 'part', values: addQuery });
      }
      if (this.isChangeBalance !== null) {
        let variants = [
            {recType: 'toOrderFromAccount', payType: ['fromAccount']},
            {recType: 'toOrder', payType: ['fromInvoice', 'fromSber']},
            {recType: 'toOrder', payType: ['fromAccount', 'payLater']},
          ];
        if (this.isRight) {
          const op = this.isChangeBalance ? 'neq' : 'eq';
          this.filter.query.push({ isOr: false, fieldName: 'type', op, values: ['fromInvoiceToOrder'] });
        } else {
              if (this.isChangeBalance === 3) {
                this.filter.query.push({ isOr: false, fieldName: 'type', op: 'in', values: ['correction','toClient'] });
              } else {
                let choice = variants[this.isChangeBalance];
                this.filter.query.push({ isOr: false, fieldName: 'type', op: 'eq', values: [choice.recType]});
                this.filter.query.push({ isOr: false, fieldName: 'metaorder.payType', op: 'in', values: choice.payType});
              }
        }
      }

      let response;
      try {
        response = await this.$store.dispatch('payLogs/loadItems', { filter: this.filter, isGroup: this.isGroup, isPositivSum: this.isRight });
      } catch (ex) {
        alert('Ошибка получения платежных записей клиента: ' + ex.message);
        return;
      }
      this.items = response.items;
      this.count = response.count;
      this.sum = response.sum;
    },
    async showInvoice(id) {
      await this.$showWindowAsync(invoiceEditor, { id });
    },
    async showClient(id) {
      await this.$showWindowAsync(clientEditor, { id });
    },
    async showDelivery(id) {
      await this.$showWindowAsync(deliveryEditor, { id });
    },

    async editComment(editRow) {
      let payLogRec = this.isGroup   //Если включена группировка то нам нужен первый элемент группы
          ? editRow.records[ 0 ]
          : editRow;
      let saveComment = payLogRec.comment;
      let comment = await this.$showWindowAsync(editCommentWindow, { comment: payLogRec.comment });
      if (comment === null) {
        return;
      }
      payLogRec.comment = comment;
      try {
        await this.$store.dispatch('payLogs/saveItem', payLogRec);
      } catch (ex) {
        payLogRec.comment = saveComment;
        alert('Ошибка сохранения комментария: ' + ex.message);
        return;
      }
      if (this.isGroup) {
        editRow.comment = comment;
      }
    }
  },
  computed: {
    monitoringItems() {
      return {
        paylog: {
          actions: ['insert', 'update', 'delete'],
          ids: !this.isGroup
              ? this.items.map(o => o.id)
              : this.items.reduce((acc, el) => {
                 if (el.records) {
                   acc.push(...el.records.map(r => r.id));
                 }
                return acc;
              }, []),
          withoutData: true,
          insertExist: false
        }
      }
    },

    tableSaveName() {
      return 'paylogTbl_' + this.isRight;
    }

  }

}
</script>

<style scoped>

</style>
