<template>
  <el-container style="height: 100%">
    <el-header style="height: 20px">
      <div style="font-size:20px; font-weight: bold; color: #409EFF;text-align: center;margin-top: 7px;">Заметки по заказам на сиквенс</div>
      <change-monitor name="orderTable"
                      :items="monitoringItems"
                      @change="onChangesElements"
                      ref="changeMonitor"></change-monitor>
    </el-header>
    <el-main style="height: 100%;overflow: hidden">
      <div style="display: flex;flex-direction: column; height: 100%">
        <div v-if="noteLists.length === 0"
             style="display:flex; flex-direction: column; justify-content: center;height: 100%;align-items: center;">Нет заметок :(</div>
        <el-tabs
            v-else
            style="display: flex;flex-direction: column; height: 100%;"
            :value="activeTabName"
            type="border-card"
            ref="tabs"
            closable
            @tab-remove="onTabRemove"
        >
          <el-tab-pane v-for="list in noteLists" :label="list.name" :name="`tab_${list.id}`" :key="`tab_${list.id}`" style="height: 100%">
        <span slot="label">
          <i v-if="list.notes.every(n => n.isComplete)" class="el-icon-check" style="color: green; font-weight: bold"></i>
          {{ list.name }} ({{new Date(list.createdAt).toLocaleDateString()}})
        </span>
            <div style="display: flex; flex-direction: column;height: 100%; overflow-y:auto">
              <div style="display: flex; justify-content: space-between;margin-bottom: 25px">
                <el-input
                    placeholder="Комментарий ко всему списку"
                    type="textarea"
                    :rows="1"
                    v-model="list.comment"
                    @input="onChangeListComment(list)"
                ></el-input>
                <my-el-button
                    type="info"
                    size="mini"
                    icon="el-icon-printer"
                    @click="onClickPrintPdf(list)"
                ></my-el-button>
              </div>



              <div v-for="order in getOrders(list.id)" :key="`${list_id}_${order.id}`" style="margin-bottom: 40px">
                <div style="display: flex;align-items: center">
                  <div style="white-space: nowrap;margin-right: 20px" @click="showOrderWindow(order.metaorderId)" class="my-link">{{ order.number }}</div>
                  <el-input
                      placeholder="Комментарий"
                      v-model="tempComments[`${list.id}_${order.id}`]"
                      @input="setOrderComment($event, list.id, order.id)"
                  />
                </div>
                <div style="margin-bottom: 10px;" @click="showUserWindow(order.userId)"  class="my-link">{{ order.fio }}</div>
                <el-table :data="list.notes.filter(n => n.primerForSequenceRl && n.primerForSequenceRl.sequence.order.id === order.id)"
                          style="font-size: 16px;overflow-y: hidden"
                          :border="true"
                >
                  <el-table-column prop="isComplete" width="40px" align="center">
                    <div slot="header"><i class="el-icon-check" style="font-weight: bold; color:green"/></div>
                    <div slot-scope="scope">
                      <el-checkbox v-model="scope.row.isComplete" @change="onChangeNoteComplete(scope.row)"></el-checkbox>
                    </div>
                  </el-table-column>
                  <el-table-column label="Фрагмент" prop="primerForSequenceRl.sequence.name" width="150px">
                     <div slot-scope="scope" style="display: flex;flex-direction: column">
                       <div>{{scope.row.primerForSequenceRl.sequence.name}}</div>
                       <div v-if="scope.row.primerForSequenceRl.sequence.tubeTitle" style="font-size: 11px">
                         На пробирке: {{scope.row.primerForSequenceRl.sequence.tubeTitle}}
                       </div>
                     </div>



                  </el-table-column>
                  <el-table-column label="Праймер" prop="primerForSequenceRl.primer.name" width="150px"></el-table-column>
                  <el-table-column label="Плашка" prop="primerForSequenceRl.plate.number" width="120px">
                    <div slot-scope="scope" v-if="scope.row.primerForSequenceRl.plate" class="my-link" @click="showPlate(scope.row.primerForSequenceRl)">
                      {{ scope.row.primerForSequenceRl.plate.number }}&nbsp;{{ scope.row.primerForSequenceRl.platePoint }}
                    </div>
                  </el-table-column>
                  <el-table-column label="Файл" prop="primerForSequenceRl.fileNameWoExt" width="250px">
                    <div slot-scope="scope" style="display:flex;flex-wrap: nowrap">
                      {{ scope.row.primerForSequenceRl.fileNameWoExt }}
                    </div>
                  </el-table-column>
                  <el-table-column label="Комментарий" min-width="200">
                    <template slot-scope="scope">
                      <el-input v-model="scope.row.comment" @input="onChangeNoteComment(scope.row)" placeholder="Комментарий"/>
                    </template>
                  </el-table-column>
                  <el-table-column width="55">
                    <template slot-scope="scope">
                      <div style="display: flex; flex-wrap: nowrap;justify-content: center;">
                        <my-el-button delete @click="onBtnDelete(scope.row)"/>
                      </div>
                    </template>
                  </el-table-column>

                </el-table>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-main>
  </el-container>
</template>
<script>

import ChangeMonitor from "@/components/common/changeMonitor";
import _ from 'lodash';
import sequencePlateEditor from "@/components/sequence/SequencePlateEditor";
import orderEditor from "@/components/order/OrderEditor";

export default {
  name: 'SequenceNoteList',
  components: { ChangeMonitor },
  props: ['id', 'idType', 'user', 'client', 'onClose', 'subOrderId', 'isTabView'],
  data() {
    return {
      tempComments: [],
      noteLists: [],
      activeTabName: 'fields'
    };
  },
  watch: {
    '$route.hash': function() {
      this.setActiveTab();
    }
  },
  async mounted() {
    window.name = 'seqNoteLists';
    this.noteLists = await this.$store.dispatch('sequenceNotes/loadItems', {});
    this.setActiveTab();
    this.buildTempComments();
  },

  methods: {
    async onClickPrintPdf(list) {
      const strDate = new Date(list.createdAt).toLocaleDateString().replace(/\/|\./g, '-');
      const fileName = `${list.name}_${strDate}.pdf`
      await this.$myHttp.postWithDownload('/api/SequenceNoteLists/exportSequencePdf', list.id, fileName);
    },

    setActiveTab() {
      if (this.noteLists.length > 0) {
        let hashId = this.$route.hash.trim().replace('#', '');
        this.activeTabName = hashId
            ? `tab_${hashId}`
            : `tab_${this.sortedNoteLists[0].id}`;
      }
    },

    async showOrderWindow(orderId) {
      await this.$showWindowAsync(orderEditor, { id: orderId });
    },
    async showUserWindow(userId) {
      // Какаято кривизна с циклическими зависисмостями в userEditor из за которых их не получается просто import не динамический сделать
      let userEditor = await import('@/components/usersClientsAffiliation/UserEditor');
      await this.$showWindowAsync(userEditor.default, { id: userId });
    },
    async showPlate(primerRl) {
      await this.$showWindowAsync(sequencePlateEditor, { id: primerRl.plate.id, number: primerRl.plate.number, selectedId: primerRl.id });
    },


    buildTempComments() {
      let arr = this.noteLists.reduce((acc, list) =>
                                          acc.concat(this.getOrders(list.id).map(o => [
                                            `${list.id}_${o.id}`,
                                            list.orderNotes.find(n => n.seqOrderId === o.id)?.comment || ''
                                          ])), []);
      this.tempComments = Object.fromEntries(arr)
    },

    async setOrderComment(event, listId, orderId) {
      let list = this.noteLists.find(l => l.id === listId);
      let orderNote = list.orderNotes.find(on => on.seqOrderId === orderId);
      let value = this.tempComments[ `${listId}_${orderId}` ];
      if (!value) {
        if (orderNote) {
          orderNote.comment = '';
          orderNote.forDelete = true;
        } else {
          return;
        }
      } else {
        if (!orderNote) {
          orderNote = {
            id: 0,
            seqOrderId: orderId,
            sequenceNoteListId: listId
          };
          list.orderNotes.push(orderNote);
        }
        orderNote.comment = value;
      }
      await this.onChangeOrderNoteComment(orderNote, list);
    },

    getOrders(listId) {
      let list = this.noteLists.find(l => l.id === listId);
      let result = list.notes.map(n => ({
        id: n.primerForSequenceRl?.sequence.order.id,
        metaorderId: n.primerForSequenceRl?.sequence.order.metaorderId,
        number: n.primerForSequenceRl?.sequence.order.number,
        fio: n.primerForSequenceRl?.sequence.order.metaorder.user?.fio || '',
        userId:n.primerForSequenceRl?.sequence.order.metaorder.userId
      }));
      return _.orderBy(_.uniqBy(result, 'id'), 'id', 'desc');
    },

    async onBtnDelete(note) {
      if (await this.$confirm('Удалить заметку?')) {
        note.forDelete = true;
        await this.$store.dispatch('sequenceNotes/saveSequenceNotes', [note]);
      }
    },

    async onChangeNoteComplete(note) {
      await this.$store.dispatch('sequenceNotes/saveSequenceNotes', [note]);
    },

    onChangeNoteComment: _.debounce(async function(note) {
      await this.$store.dispatch('sequenceNotes/saveSequenceNotes', [note]);
    }, 500),

    onChangeListComment: _.debounce(async function(list) {
      await this.$store.dispatch('sequenceNotes/saveItem', list);
    }, 500),

    onChangeOrderNoteComment: _.debounce(async function(note, list) {
      let response = await this.$store.dispatch('sequenceNotes/saveSequenceOrderNote', note);
      if (!note.forDelete) {
        note.id = response.id;
      } else {
        list.orderNotes = list.orderNotes.filter(on => on.id !== note.id);
      }
    }, 500),


    async onTabRemove(targetName) {
      let deleteNoteId = targetName.replace('tab_', '') * 1;
      if (!await this.$confirm('Вы уверены что хотите удалить список')) {
        return;
      }
      if (this.activeTabName === targetName && this.noteLists.length > 1) {
        this.activeTabName = `tab_${this.noteLists.find(n => n.id !== deleteNoteId).id}`;
      } else if (this.noteLists.length === 1) {
        this.activeTabName = null;
      }
      await this.$store.dispatch('sequenceNotes/deleteItem', { id: deleteNoteId });
      this.noteLists = this.noteLists.filter(n => n.id !== deleteNoteId);
    },

    onChangesElements(changes) {
      if (changes.sequenceNoteList) {
        if (changes.sequenceNoteList.insert.length > 0) {
          let newNote = changes.sequenceNoteList.insert[ 0 ];
          if (!this.noteLists.some(n => n.id === newNote.id)) {
            this.noteLists.push(newNote);
            this.buildTempComments();
          }
        }
        if (changes.sequenceNoteList.delete.length > 0) {
          this.noteLists = this.noteLists.filter(n => !changes.sequenceNoteList.delete.some(d => d === n.id));
        }
        changes.sequenceNoteList.update.forEach(updatedList => {
          let fndIndex = this.noteLists.findIndex(n => n.id === updatedList.id);
          if (fndIndex !== -1) {
            this.noteLists.splice(fndIndex, 1, updatedList);
          } else {
            this.noteLists.push(updatedList);
          }
        });
      }
      if (changes.sequenceNote) {
        if (changes.sequenceNote.insert.length > 0) {
          changes.sequenceNote.insert.forEach(note => {
            let fndList = this.noteLists.find(list => list.id === note.noteListId);
            if (fndList && !fndList.notes.some(n => n.id === note.id)) {
              fndList.notes.push(note);
            }
          });
          this.buildTempComments();
        }
        if (changes.sequenceNote.update.length > 0) {
          changes.sequenceNote.update.forEach(note => {
            let fndList = this.noteLists.find(list => list.id === note.noteListId);
            let idx = fndList.notes.findIndex(n => n.id === note.id);
            if (idx !== -1) {
              fndList.notes.splice(idx, 1, note);
            }
          });
        }
        if (changes.sequenceNote.delete.length > 0) {
          this.noteLists.forEach(list => list.notes = list.notes.filter(note => changes.sequenceNote.delete.indexOf(note.id) === -1));
        }
      }
      if (changes.sequenceOrderNote) {
        if (changes.sequenceOrderNote.insert.length > 0) {
          changes.sequenceOrderNote.insert.forEach(note => {
            let fndList = this.noteLists.find(list => list.id === note.sequenceNoteListId);
            if (fndList && !fndList.orderNotes.some(n => n.id === note.id)) {
              fndList.orderNotes.push(note);
            }
          });
        }
        if (changes.sequenceOrderNote.update.length > 0) {
          changes.sequenceOrderNote.update.forEach(note => {
            let fndList = this.noteLists.find(list => list.id === note.sequenceNoteListId);
            let idx = fndList.orderNotes.findIndex(n => n.id === note.id);
            if (idx !== -1) {
              fndList.orderNotes.splice(idx, 1, note);
            }
          });
        }
        if (changes.sequenceOrderNote.delete.length > 0) {
          this.noteLists.forEach(list => list.orderNotes = list.orderNotes.filter(note => changes.sequenceOrderNote.delete.indexOf(note.id) === -1));
        }
        this.buildTempComments();
      }
    }
  },

  computed: {
    sortedNoteLists() {
      return  _.orderBy(this.noteLists, 'id', 'desc');
    },

    monitoringItems() {
      return {
        sequenceNoteList: {
          actions: ['insert', 'update', 'delete'],
          ids: this.noteLists.map(nl => nl.id),
          insertExist: true,
          dispatch: 'sequenceNotes/loadItems'
        },
        sequenceNote: {
          actions: ['insert', 'update', 'delete'],
          ids: this.noteLists.reduce((acc, nl) => acc.concat(nl.notes.map(n => n.id)), []),
          insertExist: true,
          dispatch: 'sequenceNotes/findSequenceNotes'
        },
        sequenceOrderNote: {
          actions: ['insert', 'update', 'delete'],
          ids: this.noteLists.reduce((acc, nl) => acc.concat(nl.orderNotes.map(n => n.id)), []),
          insertExist: true,
          dispatch: 'sequenceNotes/findSequenceOrderNotes'
        }
      }
    }
  },
}
</script>
