<template>
    <el-form label-width="120px" ref="editForm" style="height:100%;overflow-y:auto;padding-right: 5px ">
        <el-form-item label="Search_name" v-if="source === 'pd'">
            <div style="display: flex; flex-wrap: nowrap">
                <el-input readonly :value="oldData.search" style="width:50%"></el-input>
                <div v-if="oldData.country" style="margin-left:15px; display: flex; flex-wrap: nowrap">
                    Country
                    <el-input readonly :value="oldData.country" style="margin-left: 5px"></el-input>
                </div>
                <div v-if="oldData.currency" style="margin-left:15px; display: flex; flex-wrap: nowrap">
                    Currency
                    <el-input readonly :value="oldData.currency" style="margin-left: 5px"></el-input>
                </div>
                <div v-if="!oldData.ship_cost" style="margin-left:15px; display: flex; flex-wrap: nowrap">
                    Ship_cost
                    <el-input readonly :value="oldData.ship_cost" style="margin-left: 5px"></el-input>
                </div>
                <div v-if="oldData.status" style="margin-left:15px; display: flex; flex-wrap: nowrap">
                    Status
                    <el-input readonly :value="oldData.status" style="margin-left: 5px"></el-input>
                </div>
            </div>
        </el-form-item>
        <el-form-item label="Фамилия" v-if="source === 'ru'">
            <div style="display: flex; flex-wrap: nowrap">
                <el-input readonly :value="oldData.last_name" style="width:30%"></el-input>
                <div style="margin-left:15px; display: flex; flex-wrap: nowrap">
                    Имя
                    <el-input readonly :value="oldData.first_name" style="margin-left: 5px"></el-input>
                </div>
                <div  style="margin-left:15px; display: flex; flex-wrap: nowrap">
                    Отчество
                    <el-input readonly :value="oldData.medium_name" style="margin-left: 5px"></el-input>
                </div>
            </div>
        </el-form-item>
        <el-form-item label="E-mail" v-if="source === 'ru'">
            <div style="display: flex; flex-wrap: nowrap">
                <el-input readonly :value="oldData.email" style="width:30%"></el-input>
                <div style="margin-left:15px; display: flex; flex-wrap: nowrap">
                    Логин
                    <el-input readonly :value="oldData.primer_login" style="margin-left: 5px"></el-input>
                </div>
                <div  style="margin-left:15px; display: flex; flex-wrap: nowrap">
                    Пароль
                    <el-input readonly :value="oldData.primer_parol" style="margin-left: 5px"></el-input>
                </div>
            </div>
        </el-form-item>
        
        <template v-for="field in fields[source]">
            <el-form-item v-if="oldData[field[1]]" :label="field[0]" :key="field[0]">
                <el-input type="textarea" readonly autosize :value="oldData[field[1]]"/>

            </el-form-item>
        </template>
    </el-form>
</template>

<script>
    export default {
        name: 'OldBaseTab',
        props: [ 'json', 'source' ],
        data() {
            return {
                fields: {
                    pd: [
                        [ 'Title', 'title_c' ],
                        [ 'Title - billing address', 'title_bill' ],
                        [ 'Contact name', 'contact_c' ],
                        [ 'Contact name - billing address', 'contact_bill' ],
                        [ 'Email name', 'email_c' ],
                        [ 'Email name - billing address', 'email_bill' ],
                        [ 'Company', 'company_c' ],
                        [ 'Company - billing address', 'company_bill' ],
                        [ 'Company info', 'info_c' ],
                        [ 'Company info - billing address', 'info_bill' ],
                        [ 'FedEx# \nTNT#\nDHL#\nUPS#', 'how' ],
                        [ 'Other contact info', 'other' ],
                        [ 'Notes', 'note' ],
                        [ 'Discount', 'procent' ],
                        [ 'Terms of payment', 'terms' ],
                        [ 'Invoice Emailing: Person name', 'd_message_recipient_name'],
                        [ 'Invoice Emailing: To', 'd_message_recipient_mail'],
                        [ 'Invoice Emailing: Copy to', 'd_message_recipient_copy'],
                    ],
                    ru: [
                        ['Note','note'],
                        ['Raw','raw'],
                        ['Postal','postal'],
                        ['Payer','payer'],
                        ['Sequence payer','sequence_payer']
                    ]
                }
            }
        },
        computed: {
            oldData() {
                return JSON.parse(this.json);
            },
        }
        
    }
</script>

<style scoped>

</style>