<template>
    <el-table
            :data="data ? data.filter(r => !r.forDelete) : []"
            style="width: 100%;font-size: 16px;flex-grow: 1"
            height="100%"
            :border="true"
            v-ls-saver:clientUserTable
    >
        <el-table-column :resizable="true" v-if="!!userId || userId === 0">
            <template slot-scope="scope">
                <el-form v-if="currentEdit.id === scope.row.id" label-width="0" :model="currentEdit" :rules="rules">
                    <el-form-item prop="client">
                        <my-selector
                                type="client"
                                v-model="currentEdit.client"
                                placeholder=""
                                buttons
                                no-edit
                                @change="currentEdit.role=null; currentEdit.clientId = currentEdit.client ? currentEdit.client.id : ''"
                                @edit="onEditClient(currentEdit.clientId, currentEdit)"
                                @add="onEditClient(0, currentEdit)"
                        />
                    </el-form-item>
                </el-form>
                <div v-else style="display: flex; justify-content: space-between">
                    <my-type-link :object="scope.row.client"
                                  @click="onEditClient(scope.row.clientId, scope.row)"
                    ></my-type-link>
                    <div>
                        <i v-if="scope.row.client.isCheck"
                           class="el-icon-circle-check"
                           style="font-weight: bold;color:green; align-self: center;margin-right: 10px;margin-left: 5px"></i>
                    </div>
                </div>
            </template>
            <template v-slot:header>
                <div style="display: flex;justify-content: space-between;align-items: center">
                    Клиент
                    <div style="display: flex;justify-content: center">
                        <my-el-button plus :disabled="!!(currentEdit.id || currentEdit.id === 0)" @click="onBtnAddRow"></my-el-button>
                    </div>
                </div>
            </template>
        </el-table-column>
        <el-table-column :resizable="true" v-if="!!clientId || clientId === 0">
            <template slot-scope="scope">
                <el-form v-if="currentEdit.id === scope.row.id" label-width="0" :model="currentEdit" :rules="rules">
                    <el-form-item prop="user">
                        <my-selector
                                type="user"
                                v-model="currentEdit.user"
                                placeholder=""
                                buttons
                                no-edit
                                @change="currentEdit.userId = currentEdit.user ? currentEdit.user.id : ''"
                                @add="onEditUser(0, currentEdit)"
                        />
                    </el-form-item>
                </el-form>
                <div v-else style="display: flex; justify-content: space-between">
                    <my-type-link :object="scope.row.user"
                                  @click="onEditUser(scope.row.userId, scope.row)"
                    ></my-type-link>
                    <div>
                        <i v-if="scope.row.user.isChecked"
                           class="el-icon-circle-check"
                           style="font-weight: bold;color:green; align-self: center;margin-right: 10px;margin-left: 5px"></i>
                    </div>
                </div>
            </template>
            <template v-slot:header>
                <div style="display: flex;justify-content: space-between">
                    Заказчик
                    <div style="display: flex;justify-content: center">
                        <my-el-button plus :disabled="!!(currentEdit.id || currentEdit.id === 0)" @click="onBtnAddRow"></my-el-button>
                    </div>
                </div>
            </template>
        </el-table-column>
        <el-table-column label="Должность" :resizable="true" width="200px">
            <template slot-scope="scope">
                <el-form v-if="currentEdit.id === scope.row.id" label-width="0" :model="currentEdit" :rules="rules" ref="ruleForm">
                    <el-form-item prop="role">
                        <el-select
                                v-model="currentEdit.role"
                                :disabled="(userId || userId === 0) && !currentEdit.client"
                                @blur="$refs.ruleForm.validate().catch(()=>{})">
                            <el-option
                                    v-for="role in $getEnum('RoleInClientEnum')"
                                    v-show="showRole(role.value)"
                                    :value="role.value"
                                    :label="role.name"
                                    :key="role.value"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                  <my-link v-else
                           @click="onClickEditRow(scope.row)"                          
                          :name ="$getEnumValue('RoleInClientEnum', scope.row.role)"
                          :disabled="!!(currentEdit.id || currentEdit.id === 0 || lockUserId === scope.row.userId)"
                           ></my-link>
            </template>
        </el-table-column>
        <el-table-column label="" width="110px">
            <div slot-scope="scope" style="display: flex;flex-wrap: nowrap;justify-content: center">
                    <my-el-button
                            v-if="currentEdit.id !== scope.row.id"
                            delete key="btn2" :disabled="!!(currentEdit.id  || currentEdit.id === 0  || lockUserId === scope.row.userId)" @click="onClickDeleteRow(scope.row)"/>
                <div v-else style="display: flex;flex-wrap: nowrap">
                    <my-el-button cancel key="btn3" @click="onBtnCancelEditRow"/>
                    <my-el-button check key="btn4" :disabled="!!checkRow()" @click="onBtnApplyEditRow(scope.row)"/>
                </div>
            </div>
        </el-table-column>
    </el-table>
</template>

<script>
    import _ from 'lodash';
    import tableSaver from '@/utils/tableSaver';
    import clientEditor from "./ClientEditor";
    
    import userEditor from './UserEditor';
    import { confirm } from '@/components/common/dialogs/dialogUtils';
    import MyTypeLink from "@/components/common/myTypeLink";
    
    
    export default {
        name: "ClientUserTable",
        components: { MyTypeLink },
        mixins: [tableSaver],
        props: ['clientId', 'userId', 'data', 'lockUserId'],
        data() {
            let validateUser = async(rule, value, callback) => {
                let message = this.checkUser();
                message
                    ? callback(new Error(message))
                    : callback();
            };
            let validateClient = async(rule, value, callback) => {
                let message = this.checkClient();
                message
                    ? callback(new Error(message))
                    : callback();
            };
            
            return {
                currentEdit: { id: null, user: null, client: null, role: null },
                rules: {
                    role: [this.$validateRuleRequired],
                    client: [this.$validateRuleRequired, { validator: validateClient }],
                    user: [this.$validateRuleRequired, { validator: validateUser }]
                }
            }
        },
        methods: {
            onClickEditRow(row) {
                this.currentEdit = _.cloneDeep(row)
                this.$emit('begin-edit')
            },
            
            async onClickDeleteRow(row) {
                if (!await confirm(`Удалить ${(this.userId || this.userId === 0) ? 'клиента' : 'заказчика'} из списка?`)) {
                    return;
                }
                
                
                if (row.id > 0) {
                    row.forDelete = true;
                } else {
                    let idx = _.findIndex(this.data, rl => rl.id === row.id);
                    this.data.splice(idx, 1);
                }
            },
            
            onBtnCancelEditRow() {
                if (this.currentEdit.id === 0) {
                    let idx = _.findIndex(this.data, el => el.id === 0);
                    this.data.splice(idx, 1);
                }
                this.currentEdit = { id: null, user: null, client: null, role: null };
                this.$emit('end-edit')
            },
            
            onBtnApplyEditRow(row) {
                let idx = _.findIndex(this.data, rl => rl.id === row.id);
                let newVal = _.cloneDeep(this.currentEdit);
                if (newVal.id === 0) {
                    let min = _.minBy(this.data, rl => rl.id);
                    newVal.id = min.id >= 0 ? -1 : (min.id--);
                }
                
                this.data.splice(idx, 1, newVal);
                this.currentEdit = { id: null, user: null, client: null, role: null };
                this.$emit('end-edit')
            },
            
            onBtnAddRow() {
                this.currentEdit = {
                    id: 0,
                    forDelete: false,
                    client: null,
                    clientId: this.clientId,
                    user: null,
                    userId: this.userId,
                    role: null
                };
                this.data.push(this.currentEdit);
                this.$emit('begin-edit')
            },
            
            async onEditUser(id, row) {
                let user = await this.$showWindowAsync(userEditor, { id });
                if (user) {
                    if (id !== 0) {
                        let rlRow = _.find(user.clientUserRls, rl => !rl.forDelete && rl.clientId === row.clientId);
                        if (rlRow) {
                            row.role = rlRow.role;
                        } else if (row.id > 0) {
                            let idx = _.findIndex(this.data, d => d.id === row.id);
                            this.data.splice(idx, 1);
                            return;
                        }
                    }
                    row.user = user;
                    row.userId = user.id;
                }
            },
            
            async onEditClient(id, row) {
                let client = await this.$showWindowAsync(clientEditor, { id });
                if (client) {
                    if (id !== 0) {
                        let rlRow = _.find(client.clientUserRls, rl => !rl.forDelete && rl.userId === row.userId);
                        if (rlRow) {
                            row.role = rlRow.role;
                        } else if (row.id > 0) {
                            let idx = _.findIndex(this.data, d => d.id === row.id);
                            this.data.splice(idx, 1);
                            return;
                        }
                    }
                    row.client = client;
                    row.clientId = client.id;
                }
            },
            
            checkRow() {
                return this.checkUser() + this.checkClient() + (!this.currentEdit.role ? '!!!' : '');
            },
            
            checkClient() {
                if (!this.currentEdit.clientId && this.currentEdit.clientId !== 0) {
                    return 'Поле должно быть заполнено.'
                }
                if (this.clientId || this.clientId === 0) {
                    return '';
                }
                return this.data.some(rl => !rl.forDelete && rl.id !== this.currentEdit.id && rl.clientId === this.currentEdit.clientId) ? 'Клиент уже есть в списке.' : '';
            },
            
            checkUser() {
                if (!this.currentEdit.userId && this.currentEdit.userId !== 0) {
                    return 'Поле должно быть заполнено.'
                }
                if (this.userId || this.userId === 0) {
                    return '';
                }
                return this.data.some(rl => !rl.forDelete && rl.id !== this.currentEdit.id && rl.userId === this.currentEdit.userId) ? 'Заказчик уже есть в списке.' : '';
            },
            showRole(role) {
                if (this.currentEdit.role === role) {
                    return false;
                }
                if (role === 'director') {
                    let recs = (this.clientId || this.clientId === 0) ? this.data : (this.currentEdit.client ? this.currentEdit.client.clientUserRls : []);
                    return recs && !recs.some(u => u.role === 'director');
                }
                return true;
            }
        },
    }
</script>

<style scoped>

</style>