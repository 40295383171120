import { render, staticRenderFns } from "./myDate.vue?vue&type=template&id=04e4c547&functional=true"
import script from "./myDate.vue?vue&type=script&lang=js"
export * from "./myDate.vue?vue&type=script&lang=js"
import style0 from "./myDate.vue?vue&type=style&index=0&id=04e4c547&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports