<template>
    <component-window
            :title="id ? `Редактирование проверки ${editCheck.number}` : 'Добавление проверки'"
            lock-name="sequenceCheck"
            :watch-object="editCheck"
            :isWatch="lockerIsWatch && !editCheck.isDone"
            :show-refresh-button="true"
            @click-refresh-button="loadCheck(editClient.id)"
            @close="onBtnCancelClick"
            ref="compWindow"
    >
        <div style="display:flex;height: 100%">
            <el-container style="height: 100%;width: 70%">
                <el-header style="height:auto;padding: 0">
                        <el-form>
                            <el-form-item label="Комментарий" style="margin-bottom: 3px">
                                <div style="display:flex;justify-content: flex-end">
                                    <el-form-item v-if="editCheck.createdAt" label="Дата создания" label-width="100px" style="margin-bottom: 0">
                                        <my-date :date="editCheck.createdAt"></my-date>
                                    </el-form-item>
                                    <el-form-item v-if="editCheck.checkDate" label="Дата Завершения" label-width="150px" style="margin-bottom: 0">
                                        <my-date :date="editCheck.checkDate"></my-date>
                                    </el-form-item>
                                    <my-el-button v-if="!editCheck.isDone" type="success" @click="onClickDone">Завершить</my-el-button>
                                </div>
                            </el-form-item>
                        </el-form>
                        <div style="display: flex;flex: 1 1;margin-bottom: 3px">
                            <el-input v-model="editCheck.comment"
                                      type="textarea"
                                      rows="3"
                                      style="display: flex"
                            />
                        </div>
                        Изображения:
                </el-header>
                <el-main style="padding: 2px 0 0 0">
                    <file-table
                            :files="editCheck.files"
                            with-delete
                            with-add
                            :file-type="['sequenceCheck']"
                            is-images

                    />
                </el-main>
            </el-container>
            
            <div style="display:flex;flex-direction: column; width: 30%; height:100%;border: solid 1px #DCDFE6;margin-left: 5px;margin-bottom: 3px">
                <div v-if="!editCheck.isDone" style="display: flex;justify-content: flex-end; margin:3px 0 3px 3px">
                  <my-el-button type="success" @click="selectOrders">Добавить заказы</my-el-button>
                </div>
                <div style="display: flex;flex: 1 0;height: 0px;overflow-y: auto;">
                    <el-tree
                            :data="tree"
                            ref="tree"
                            show-checkbox
                            node-key="id"
                            default-expand-all
                            :default-checked-keys="getKeys"
                            @check-change="checkChangeNode"
                            @check="checkNode"
                            style="width: 100%"
                            :expand-on-click-node="false">
                        <div slot-scope="{ node, data }" style="display: flex;flex-wrap: nowrap;width: 100%;justify-content: space-between;align-items: center; margin-right: 7px">
                            <div v-if="data.sequence">{{ data.sequence.name }}</div>
                            <div v-else style="display: flex; justify-content: space-between; flex-wrap: nowrap">
                            <my-order-number :order="data.order"></my-order-number>
                                <div> ({{data.order.metaorder.user ? data.order.metaorder.user.showName : 'Заказчик не выбран'}})</div>
                            </div>
                            <div style="display: flex;flex-wrap: nowrap;align-items: center">
                                <div v-if="data.rl && (node.checked || data.rl.checkId !== editCheck.id)">
                                    <el-dropdown
                                            v-if="data.rl.checkId === editCheck.id"
                                            @command="data.rl.state = $event"
                                            trigger="click">
                                        <span class="el-dropdown-link">
                                            {{$getEnumValue('CheckStateEnum', data.rl.state)}}
                                            <i class="el-icon-arrow-down el-icon--right"></i>
                                        </span>
                                        <el-dropdown-menu slot="dropdown">
                                            <el-dropdown-item command="ok">Ok</el-dropdown-item>
                                            <el-dropdown-item command="reject">Брак</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                    <div v-else>{{$getEnumValue('CheckStateEnum', data.rl.state)}}</div>
                                </div>
                                <div style="margin-left:5px; width: 60px">
                                    <el-input v-model="data.rl.data"
                                              size="mini"
                                              v-if="data.rl && node.checked"
                                    ></el-input>
                                </div>
                            </div>

                        </div>
                    </el-tree>
                </div>
            </div>
        </div>

        <template slot="footer" slot-scope="{lockerState}">
            <div style="display: flex;justify-content: space-between;width:100%">
                <div style="display: flex">
                    <my-el-button delete
                                  v-if="!!id && !editCheck.isDone"
                                  :disabled="!!errorsForDeleteCheckButton(lockerState)"
                                  :dis-popover="errorsForDeleteCheckButton(lockerState)"
                                  @click="onBtnDeleteClick"
                                  style="margin-right: 15px"></my-el-button>
                </div>
                <div style="display:flex">
                    <my-el-button @click="onBtnCancelClick" type="warning" align="right">Отменить</my-el-button>
                    <my-el-button :disabled="(!!id) && (!lockerState.canEdit || editCheck.isDone)" @click="onBtnSaveClick()" type="success">Сохранить</my-el-button>
                </div>
            </div>
        </template>
    </component-window>
</template>

<script>
    import { alert, confirm } from '@/components/common/dialogs/dialogUtils';
    import _ from 'lodash';
    import tableSaver from '@/utils/tableSaver';
    import fileTable from "../common/fileTable";
    import sequenceOrderSelector from '@/components/sequence/SequenceOrderSelector';
    
    export default {
        name: 'SequenceCheckEditor',
        components: { fileTable },
        props: [ 'id', 'onClose' ],
        mixins: [ tableSaver ],
        data() {
            return {
                selectedOrder: null,
                allSequences: [],
                editCheck: {
                    id: 0,
                    number: '',
                    sequenceCheckRls: [],
                    files: [],
                    comment: ''
                },
                lockerIsWatch: false,
                windowSize: { minHeight: 400, minWidth: 600, height: '90%', width: '50%' },
                
                rules: {
                    maxInvoiceDebt: [ this.$validateRuleRequired, this.$validateRuleNumber?.(true, false, false) ],
                    maxAccountDebt: [ this.$validateRuleRequired, this.$validateRuleNumber?.(true, false, false) ]
                },
            };
        },
        
        async mounted() {
            if (!this.id) {
                return;
            }
            await this.loadCheck(this.id);
        },
        
        methods: {
            async selectOrders() {
              let result = await this.$showWindowAsync(sequenceOrderSelector, {id: '', ignoreOrderIds: this.ordersInSequences});
              if (result.length > 0) {
                await this.addOrderToCheck(result.map(o => o.id));
              }
            },
          
          
            async loadCheck(id) {
                
                this.lockerIsWatch = false;
                try {
                    this.editCheck = await this.$store.dispatch('sequenceChecks/loadItem', id);
                } finally {
                    this.lockerIsWatch = true;
                }
                await this.addOrderToCheck(this.ordersInCheck, false);
            },
            
            async addOrderToCheck(orderIds, addNewRl = true) {
                let response = await this.$store.dispatch('orders/getSeqOrderFragments', orderIds);
                response.forEach(fr => {
                    if (!addNewRl || fr.sequenceCheckRls.some(rl => rl.checkId === this.editCheck)) {
                        return
                    }
                    let maxRl = _.maxBy(fr.sequenceCheckRls, 'id');
                    if (maxRl && maxRl.state !== 'rejectWait') {
                        return;
                    }
                    fr.sequenceCheckRls.push({
                                                 id: 0,
                                                 sequenceId: fr.id,
                                                 sequence: fr,
                                                 checkId: this.editCheck.id,
                                                 state: 'ok',
                                                 forDelete: false
                                             })
                });
                this.allSequences.push(...response);
            },
            
            async onAutocompleteOrderSearch(query, cb) {
                let response = await this.$store.dispatch('orders/findSeqOrders', JSON.stringify(query || ''));
                cb(response.items.filter(resOrd => !this.ordersInSequences.some(ordId => ordId === resOrd.id)));
            },
            
            checkChangeNode(node, checked) {
                if (node.sequence) {
                    //Без nextTick криво работает выделение заказов
                    this.$nextTick(() => {
                        let rl = node.sequence.sequenceCheckRls.find(rl => rl.checkId === this.editCheck.id);
                        if (!rl) {
                            rl = {
                                id: 0,
                                sequence: node.sequence,
                                sequenceId: node.sequence.id,
                                checkId: this.editCheck.id,
                                state: 'ok',
                                forDelete: false
                            };
                            node.sequence.sequenceCheckRls.push(rl);
                        }
                        rl.forDelete = !checked;
                    });
                }
            },
            
            async doSave() {
                this.editCheck.sequenceCheckRls = this.allSequences
                .reduce((acc, s) =>
                            [ ...acc,
                                ...s.sequenceCheckRls.filter(rl => rl.checkId === this.editCheck.id && !(!rl.id && rl.forDelete))
                            ], []);
                let dataForSend = _.cloneDeep(this.editCheck);
                dataForSend.sequenceCheckRls.forEach(rl => rl.sequence && (rl.sequence.sequenceCheckRls = null));
                this.lockerIsWatch = false;
                this.editCheck = await this.$store.dispatch('sequenceChecks/saveItem', dataForSend);
                this.lockerIsWatch = true;
            },
            
            async onBtnSaveClick() {
                await this.doSave();
                this.onClose({ result: this.editCheck });
                this.$emit('close');
            },
            
            async onClickDone() {
                if (!await confirm("После завершения редактирование будет невозможно. Продолжить?")) {
                    return;
                }
                try {
                    await this.doSave();
                    this.editCheck = await this.$store.dispatch('sequenceChecks/doCheckDone', this.editCheck);
                } catch (ex) {
                    await alert("Ошибка завершения проверки: " + ex.message);
                    return;
                }
                this.onClose({ result: this.editCheck });
                this.$emit('close');
            },
            
            onBtnCancelClick() {
                this.onClose(null);
                this.$emit('close');
            },
            
            errorsForDeleteCheckButton(locker) {
                if (!locker.canEdit) {
                    return 'Запись редактирует другой пользователь.'
                }
                return "";
            },
            prepareRlForTree(checkRls) {
                switch ( checkRls.length ) {
                    case 0:
                        return null;
                    case 1:
                        return checkRls[ 0 ];
                    default: {
                        return checkRls.find(c => c.checkId === this.editCheck.id);
                    }
                }
            },
            async onBtnDeleteClick() {
                if (!await confirm('Проверка будет удалена вместе со всеми изображениями, комментарием и элементами. Продолжить?')) {
                    return;
                }
                
                try {
                    await this.$store.dispatch('sequenceChecks/deleteItem', this.editCheck);
                } catch (ex) {
                    await alert("Ошибка удаления проверки: " + ex.message);
                    return;
                }
                this.onClose && this.onClose({ result: this.editCheck, isDelete: true });
                this.$emit('close');
            }
        },
        
        computed: {
            ordersInCheck() {
                return [ ...new Set(this.editCheck.sequenceCheckRls.map(rl => rl.sequence.orderId)) ]
            },
            ordersInSequences() {
                return [ ...new Set(this.allSequences.map(s => s.orderId)) ];
            },
            
            getKeys() {
                return this.allSequences
                .filter(s => s.sequenceCheckRls.some(rl => rl.checkId === this.editCheck.id && !rl.forDelete))
                .map(s => `seq_${s.id}`)
            },
            
            tree() {
                return Object.entries(_.groupBy(this.allSequences, s => s.order.number))
                .map(arr => (
                         {
                             id: `order_${arr[ 1 ][ 0 ].order.id}`,
                             order: arr[ 1 ][ 0 ].order,
                             label: arr[ 0 ],
                             children: arr[ 1 ].map(sequence => ({
                                 id: `seq_${sequence.id}`,
                                 sequence,
                                 rl: this.prepareRlForTree(sequence.sequenceCheckRls),
                                 disabled:
                                     sequence.sequenceCheckRls.length > 0
                                     && !sequence.sequenceCheckRls.some(rl => rl.checkId === this.editCheck.id),
                             }))
                         }
                     )
                );
            }
        }
    }
</script>

<style>
    .el-tree-node__content {
        margin-top: 2px;
        margin-bottom: 2px;
    }

</style>