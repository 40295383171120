import vue from 'vue';

export default function buildOrders(store){
    store.actions.setOrderState = async (context, params) => await vue.prototype.$myHttp.post('/api/orders/setOrderState', params);
    store.actions.setOrderCheckResult = async (context, params) => await vue.prototype.$myHttp.post('/api/orders/setOrderCheckResult', params);
    store.actions.getSeqOrderFragments = async (context, params) => await vue.prototype.$myHttp.post('/api/orders/getSeqOrderFragments', params);
    store.actions.findSeqOrders = async (context, params) => await vue.prototype.$myHttp.post('/api/orders/findSeqOrders', params);
    store.actions.loadOrdersInfo = async (context, params) => await vue.prototype.$myHttp.post('/api/orders/getOrdersInfo', params, false);
    store.actions.getPdtOrderElements = async (context, params) => await vue.prototype.$myHttp.post('/api/orders/getPdtOrderElements', params);
    store.actions.getFileControlTable = async (context, params) => await vue.prototype.$myHttp.post('/api/orders/GetFileControlTable', params);
    store.actions.getMailsForSeqResults = async (context, params) => await vue.prototype.$myHttp.post('/api/orders/GetMailsForSeqResults', params);
    return store;
}
