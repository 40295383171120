<template>
    <div style="display: flex">
        <div style="min-width:15px;white-space: nowrap">
            <el-popover v-if="showPopover" placement="top-start" trigger="hover">
                <div style="display: flex;flex-direction: column">
                    <div v-if="orderComment" style="display: flex;flex-direction: column">
                        <div style="font-size:16px; font-weight: bold; color: #409EFF ">Комментарий к подзаказу</div>
                        <pre style="margin:0;border:0;outline: 0" v-html="orderComment"></pre>
                    </div>
                    <div v-if="mngComment" style="display: flex;flex-direction: column">
                        <div style="font-size:16px; font-weight: bold; color: #409EFF ">Комментарий ко всему заказу</div>
                        <pre style="margin:0;border:0;outline: 0" v-html="mngComment"></pre>
                    </div>
                    <div v-if="usrComment" style="display: flex;flex-direction: column">
                        <div style="font-size:16px; font-weight: bold; color: #409EFF ">Комментарий клиента</div>
                        <pre style="margin:0;border:0;outline: 0" v-html="usrComment"></pre>
                    </div>
                </div>
                <i  class="el-icon-info" slot="reference" style="cursor: pointer;font-size: 12px;color:#409EFF;margin-right: 3px"></i>
            </el-popover>
            <el-popover v-if="order && order.isDeffered" placement="top-start" trigger="hover">
                <div style="display: flex;flex-direction: column">
                    <div style="font-size:16px; font-weight: bold; color: #409EFF">Отложен {{ order.defferedUntil ? ('до ' + moment(order.defferedUntil).format('YYYY-MM-DD')) : '' }}</div>
                    {{order.defferedReason}}
                </div>
                <i class="el-icon-alarm-clock" 
                   slot="reference" 
                   style="cursor: pointer;font-size: 12px;margin-right: 3px"
                   :style="{color: (order.defferedUntil && new Date(order.defferedUntil) < new Date()) ? 'red' : '#409EFF'}"
                ></i>
            </el-popover>
            <el-popover v-if="order && order.isUrgent" placement="top-start" trigger="hover">
                <div style="display: flex;flex-direction: column">
                    <div style="font-size:16px; font-weight: bold; color: #409EFF">Срочный</div>
                    {{order.urgentReason}}
                </div>
                <i class="el-icon-warning" slot="reference" style="cursor: pointer;font-size: 12px;color:#E6A23C;margin-right: 3px"></i>
            </el-popover>
        </div>
        <div :class="disabled ? 'dis-my-link':'my-link'"
             @click="showOrder" 
             @mousedown.stop.prevent
             @click.middle.prevent.stop="showOrder($event, true)" >{{number}}</div>
    </div>
</template>

<script>
    import orderEditor from '../order/OrderEditor';
    import moment from 'moment-business-days';
    export default {
        name: "myOrderNumber",
        props: ['order', 'orderInfo', 'user', 'client', 'disabled', 'manager-comment', 'user-comment', 'no-show-window'  ],
        computed: {
            mngComment() {
             let result;   
             if (this.order) {
                  result = this.order.metaorder?.managerComment
                      ? this.order.metaorder.managerComment
                      : this.managerComment;
              } 
              return result 
                     || (this.orderInfo?.metaComment 
                          ? this.orderInfo.metaComment
                          : this.managerComment);
            },
            usrComment() {
                let result;
                if (this.order) {
                    result = this.order.metaorder?.userComment
                        ? this.order.metaorder.userComment
                        : this.userComment;
                } 
                return result 
                      || (this.orderInfo?.metaUserComment
                          ? this.orderInfo.metaUserComment
                          : this.userComment);
            },
            orderComment() {
                return this.order 
                    ? this.order.comment
                    : this.orderInfo.comment;
                },
    
            showPopover() {
                return !!( this.mngComment
                    || this.usrComment
                    || this.orderComment);
            },
            metaorderId() {
                return this.order 
                   ? this.order.metaorderId
                   : this.orderInfo.metaId
            },
            number() {
                return this.order
                    ? this.order.number
                    : this.orderInfo.number
            },
            subOrderId() {
                return this.order
                  ? this.order.id
                  : this.orderInfo.id  
            },
            moment() {return moment;}
            
        },
        methods: {    
          async showOrder(e, isNewWindow) {
              if (this.disabled) {
                  return;
              }
                
              if (this.noShowWindow) {
                  this.$emit('click', {metaorderId: this.metaorderId, orderId: this.subOrderId});
                  return;
              }
              
              if (isNewWindow) {
                  window.open(`/order/${this.metaorderId}`, '_blank');
              }  else {
                  this.$emit('begin-edit', this.metaorderId);
                  this.$emit('end-edit', await this.$showWindowAsync(orderEditor, { id: this.metaorderId, user: this.user, client: this.client, subOrderId: this.subOrderId }));
              }
          }
        }
    }
</script>

<style>
</style>