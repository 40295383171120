/* ===========================================================
 * trumbowyg.cleanpaste.js v1.0
 * Font Clean paste plugin for Trumbowyg
 * http://alex-d.github.com/Trumbowyg
 * ===========================================================
 * Authors : Eric Radin
 *           Todd Graham (slackwalker)
 *
 * This plugin will perform a "cleaning" on any paste, in particular
 * it will clean pasted content of microsoft word document tags and classes.
 */
import $ from 'jquery'
(function ($) {
    'use strict';

    // clean editor
    // this will clean the inserted contents
    // it does a compare, before and after paste to determine the
    // pasted contents
    $.extend(true, $.trumbowyg, {
        plugins: {
            cleanPaste: {
                init: function (trumbowyg) {
                    trumbowyg.pasteHandlers.push(function (pasteEvent) {
                            try {
                                trumbowyg.saveRange();
                                let clipboardData = (pasteEvent.originalEvent || pasteEvent).clipboardData;
                                let pastedData = clipboardData.getData('Text');
                                let node = trumbowyg.doc.getSelection().focusNode;
                                let range = trumbowyg.doc.createRange();
                                let cleanedPaste =  pastedData.trim().replace(/(?:\r\n|\r|\n)/g, '<br>');
                                let newNode = trumbowyg.doc.createElement('span');
                                newNode.insertAdjacentHTML('afterbegin', cleanedPaste);
                                if (trumbowyg.$ed.html() === '') {
                                    // simply append if there is no content in editor
                                    trumbowyg.$ed[0].appendChild(newNode);
                                } else {
                                    // insert pasted content behind last focused node
                                    range.setStartAfter(node);
                                    range.setEndAfter(node);
                                    trumbowyg.doc.getSelection().removeAllRanges();
                                    trumbowyg.doc.getSelection().addRange(range);

                                    trumbowyg.range.insertNode(newNode);
                                }

                                // now set cursor right after pasted content
                                range = trumbowyg.doc.createRange();
                                range.setStartAfter(newNode);
                                range.setEndAfter(newNode);
                                trumbowyg.doc.getSelection().removeAllRanges();
                                trumbowyg.doc.getSelection().addRange(range);

                                // prevent defaults
                                pasteEvent.stopPropagation();
                                pasteEvent.preventDefault();

                                // save new node as focused node
                                trumbowyg.saveRange();
                                trumbowyg.syncCode();
                                trumbowyg.$c.trigger('tbwchange');
                            } catch (c) {
                                console.log(c)
                            }
                    });
                }
            }
        }
    });
})($);
