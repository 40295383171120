<template>
   <div>
    <div v-if="clientChangeState" style="display: flex;align-items: center ">
        <my-el-button check @click="applyClientChanges" style="margin-right: 10px;" />
        <div v-if="clientChangeState === 'insert'"> Это новая запись</div>
        <div v-else>Есть изменения</div>
    </div>
   </div>
</template>

<script>
    export default {
        name: 'changeButton',
        props: {changes: {default: []}, 'field' : {default: ''}},
        computed: {
            selChanges() {
              return (this.field
                ? this.changes.filter(ch => ch.fieldName.indexOf(this.field) === 0)
                : this.changes).filter(ch => !ch.forDelete);
            },
            clientChangeState() {
                if (this.selChanges.some(ch => !this.field && ch.fieldName === this.field &&  ch.objectAction === 'insert'))
                {
                    return 'insert';
                }
                if (this.selChanges.some(ch => !ch.processed &&  ch.objectAction !== 'delete')) {
                    return 'update';
                }
                return '';
            },
        },
        methods: {
            applyClientChanges() {
              this.selChanges.filter(ch => !ch.processed).forEach( ch => {
                if (ch.objectAction === 'insert') {
                    ch.forDelete = true;
                } else {
                    ch.processed = true;
                }
              })
            },
        }
    }


</script>

<style scoped>

</style>
