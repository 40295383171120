import vue from 'vue';

export default function buildOrders(store){
    store.actions.GetErpPrimersState = async (context, params) => await vue.prototype.$myHttp.post('/api/metaorders/GetErpPrimersState', params, true);
    store.actions.sendOrderToErp = async (context, orderId) => await vue.prototype.$myHttp.post('/api/metaorders/sendOrderToErp', orderId);
    store.actions.setDefferedOrUrgent = async (context, params) => await vue.prototype.$myHttp.post('/api/metaorders/setDefferedOrUrgent', params);
    store.actions.setOrderState = async (context, params) => await vue.prototype.$myHttp.post('/api/metaorders/setOrderState', params);
    store.actions.setPrimersFeedback = async (context, params) => await vue.prototype.$myHttp.post('/api/metaorders/setPrimersFeedback', params);
    
    store.actions.rollbackOrderState = async (context, params) => await vue.prototype.$myHttp.post('/api/metaorders/rollbackOrderState', params);
    
    store.actions.setDeliveredToEvrogen = async (context, params) => await vue.prototype.$myHttp.post('/api/metaorders/setDeliveredToEvrogen', params);
    store.actions.deleteOrder = async (context, params) => await vue.prototype.$myHttp.post('/api/metaorders/deleteOrder', params);
    
    store.actions.payFromAccount = async (context, params) => await vue.prototype.$myHttp.post('/api/metaorders/payFromAccount', params);
    store.actions.doSberPay = async (context, params) => await vue.prototype.$myHttp.post('/api/metaorders/doSberPay', params);
    store.actions.getSberInfo = async (context, params) => await vue.prototype.$myHttp.post('/api/metaorders/GetSberInfo', params);
    store.actions.moveMetaorders = async (context, params) => await vue.prototype.$myHttp.post('/api/metaorders/MoveMetaorders', params);
    store.actions.restoreArchiveFiles = async (context, params) => await vue.prototype.$myHttp.post('/api/metaorders/RestoreArchiveFiles', params);
    store.actions.getRawOrder = async (context, params) => await vue.prototype.$myHttp.post('/api/metaorders/getRawOrder', params);
    store.actions.getMetaordersForSendToErp = async (context, params) => await vue.prototype.$myHttp.post('/api/metaorders/getMetaordersForSendToErp', params);
    store.actions.sendOrderToErpBatch = async (context, params) => await vue.prototype.$myHttp.post('/api/metaorders/sendOrderToErpBatch', params);
    
    store.actions.setSeqOptionOperatorId = async (context, params) => await vue.prototype.$myHttp.post('/api/metaorders/setSeqOptionOperatorId', params);
    
    return store;
}
