<template>
  <component-window
      title="Генерация счета"
      @close="onBtnCancelClick"
  >
    <div style="display: flex;flex-direction: column;height: 100%">
      <div style="display:flex;flex-direction: column;margin-bottom: 10px ">
        <div style="display:flex;justify-content: space-between; align-items: center; flex-wrap: nowrap">
          <el-form :rules="rules" inline="true" :model="formRules" ref="formRules">
            Компания:
            <el-form-item prop="selectedAffiliation" style="margin-bottom: 0">
              <el-select
                  v-model="formRules.selectedAffiliation"
                  value-key="name"
                  placeholder="Выбрать"
                  style="margin:0 20px 0 10px"

              >
                <el-option
                    v-for="aff in affiliations"
                    :key="aff.id"
                    :label="aff.name"
                    :value="aff"

                ></el-option>
              </el-select>
            </el-form-item>
            Набор документов:
            <el-form-item prop="documents" style="margin-bottom: 0">
              <el-select
                  v-model="formRules.documents"
                  placeholder="Выбрать"
                  multiple
                  collapse-tags
                  style="margin:0 20px 0 10px"
              >
                <template v-for="doc in $getEnum('ReportsEnum').filter(e => e.value.indexOf('Invoice') > 0 || e.value === 'orderAct')">
                  <el-option
                      v-if="doc.value !== '!!! customs'"
                      :label="doc.name"
                      :key="doc.value"
                      :value="doc.value"/>
                </template>
              </el-select>
            </el-form-item>
            {{ getRuEn('Номер', 'Number') }}:
            <el-input v-model="reportParams.invoiceNumber" style="width:100px;margin:0 20px 0 10px"></el-input>
            {{ getRuEn('Дата', 'Date') }}:
            <el-date-picker
                v-model="reportParams.invoiceDate"
                type="date"
                style="width:150px;margin:0 20px 0 10px"
                :picker-options="{firstDayOfWeek: 1}"
                placeholder="Выбрать">
            </el-date-picker>
          </el-form>
        </div>
        <div v-if="!invoice.isPrepayment" style="display:flex;justify-content: flex-start; align-items: center; flex-wrap: nowrap;margin-top: 15px">
          <div style="margin-right: 10px">{{ getRuEn('Условия платежа, дней', 'Terms of payment, days') }}:</div>
          <div v-if="formRules.documents.some(d => d.indexOf('orderInvoiceBank') === 0 )">
            банк
            <el-input v-model="reportParams.termsOfPaymentBank" style="width:100px;margin:0 20px 0 10px"></el-input>
          </div>
          <div v-if="formRules.documents.some(d => d === 'orderInvoice')">
            дистрибьютер
            <el-input v-model="reportParams.termsOfPaymentDst" style="width:100px;margin:0 20px 0 10px"></el-input>
          </div>
        </div>
        <div style="display: flex;margin-top: 20px">
          <div style="display:flex;flex-direction: column; width: 50%;padding-right: 10px">
            <div style="display: flex;justify-content: space-between">
              {{ getRuEn('Покупатель', 'Sold to') }}:
              <div v-if="oldInfoBill">Из старой базы
                <el-checkbox style="margin-left: 5px" v-model="soldToUseOldBase" @change="changeOldSoldTo"></el-checkbox>
              </div>
            </div>
            <el-input type="textarea" autosize v-model="reportParams.soldTo"/>
          </div>
          <div style="display:flex;flex-direction: column; width: 50%;padding-left: 10px">
            <div style="display: flex;justify-content: space-between">
              {{ getRuEn('Получатель', 'Ship to') }}:
              <div v-if="oldInfoC">Из старой базы
                <el-checkbox style="margin-left: 5px" v-model="shipToUseOldBase" @change="changeOldShipTo"></el-checkbox>
              </div>
            </div>
            <el-input type="textarea" autosize v-model="reportParams.shipTo"/>
          </div>
        </div>
        <div style="display: flex;align-items: flex-start; margin-top: 20px">
          <div style="display: flex;align-items: center;">
            PO:
            <el-input v-model="reportParams.clientOrderNumber" style="width:150px;margin:0 20px 0 10px"></el-input>
            Коммантарий к PO (en):
          </div>
          <el-input type="textarea" autosize v-model="reportParams.poDescription"/>
        </div>
        <div v-if="formRules.documents.some(d => d === 'orderInvoiceBankTranslate' )" style="display: flex;align-items: center; margin-top: 20px">
          Коммантарий к PO (ru):
          <el-input type="textarea" autosize v-model="reportParams.addDescription" style="margin-left: 10px"/>
        </div>

        <div style="display: flex;align-items: center; margin-top: 20px">
          Служба доставки:
          <div style="margin:0 20px 0 10px;flex-shrink: 0;">
            
            <delivery-service-selector
                with-pickup
                v-model="selectedDeliveryService"
                @change="changeDeliveryService"
            ></delivery-service-selector>
          </div>
          <el-input v-model="reportParams.delivery" style="margin:0 20px 0 10px"></el-input>
          {{ getRuEn('Номер отправления', 'Track number') }}:
          <el-input v-model="reportParams.trackNumber" style="margin:0 20px 0 10px"></el-input>
          {{ getRuEn('Дата отправления', 'Ship date') }}:
          <div style="width:200px;margin:0 20px 0 10px">
            <el-date-picker
                v-model="reportParams.sendDate"
                type="date"
                :picker-options="{firstDayOfWeek: 1}"
                placeholder="Выбрать">
            </el-date-picker>
          </div>
        </div>
        <div style="display: flex;align-items: center; margin-top: 20px">
          {{ getRuEn('Комментарий', 'Description') }}:
          <el-input type="textarea" autosize v-model="reportParams.description" style="margin-left: 10px"/>
        </div>
      </div>
      <el-table
          :data="reportData"
          style="width: 100%;font-size: 16px"
          :border="true"
          height="100%"
          ref="dataTable"
          :cell-style="cellStyle"
          :span-method="spanMethod"
          v-ls-saver:invoiceTbl
      >
        <el-table-column width="150" label="#" :style="'vertical-align:top'">
          <template slot-scope="scope">
            <template>
              <el-input v-if="!scope.row.type" v-model="scope.row.number"></el-input>
              <div v-else-if="scope.row.type === 'note'">Total QTY:</div>
              <div v-else style="display:flex;flex-wrap: nowrap;justify-content: flex-end;align-items: center">
                <el-input style="width: 300px" v-if="scope.row.type ==='discount'" v-model="reportParams.discountLabel"></el-input>
                <el-input style="width: 300px" v-else-if="scope.row.type ==='delivery'" v-model="reportParams.deliveryLabel"></el-input>
                <template v-if="scope.row.type === 'subtotal'">Subtotal</template>
                <template v-if="scope.row.type === 'total'">TOTAL</template>
                <span style="white-space: nowrap;margin-left: 5px"> {{ reportParams.currency }}:</span>
              </div>
            </template>
          </template>
        </el-table-column>
        <el-table-column width="100" label="QTY" align="top">
          <template slot-scope="scope">
            <template>
              <el-input v-if="!scope.row.type" v-model="scope.row.count"></el-input>
              <el-input v-if="scope.row.type === 'note'" v-model="reportParams.allCount"></el-input>

            </template>
          </template>
        </el-table-column>

        <el-table-column label="Description">
          <template slot-scope="scope">
            <template>
              <template v-if="!scope.row.type">
                <el-input v-if="invoice.currency === 'rub'" v-model="scope.row.ruName"></el-input>
                <el-input v-else v-model="scope.row.enName"></el-input>
              </template>

              <el-input v-else-if="scope.row.type === 'note'" v-model="reportParams.note" type="textarea" autosize></el-input>
            </template>
          </template>
        </el-table-column>
        <el-table-column width="150" :label="`Unit Price ${reportParams.currency}`">
          <template slot-scope="scope">
            <template>
              <el-input-number :controls="false" :min="0" precision="2" v-if="!scope.row.type" v-model="scope.row.price"></el-input-number>
            </template>
          </template>
        </el-table-column>
        <el-table-column width="150" :label="`Ext.Price ${reportParams.currency}`">
          <template slot-scope="scope">
            <template>
              <el-input-number :controls="false" :min="0" precision="2" v-if="!scope.row.type" v-model="scope.row.sum"></el-input-number>
              <el-input-number :controls="false" :min="0" precision="2" v-else-if="scope.row.type === 'subtotal'" v-model="reportParams.subtotalSum"></el-input-number>
              <el-input-number :controls="false" :min="0" precision="2" v-else-if="scope.row.type === 'discount'" v-model="reportParams.discountSum"></el-input-number>
              <el-input-number :controls="false" :min="0" precision="2" v-else-if="scope.row.type === 'delivery'" v-model="reportParams.deliverySum"></el-input-number>
              <el-input-number :controls="false" :min="0" precision="2" v-else-if="scope.row.type === 'total'" v-model="reportParams.totalSum"></el-input-number>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <template slot="footer">
      <my-el-button @click="onBtnCancelClick" type="warning">Отменить</my-el-button>
      <my-el-button @click="onBtnCreateClick" type="success">Создать</my-el-button>
    </template>
  </component-window>
</template>

<script>
import tableSaver from '@/utils/tableSaver';
import { alert } from '@/components/common/dialogs/dialogUtils';
import petrovich from 'petrovich';
import DeliveryServiceSelector from "@/components/deliveries/DeliveryServiceSelector";

export default {
  name: 'InvoicePrint',
  components: { DeliveryServiceSelector },
  props: ['invoice', 'onClose'],
  mixins: [tableSaver],
  data() {
    return {
      windowSize: { minHeight: 400, minWidth: 400, height: '95%', width: '95%' },
      customsInfo: [],
      metaorder: null,
      delivery: null,
      recipientName: '',
      soldToUseOldBase: false,
      shipToUseOldBase: false,
      reportParams: {
        soldTo: '',
        shipTo: '',
        currency: 'RUB',
        invoiceNumber: '',
        invoiceDate: null,
        description: 'Preparation and purification of materials listed below:',
        addDescription: 'Выделение, очистка, функциональные тесты',
        termsOfPaymentDst: 30,
        termsOfPaymentBank: 180,
        clientOrderNumber: '',
        delivery: '',
        trackNumber: '',
        note: 'worldwide, non-exclusive, royalty-free,\nlimited license to use for non-commercial life\nscience research',
        allCount: 0,
        subtotalSum: 0,
        discountSum: 0,
        deliverySum: 0,
        totalSum: 0,
        discountLabel: 'Discount',
        deliveryLabel: 'Shipping and Handling',
        companyName: '',
        companyContact: '',
        companyPhone: '',
        companyCity: '',
        companyPostalCode: '',
        companyAddress: '',
        companyCountry: '',
        sendDate: null
      },
      reportData: [],
      formRules: {
        documents: [],
        selectedAffiliation: null,
      },
      affiliations: [],
      selectedDeliveryService: null,
      selectedCurrency: null,
      rules: {
        documents: [this.$validateRuleRequired],
        selectedAffiliation: [this.$validateRuleRequired]
      }
    }
  },

  async mounted() {
    let elements = !this.invoice.hasChildren
        ? this.invoice.elements
        : this.invoice.childs.reduce((acc, i) => [...acc, ...i.elements], []);
    let deliveryElements = elements.filter(e => e.sourceKey.startsWith('delivery'));

    this.reportData = this.buildReportData(elements);
    [this.metaorder, this.delivery, this.affiliations]
        = await Promise.all([
                              this.findMetaorder(elements),
                              this.findDelivery(deliveryElements),
                              this.loadAffiliations()
                            ]);

    this.reportParams.invoiceNumber = this.invoice.number;
    this.reportParams.invoiceDate = this.invoice.createdAt;
    this.reportParams.currency = this.invoice.currency.toUpperCase();
    if (this.reportParams.currency === 'EUR') {
      this.reportParams.currency = 'EURO'
    }


    this.reportParams.clientOrderNumber = this.metaorder?.clientOrderNumber || '';
    if (this.delivery) {
      if (this.delivery.service) {
        this.selectedDeliveryService =  this.$deliveryServiceById(this.delivery.serviceId);
        this.reportParams.delivery = `via ${this.selectedDeliveryService.name}`;
      }
      this.reportParams.sendDate = this.delivery.stateLog.find(l => l.newState === 'send')?.createdAt;
      this.reportParams.trackNumber = this.delivery.trackNumber;
      //this.recipientName = this.delivery.recipient?.showName;
      this.reportParams.deliverySum = deliveryElements.reduce((acc, row) => acc + row.sum, 0);
    }

    this.reportParams.allCount = this.reportData.filter(d => !d.type).reduce((acc, row) => acc + row.count, 0);
    this.reportParams.subtotalSum = this.reportData.filter(d => !d.type).reduce((acc, row) => acc + row.sum, 0);

    this.reportParams.discountSum = this.reportData.filter(d => !d.type).reduce((acc, row) => acc + (row.price - row.discountPrice) * row.count, 0);
    this.reportParams.totalSum = this.reportParams.subtotalSum
        - this.reportParams.discountSum
        + this.reportParams.deliverySum;
    this.formRules.selectedAffiliation = this.invoice.from;

    this.reportParams.soldTo = this.buildSoldTo;
    this.reportParams.shipTo = this.buildShipTo;
    this.reportParams.isPrepayment = this.invoice.isPrepayment;
    this.reportParams.termsOfPaymentDst = this.invoice?.client?.termsOfPaymentDst;
  },

  methods: {
    buildReportData(elements) {
      let result = elements.filter(e => !e.sourceKey.startsWith('delivery')).map(i => ({
        number: i.cat,
        count: i.count,
        ruName: i.name,
        enName: i.enName || i.name,
        price: i.price,
        discountPrice: i.discountPrice,
        sum: i.price * i.count
      }));
      result.push(...['note', 'subtotal', 'discount', 'delivery', 'total'].map(s => ({ type: s })));
      return result;
    },

    async findMetaorder(elements) {
      let moIds = elements.map(el => el.order?.metaorderId).filter(el => el);
      return moIds.length > 0
          ? await this.$store.dispatch('metaorders/loadItem', moIds[ 0 ])
          : null;
    },

    async findDelivery(elements) {
      if (elements.length === 0) {
        return null;
      }
      if (this.invoice.deliveryId) {
        return await this.$store.dispatch('deliveries/loadItem', this.invoice.deliveryId);
      }

      let dlvRls = elements
      .filter(el => el.sourceKey !== 'delivery')  //Отсекаем записи о доставке добавленные руками и не сопоставленные с заказом   
      .map(el => el.sourceKey.replace('delivery:', '') * 1);
      let filter = {
        query: [
          { fieldName: '@ordersRl.id', op: 'in', values: dlvRls },
          { isOr: false, fieldName: 'woDelivery', op: 'eq', values: [false] },
          { isOr: false, fieldName: 'type', op: 'eq', values: ['Simple'] }
        ]
      };
      let deliveries = await this.$store.dispatch('deliveries/loadItems', filter);
      return deliveries.length > 0 ? deliveries[ 0 ] : null;
    },

    async loadAffiliations() {
      let filter = {
        orderBy: 'Name',
        orderIsDesc: false,
        query: [{ fieldName: 'isEvrogen', op: 'eq', values: [true] }]
      };
      return await this.$store.dispatch('affiliations/loadItems', filter);
    },

    getRuEn(ru, en) {
      return this.invoice.currency === 'rub' ? ru : en;
    },

    changeOldSoldTo(value) {
      this.reportParams.soldTo = value
          ? this.oldInfoBill
          : this.buildSoldTo;
    },

    changeOldShipTo(value) {
      this.reportParams.shipTo = value
          ? this.oldInfoC
          : this.buildShipTo;
    },

    onBtnCancelClick() {
      this.onClose && this.onClose(null);
      this.$emit('close');
    },

    changeDeliveryService(deliveryService) {
      if (!deliveryService) {
        this.reportParams.delivery = '';
        this.reportParams.trackNumber = '';
        this.reportParams.sendDate = null;
        return;
      }
      this.reportParams.delivery = `via ${deliveryService.name}`;
    },

    async onBtnCreateClick() {
      let isValid = false;
      await this.$refs[ 'formRules' ].validate((valid) => isValid = valid);
      if (!isValid) {
        return;
      }

      let data = {
        documents: this.formRules.documents,
        objectId: null,
        invoiceId: this.invoice.id,
        reportParams: this.setEvrogenParams(this.reportParams, this.formRules.selectedAffiliation),
        reportData: this.reportData.filter(r => !r.type)
      };

      let result;
      try {
        result = await this.$store.dispatch('buildDocs', data);
      } catch (ex) {
        alert(ex.message);
        return;
      }
      this.onClose && this.onClose(result);
      this.$emit('close');
    },

    setEvrogenParams(prm, aff) {
      prm.evrogenName = aff.name;
      prm.evrogenNameEn = aff.nameEn;
      prm.evrogenAddress = aff.address;
      prm.evrogenAddressEn = aff.addressEn;

      prm.evrogenPhone = aff.phone;
      prm.evrogenFax = aff.fax;
      prm.evrogenSite = aff.site;
      prm.evrogenInn = aff.inn;
      prm.evrogenKpp = aff.kpp;
      prm.evrogenOkpo = aff.okpo;
      prm.evrogenOgrn = aff.ogrn;
      prm.evrogenBankAccount = aff.bankAccount[ this.invoice.currency ];
      prm.evrogenBankName = aff.bankName;
      prm.evrogenBankAddress = aff.bankAddress;
      prm.evrogenBankNameEn = aff.bankNameEn;
      prm.evrogenBankAddressEn = aff.bankAddressEn;
      prm.evrogenStampRu = aff.stampFileRu.length === 1 ? aff.stampFileRu[ 0 ].dataInBase64 : null;
      prm.evrogenStampEn = aff.stampFileEn.length === 1 ? aff.stampFileEn[ 0 ].dataInBase64 : null;
      prm.evrogenContact = aff.contact;
      prm.evrogenContactEn = aff.contactEn;
      prm.evrogenLegalAddress = aff.legalAddress;
      prm.evrogenLegalAddressEn = aff.legalAddressEn;


      prm.evrogenDirector =
          [
            aff.directorLName || '',
            aff.directorFName || '',
            aff.directorMName || ''
          ].filter(s => s.trim()).join(' ');
      let person = {
        gender: 'male',
        first: aff.directorFName,
        last: aff.directorLName,
        middle: aff.directorMName
      };
      person = petrovich(person, 'accusative');
      prm.evrogenDirectorAcc = [
        person.last || '',
        person.first || '',
        person.middle || ''
      ].filter(s => s.trim()).join(' ');
      prm.evrogenDirectorEn = aff.directorEn;


      prm.evrogenAccountant = aff.accountant;
      prm.evrogenAccountantEn = aff.accountantEn;

      return prm;
    },

    cellStyle({ row }) {

      return row.type ? { 'vertical-align': 'top', 'text-align': 'right' } : {};
    },

    spanMethod({ row, columnIndex }) {
      switch ( row.type ? row.type : '' ) {
        case '' :
          return [1, 1];
        case 'note':
          return columnIndex === 2 ? [1, 3] : (columnIndex > 2 ? [0, 0] : [1, 1]);
        default:
          return columnIndex === 0 ? [1, 4] : (columnIndex < 4 ? [0, 0] : [1, 1])
      }
    },

  },

  computed: {
    oldJson() {
      return this.delivery?.recipient?.oldPdUserJson || this.metaorder?.user?.oldPdUserJson;
    },

    oldInfoBill() {
      return this.oldJson ? JSON.parse(this.oldJson).info_bill : '';
    },

    oldInfoC() {
      return this.oldJson ? JSON.parse(this.oldJson).info_c : '';
    },

    buildSoldTo() {
      return `${this.invoice.to.name}\n` +
          (this.invoice.to.address ? `${this.invoice.to.address}\n` : '') +
          (this.invoice.to.phone ? `Tel: ${this.invoice.to.phone}\n` : '') +
          (this.invoice.to.fax ? `Fax: ${this.invoice.to.fax}\n` : '');
    },

    buildShipTo() {
      let user = this.delivery?.recipient || this.metaorder?.user;
      let addrInToStr = this.delivery?.address ? this.$addressToString(this.delivery.address, true) : '\n\n';
      let phones = user?.contacts?.filter(c => c.type === 'phone').map(c => c.contact.trim()).join(', ');

      return `${user?.showName || ''}\n`
          + `${this.invoice?.to?.name || ''}\n`
          + `${addrInToStr}`
          + (phones ? `\n${phones}` : '');
    },
  }
}
</script>

<style>

</style>