import _ from 'lodash';
import Vue from 'vue';

export default {
    name: "TableSaver",
    data() {
        return {
            saveElements: []
        }
    },
    computed: {
        saveElementName() {
            return `save_${this.$parent.$options.name}_${this.$options.name}`;
        }
    },
    created() {
        this.loadStorage();
        if (!this.localStorageFields) {
            return;
        }
        this.localStorageFields.forEach( fieldName => {
            let fndSaveElement = _.find(this.saveElements, el => el.key === `form_${fieldName}`);  
            if (fndSaveElement) {
                let nameParts = fieldName.split('.');
                if (nameParts.length === 1 ) {
                    this[ fieldName ] = fndSaveElement.value; 
                } else if (nameParts.length === 2) {
                    this[nameParts[0]][nameParts[1]] = fndSaveElement.value;
                } else {
                    //todo: переделать для общего случая
                }
            }
        });
        this.localStorageFields.forEach( fieldName => {
           this.$watch(fieldName, value => {
               let fndSaveElement = _.find(this.saveElements, el => el.key === `form_${fieldName}`); 
               if (fndSaveElement) {
                 fndSaveElement.value = value;  
               } else {
                   this.saveElements.push({key: `form_${fieldName}`, value})  
               }
               this.saveStorage();
           })
        });
    },
    
    directives: {
        lsSaver: {
            bind: function(el, binding, vnode) {
                let tblColumns = vnode.child.$children;
                let labelComp = Vue.extend({
                                               template:
                                                  '<el-popover v-if="show" placement="bottom-end" trigger="hover">' +
                                                  '<div>Вернуться к заводским настройкам колонок</div>' +    
                                                  '<i id="cross" slot="reference" class="el-icon-close"  @click="onClick" style="position: absolute;z-index: 100;font-size: 10px;margin: 0;"></i>' +
                                                  '</el-popover>', 
                                               data() {return {show1: true}},
                                               methods: {
                                                   onClick() {
                                                       vnode.context.clearStoreTable(binding.arg);
                                                       tblColumns.forEach( c => c.width = c.saveWidth);
                                                       vnode.child.doLayout();
                                                       this.show = false;
                                                   }
                                               },
                                               computed: {
                                                 show() {
                                                     return vnode.context.saveElements.some(el => el.key.indexOf(`${binding.arg}_`) === 0)
                                                 }     
                                               }
                                           });
                let instance = new labelComp();
                instance.$mount();
                let firstChild = vnode.child.$el.firstChild;
                vnode.child.$el.insertBefore(instance.$el, firstChild);
    
                let keyPrefix = `${binding.arg}_column_`;
                vnode.child.$on('header-dragend',
                                (oldWidth, width, column) => {
                                    let columnIdx = _.findIndex(tblColumns, el => el.columnId === column.id);
                                    let key = `${keyPrefix}${columnIdx}`;
                                    let existRec = _.find(vnode.context.saveElements, el => el.key === key);
                                    if (!existRec) {
                                        vnode.context.saveElements.push({ key, value: column.width });
                                    } else {
                                        existRec.value = column.width;
                                    }
                                    vnode.context.saveStorage();
                                },
                );
                
               vnode.child.$on('hook:mounted', () => {
                   vnode.child.$children.forEach( c =>  c.saveWidth = c.width );
               });
                               
               vnode.child.$on('hook:updated', () => {
                                    vnode.context.saveElements.forEach(el => {
                                        if (el.key.indexOf(keyPrefix) < 0) {
                                            return;
                                        }
                                        let colIdx = el.key.replace(keyPrefix, '') * 1;
                                        if (colIdx >= tblColumns.length) {
                                            return;
                                        }
                                        tblColumns[ colIdx ].width = el.value;
                                    });
                                });
            },
        }
    },
    
    methods: {
        saveStorage() {
            localStorage[ this.saveElementName ] = JSON.stringify(this.saveElements);
        },
        
        loadStorage() {
            this.saveElements = localStorage[ this.saveElementName ] ? JSON.parse(localStorage[ this.saveElementName ]) : [];
        },
        
        clearStoreTable(name) {
            this.saveElements = this.saveElements.filter(el => el.key.indexOf(`${name}_`) < 0 );
            this.saveStorage();
        }
    }
}
