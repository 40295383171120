<template>
  <div style="display: flex;width: 100%;margin-bottom: 10px">
    <el-card style="display: flex; flex-direction: column; width: 50%;margin-right: 5px;align-items: center">
      <Highcharts :options="yearChartOptions"/>
      <el-table :data="years"
                style="font-size: 16px;width: 360px;margin: auto"
                :border="true"
      >
        <el-table-column width="60" label="Год">
          <template slot-scope="scope">
            {{ scope.row.year }}
          </template>
        </el-table-column>
        <el-table-column :label="`Сумма продаж ${$getEnumValue('CurrencyEnum', this.yearCurrency)}`" align="right">
          <template slot-scope="scope">
            {{ scope.row.sum[ yearCurrency ].toLocaleString() || 0 }}
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-card style="display: flex; flex-direction: column; width: 50%;margin-left: 5px;align-items: center">
      <div style="width: 500px;margin: auto">
        <Highcharts :options="quarterChartOptions" ref="allByQuarter"/>
      </div>
      <el-table :data="quarters"
                style="width: 700px;font-size: 16px"
                :border="true"
                :cell-style="allByQuarterCellStyle"
      >
        <el-table-column width="40">
        </el-table-column>
        <el-table-column width="60" label="Год">
          <div slot-scope="scope">
            {{ scope.row.name }}
          </div>
        </el-table-column>
        <el-table-column v-for="quarter in 4" :label="`${quarter} квартал`" :key="quarter" align="right">
          <template slot-scope="scope">
            {{ scope.row.data[ quarter - 1 ][ quarterCurrency ].toLocaleString() }}
          </template>
        </el-table-column>
        <el-table-column align="right">
          <div slot="header" style="font-weight: bold; color: black">Итого</div>
          <div slot-scope="scope" style="font-weight: bold">
            {{ scope.row.data.reduce((acc, i) => acc + i[ quarterCurrency ], 0).toLocaleString() }}
          </div>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import { genComponent } from 'vue-highcharts';
import loadExporting from 'highcharts/modules/exporting'
import loadOfflineExporting from 'highcharts/modules/offline-exporting'

loadExporting(Highcharts);
loadOfflineExporting(Highcharts);

export default {
  name: 'YearAndQuarterCharts',
  components: {
    Highcharts: genComponent('Highcharts', Highcharts)
  },
  props: {
    title: { default: '' },
    filter: { default: null },
    country: { default: '' },
    productType: { default: '' },
    multiCurrency: { default: true }
  },
  data() {
    return {
      years: [],
      quarters: [],
      yearCurrency: 'rub',
      quarterCurrency: 'rub',
    }
  },

  watch: {
    filter: {
      handler: function() {
        this.load()
      },
      deep: true
    }
  },

  async mounted() {
    await this.load();
  },

  methods: {
    async load() {
      let prepFilter = Object.assign(
          {
            country: this.country,
          }, this.filter);
      if (['primer', 'seq', 'srv', 'ngs', 'order'].includes(this.productType)) {
        prepFilter.reportType = this.productType;
      } else {
        prepFilter.reportType = 'pdt';
        if (this.productType === 'medical' ) {
          prepFilter.isMedical = true;
        } else if (this.productType === 'sci') {
          prepFilter.isMedical = false;
        }
      }

      [this.years, this.quarters] = await Promise.all([
                                                        this.$store.dispatch('stateReports/getYearGroupReport', prepFilter),
                                                        this.$store.dispatch('stateReports/getQuarterGroupReport', prepFilter)
                                                      ]);
    },

    buttons(currencyFieldName) {

      let curButtons = !this.multiCurrency
          ? []
          : this.$getEnum('CurrencyEnum').map(en => ({
            text: en.name,
            onclick: () => {
              this[ currencyFieldName ] = en.value;
            }
          }));
      return {
        buttons: {
          contextButton: {
            menuItems: [
              ...curButtons,
              { separator: true },
              {
                text: 'Печать',
                onclick: function() {
                  this.print();
                }
              },
              {
                text: 'Скачать',
                onclick: function() {
                  this.exportChart({ type: 'image/jpeg' });
                }
              }]
          }
        }
      }
    },

    allByQuarterCellStyle({ row, columnIndex }) {
      return columnIndex !== 0
          ? null
          : { backgroundColor: this.getQuarterColor(row.name) };
    },

    getQuarterColor(name) {
      return this.$refs.allByQuarter.chart.series.find(s => s.name === name).color;
    }
  },

  computed: {
    yearChartOptions() {
      return {
        exporting: this.buttons('yearCurrency'),
        chart: {
          type: 'column',
          width: 500
        },
        title: {
          text: this.title + (this.multiCurrency ? `, пересчитанные в ${this.$getEnumValue('CurrencyEnum', this.yearCurrency)}` : '') + ', по годам'
        },
        xAxis: {
          categories: this.years?.map(y => y.year),
          gridLineWidth: 1,
        },
        yAxis: {
          min: 0,
          title: {
            text: this.$getEnumValue('CurrencyEnum', this.yearCurrency)
          }
        },
        tooltip: {
          enabled: false,
        },

        plotOptions: {

          column: {
            pointPadding: 0.2,
            borderWidth: 0,
          },
          series: {
            dataLabels: {
              enabled: true,
              useHTML: true,
              inside: false
            }
          },
        },
        legend: {
          enabled: false
        },
        series: [
          {
            data: this.years?.map(y => {
              return y.sum[ this.yearCurrency ]
            }) || []
          },
        ]
      }
    },
    quarterChartOptions() {
      return {
        exporting: this.buttons('quarterCurrency'),
        chart: {
          type: 'column',
          width: 500
        },
        title: {
          text: this.title + (this.multiCurrency ? `, пересчитанные в ${this.$getEnumValue('CurrencyEnum', this.quarterCurrency)}` : '') + ', по кварталам'
        },
        xAxis: {
          categories: [...Array(4).keys()].map(a => `${a + 1} квартал`),
          gridLineWidth: 1
        },
        yAxis: {
          min: 0,
          title: {
            text: this.$getEnumValue('CurrencyEnum', this.quarterCurrency)
          }
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0
          }
        },
        legend: {
          enabled: false
        },
        series: this.quarters?.map(row => ({
          name: row.name,
          data: row.data.map(qr => {
            return qr[ this.quarterCurrency ]
          })
        }))
      }
    },
  }
}
</script>

<style scoped>

</style>
