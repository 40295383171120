<template>
    <div style="margin-bottom: 5px">
        <el-select :placeholder="'5\` мод'"
                   style="width:100px;margin-right: 5px" 
                   value="" 
                   @change="item=> onClick(item, 'left')">
            <el-option
                    v-for="item in $primerHelper.getSortedModsForPosition( 'left' )"
                    :key="item.name"
                    :label="item.name"
                    :value="item">
                <span :draggable='!inHeader' 
                      @dragstart='onDragStart($event, item.id)'>{{item.name}}</span>
            </el-option>
        </el-select >
        <el-select v-if="! inHeader"
                   @change="item=> onClick(item, 'in')"
                   placeholder="Внутренний" 
                   style="width:120px;margin-right: 5px" 
                   value="">
            <el-option
                    v-for="item in $primerHelper.getSortedModsForPosition( 'in' )"
                    :key="item.name"
                    :label="item.name"
                    :value="item">
                <span draggable="true" 
                      @dragstart='onDragStart($event, item.id)'>{{item.name}}</span>
            </el-option>
        </el-select>
        <el-select :placeholder="'3` мод'"
                   @change="item=> onClick(item, 'right')"
                   style="width:100px;margin-right: 5px" value="">
            <el-option
                    v-for="item in $primerHelper.getSortedModsForPosition( 'right' )"
                    :key="item.name"
                    :label="item.name"
                    :value="item">
                <span draggable='true' 
                      @dragstart='onDragStart($event, item.id)'>{{item.name}}</span>
            </el-option>
        </el-select>
    </div>
</template>

<script>
    
    export default {
        name: 'modifiers-buttons',
        props: [ 'in-header' ],
        data: function() {
            return { activeDiv: '' };
        },
        methods: {
            onClick: function(item, position) {
                if (this.inHeader) {
                    this.$emit('select-group-modifier', { modifier: item, position: position });
                } else {
                    this.$emit('select-modifier', { modifier: item, position: position });
                }
            },
            onDragStart: function(args, modId) {
                if (this.inHeader) {
                    return false;
                }
                args.dataTransfer.effectAllowed = 'copy';
                args.dataTransfer.setData('Text', modId.toString());
                return true;
            }
        }
    }
</script>

<style>
</style>
