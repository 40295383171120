<template>
  <component-window
      :title="title"
      @close="onBtnCancelClick">

    <div style="display: flex;flex-direction: column;height: 100%">
      <el-date-picker
          v-model="editDate"
          type="date"
          placeholder="Выбрать"
          style="margin-right: 25px"
          :picker-options="{firstDayOfWeek: 1}"
      >
      </el-date-picker>
    </div>
    <template slot="footer">
      <my-el-button @click="onBtnCancelClick" type="warning">Отменить</my-el-button>
      <my-el-button :disabled="false" @click="onBtnSaveClick" type="success">Применить</my-el-button>
    </template>
  </component-window>
</template>
<script>

export default {
  name: 'DateSelectorWindow',
  props: ['title', 'date', 'onClose'],
  data() {
    return {
      editDate: null
    };
  },

  async mounted() {
    this.editDate = this.date;
  },

  methods: {
    onBtnCancelClick() {
      this.onClose({cancel: true});
      this.$emit('close');
    },

    async onBtnSaveClick() {
      this.onClose({ cancel:false, date: this.editDate });
      this.$emit('close');
    },
  },
}
</script>

