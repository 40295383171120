<template>
  <component-window
      :title="`Контроль файлов заказа ${editOrder.number}`"
      :maximize="true"
      @close="onBtnCancelClick"
  >

    <div style="display: flex;flex-direction: column;height: 100%">
      <div>
        <div v-if="elementsView.some(el => el.notEqual)" style="color:red;font-weight: bold;height: 40px;display: flex;align-items: center;">
          Есть файлы размер или контрольная сумма которых несовпадает в разных папках!
        </div>
        <div v-else-if="elementsView.length > 0" style="color:#409EFF;font-weight: bold;height: 40px;display: flex;align-items: center;">
          Файлы (если есть) совпадают во всем заказе!
        </div>

      </div>
      <el-table :data="elementsView"
                style="width: 100%;font-size: 16px"
                :border="true"
                height="100%"
                width="100%"
                ref="elementTable"
                :row-class-name="calcRowClassName"
                :span-method="tableSpanMethod"
                v-ls-saver:SeqFilesControlTbl
      >
        <el-table-column label="Исходные файлы">
          <template slot-scope="scope">
            <div v-if="scope.row.notEqual === null" style="display: flex;justify-content: flex-start">
              <div style="width:50px">N <span style="font-weight: bold">{{ scope.row.prior }}</span></div>
              <div >Образец <span style="font-weight: bold">{{ scope.row.sequence }}</span></div>
              <div style="margin-left: 30px">Праймер <span style="font-weight: bold">{{ scope.row.primer }}</span></div>
              <div style="margin-left: 30px">Плашка  <span style="font-weight: bold">{{ scope.row.plate }}</span></div>
            </div> 
            <one-file-element v-else :file="scope.row.raw" folder="raw"  :not-equal="scope.row.notEqual" :rl-id="scope.row.rlId"></one-file-element>
          </template>
        </el-table-column>
        <el-table-column label="Расфасованные файлы">
          <template slot-scope="scope">
            <one-file-element :file="scope.row.pack" folder="pack" :not-equal="scope.row.notEqual" :rl-id="scope.row.rlId"></one-file-element>
          </template>
        </el-table-column>
        <el-table-column label="Файлы в заказе">
          <template slot-scope="scope">
            <one-file-element :file="scope.row.order" folder="order" :not-equal="scope.row.notEqual" :rl-id="scope.row.rlId"></one-file-element>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <template slot="footer">
      <my-el-button @click="onBtnCancelClick" type="success">Закрыть</my-el-button>
    </template>
  </component-window>
</template>

<script>
import tableSaver from "@/utils/tableSaver";
import Vue from 'vue';

Vue.component('one-file-element', {
  name: 'oneFileElement',
  props: ['file', 'notEqual', 'rlId', 'folder'],
  template: `
    <div v-if="notEqual !== null && file.fileName" style="display: flex;flex-direction: column">

    <div style="display: flex;justify-content: space-between">
      <div style="display: flex">
        <div class="my-link"
             @click="getSequenceFile(rlId, file.fileName, folder )"
             v-html="file.fileName"/>
             <i class="el-icon-copy-document" @click="copyPath" style="margin-left: 5px"></i>
      </div>
      <div>
        байт: {{ file.length }}
      </div>
    </div>
    <div style="display: flex;justify-content: stretch">
      <div style="display: flex;flex-grow: 1">{{ file.updateDate }}</div>
      <div style="display: flex;flex-grow: 1;justify-content: flex-end">crc:   {{ file.crc16 }}</div>
    </div>
    </div>`,
  methods: {
    async getSequenceFile(rlId, fileName, folder) {
      await this.$myHttp.postWithDownload('/api/files/GetSequenceFile',
                                    {
                                      relationId: rlId,
                                      folder,
                                      fileName: encodeURIComponent(fileName)
                                    },
                                    encodeURIComponent(fileName)
      )
    },
    async copyPath() {
      await navigator.clipboard.writeText(this.file.path);
    } 
  }
});

export default {
  name: 'SeqFilesView',
  props: ['editOrder', 'onClose'],
  mixins: [tableSaver],
  data() {
    return {
      elements: [],
      windowSize: { minHeight: 150, minWidth: 400, height: '150px', width: '500' },
    };
  },

  async mounted() {
    await this.loadElements();
  },


  methods: {
    async loadElements() {
      this.elements = await this.$store.dispatch('orders/getFileControlTable', this.editOrder.id);
    },

    onBtnCancelClick() {
      this.$emit('close');
      this.onClose && this.onClose(null);
    },
    calcRowClassName({ row }) {
      if (row.notEqual) {
        return 'err-row';
      }
      return (row.idx % 2 === 0) ? 'row-even' : 'row-odd';
    },
    tableSpanMethod({ row, columnIndex }) {
      if (row.notEqual === null) {
        if (columnIndex === 0) {
          return [1, 5];
        }
        return [0, 0];
      }
    },
  },

  computed: {
    elementsView() {
      return this.elements.filter(el => el.files.length > 0).reduce((acc, element, idx) => {
        acc.push({
                   idx,
                   notEqual: null,
                   prior: element.rl.prior,
                   sequence: element.rl.sequence.name,
                   primer: element.rl.primer?.name,
                   plate: element.rl.plate.number    
                 });
        element.files.forEach(file => acc.push({
                                                 idx,
                                                 rlId: element.rl.id,
                                                 notEqual: file.notEqual,
                                                 raw: file.raw || {},
                                                 pack: file.pack || {},
                                                 order: file.order || {}
                                               }));
        return acc;
      }, [])
    }
  }
}
</script>

<style>
.err-row {
  background-color: #FAB6B6 !important;
}

.even-row {
  background-color: #FDF5E6 !important;
}

.odd-row {
  background: #f0f9eb;
}
</style>