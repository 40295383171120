import _ from 'lodash';
import { seqOrderSummary } from "@/utils/orders";

export let seqData = [
    {
        key: 'sequence',
        name: 'Секвенирование'
    },
    {
        key: 'fragmentAnalyze',
        name: 'Фрагментный анализ',
    },
    {
        key: 'dnaExtract',
        name: 'Выделение геномной ДНК'
    },
    {
        key: 'pcr',
        name: 'ПЦР'
    }
];

export let syntData = [
    { key: 'char', name: 'Олигонуклеотиды', selectFn: l => ![ 'i', 'u' ].includes(l.toLowerCase()) },
    { key: 'inosine', name: 'Инозин', selectFn: l => l.toLowerCase() === 'i' },
    { key: 'deoxyuridine', name: 'Дезоксиуридин', selectFn: l => l.toLowerCase() === 'u'},
    { key: 'cleaning', name: 'Очистка', field: 'optionsCleaning' },
    { key: 'keen', name: 'Кинирование', field: 'optionsKeen' },
    { key: 'residementation', name: 'Переосаждение', field: 'optionsResidiment' }
];

export async function createInvoiceElements(metaorder, invoiceId, vue) {
    let workers = {
        sst: buildPrimerData,
        mst: buildPrimerData,
        pdt: buildProductData,
        seq: buildSeqData,
        srv: buildServData,
        ngs: buildNgsData
    };
    let elements = metaorder.orders
    .reduce((acc, o) => acc.concat(workers[ o.type ](o, vue)), [])
    .map(row =>({
      ...row,
      invoiceId
    }));
    if (metaorder.deliverySum) {
        let dlvRls = await vue.$store.dispatch('deliveries/findDeliveriesPartsForMetaorder', metaorder.orders.map(o => o.id));
        elements.push(...dlvRls.map( rl => ({
            order: metaorder.orders.find(o => o.id === rl.orderId),
            orderId: rl.orderId,
            ...createDeliveryRow(rl.price, rl.id)
        }) ));
    }
    
    return elements;
}

function buildPrimerData(order, vue) {
    let result = calcLettersAndOptions(order, vue);
    result = Object.assign(result, calcModifiersAndZonds(order, vue));
    result = Object.values(result).filter(row => row.count > 0);
    result.forEach(row => row.sum = row.discountPrice * row.count);
    return result.map(row => ({...row, order, orderId: order.id}))
}

function calcLettersAndOptions(order, vue) {
    return order.elements
    .filter(pr => [ 'notInGroup1', 'notInGroup2' ].includes(pr.priceCalcStrategy))
    .reduce((acc, pr) => {
        syntData.reduce((acc, synt) => {
            let key = `${synt.key}:${pr.scale}`;
            if (acc[ key ]) {
                acc[ key ].count += synt.selectFn
                    ? [ ...pr.sequence ].filter(synt.selectFn).length
                    : (pr[ synt.field ] && pr.priceCalcStrategy === 'notInGroup2' ? 1 : 0); //Опции учитываются только для notInGroup2
            } else {
                acc[key] = createSyntRow(
                    synt.key,
                    pr.scale,
                    synt.selectFn
                         ? [ ...pr.sequence ].filter(synt.selectFn).length
                         : (pr[ synt.field ] && pr.priceCalcStrategy === 'notInGroup2' ? 1 : 0),  //Опции учитываются только для notInGroup2
                    vue,
                    null,
                    !synt.field   // скидка работает только для букв (у них нет поля field)
                    ? order.discountPercent
                    : 0 )
            }
            return acc;
        }, acc);
        return acc;
    },{})
}

function calcModifiersAndZonds(order, vue) {
    if (order.type !== 'mst') return {};
    
    let result = order.elements
    .filter(pr => [ 'notInGroup1', 'notInGroup2' ].includes(pr.priceCalcStrategy))
    .reduce((acc, pr) => {
                return pr.primerModifierRls.reduce((acc, rl) => {
                    let position = getModifierPosition(rl.position, pr.sequence.length + pr.primerModifierRls.length);
                    let key = `modifier:${rl.modifierFk}-${position}:${pr.scale}`;
                    if (acc[ key ]) {
                        acc[ key ].count++;
                    } else {
                        acc[ key ] = createModifierRow(rl.modifierFk, pr.scale, position, 1, vue);
                    }
                    return acc;
                }, acc)
            }
        , {});
    
    result = order.elements
    .filter(pr => [ 'notInGroup3', 'inGroup3' ].includes(pr.priceCalcStrategy))
    .reduce((acc, pr) => {
        let modsIdsArr = _.sortBy(pr.primerModifierRls, p => p.position).map(p => p.modifierFk);
        let key = `zond:${modsIdsArr.join('-')}:${pr.scale}`;
        if (acc[ key ]) {
            acc[ key ].count++;
        } else {
            acc[key] = createZondRow(modsIdsArr, pr.scale, 1, vue, pr.price);
        }
        return acc;
    }, result);
    return result;
}

function getModifierPosition(modPosition, primerLength) {
    return modPosition === 0
        ? '5`'
        : (modPosition === primerLength - 1 ? '3`' : 'in');
}

export function buildProductData(source, vue, forStockInvoice = false) {
    let elements = forStockInvoice
          ? source
          : source.elements;
    let order = forStockInvoice ? null : source;
    return elements.map(row =>({
                                  order: order || row.order,
                                  orderId: (order || row.order).id,
                                  countAll: forStockInvoice ? row.countAll : undefined,        // Эти три поля нужны только при создании стокового инвойса
                                  countStock: forStockInvoice ? row.countStock : undefined,    // в базу они не попадают
                                  saveCountAll: forStockInvoice ? row.countAll : undefined,    //
                                  orderProductId: row.id,
                                  ...createProductRow(row.product,
                                                   forStockInvoice ? 0 : row.countAll,
                                                   selectPrice(order  || row.order, row, forStockInvoice),
                                                   selectDiscountPrice(order || row.order, row, forStockInvoice),
                                                   selectDiscountPrice(order || row.order, row, forStockInvoice) * row.countAll
                                  )}));
}

export function buildNgsData(order) {
    return order.elements.map(row =>({
        order: order || row.order,
        orderId: (order || row.order).id,
        orderNgsCatalogId: row.id,
        sourceKey : `ngs:${row.cat}`,
        cat : row.ngsCatalog.cat,
        name : row.ngsCatalog.name,
        enName: '',
        count : row.count,
        price : row.handPrice === null || row.handPrice === undefined ? row.price : row.handPrice,
        discountPrice : row.handPrice === null || row.handPrice === undefined ? row.price : row.handPrice,
        sum: row.count * (row.handPrice === null || row.handPrice === undefined ? row.price : row.handPrice)
    }));
}

let calcDsc = function(val, order) {
    return val - Math.round(val / 100 * order.discountPercent* 100) / 100
};
let calcFpPrice = function(row, price, discountPrice) {
    let sum =  price * (row.countAll - row.countDiscount) + discountPrice * row.countDiscount;
    return Math.round( sum / row.countAll *100) / 100;   //т.к в инвойсе нужна цена за еденицу
};

function selectPrice(order, row, forStock){
    let price = (forStock && !row.price)
        ? priceInCurrrency(row.product, order.metaorder.currency)
        : row.price;
    return calcFpPrice(row, price, price/2);
}

function selectDiscountPrice(order, row, forStock) {
    return row.handPrice || row.handPrice === 0
        ? row.handPrice
        : calcDsc(selectPrice(order, row, forStock), order);
}

function priceInCurrrency(product, currency) {
    switch (currency) {
        case 'usd' : return product.priceUsd;
        case 'eur' : return product.priceEuro;
        default: return product.price;
    }
}



export function buildServData(order) {
    return [{
        order,
        orderId: order.id,
        countAll: undefined,        // Эти два поля нужны только при создании стокового инвойса
        countStock: undefined,    // в базу они не попадают
        ...createInvoiceRow('serv', 'SRV', `Сервисный заказ ${order.number}`, '', order.sum, order.sum, 1)
     }];
}

function buildSeqData(order, vue) {
    return seqOrderSummary(order)
        .filter(el => el.sum )
        .map(el => createSequenceRow(el.key,
                                     el.count,
                                     vue,
                                     el.price,
                                     order.useHandPrice && order.handPrice === 0 ? 0 : el.discountPrice))
        .map(row => ({...row, order, orderId: order.id}));
    
}

export function createZondRow(modsIdsArr, scale, count, vue, price = null) {
    let zondInfo = findZondCatAndPriceNumber(modsIdsArr, scale, vue);
    return createInvoiceRow(
        `zond:${modsIdsArr.join('-')}:${scale}`,
        zondInfo.cat,
        `Зонд ${modsIdsArr.map(mr => vue.$primerHelper.getModifiers().find(m => m.id === mr).name).join(' ')} ${scale}`,
        '',
        price !== null ? price : zondInfo.price,
        price !== null ? price : zondInfo.price,
        count
    );
}

export function createModifierRow(modifierId, scale, position, count, vue) {
    let modInfo = findModifierCatNumber(scale, modifierId, position, vue);
    return createInvoiceRow(
        `modifier:${modifierId}-${position}:${scale}`,
        modInfo.cat,
        `Модификатор ${modInfo.name}`,
        '',
        modInfo.price,
        modInfo.price,
        count
    );
}

function findZondCatAndPriceNumber(sortedModifierIds, scale, vue) {
    let fndKey = sortedModifierIds.join(':');
    let zond = vue.$store.state.zonds.items.find(z => z.modifiers.map(m => m.id).join(':') === fndKey);
    if (!zond) {
        return '';
    }
    let scaleName = 's' + scale.toString().replace(/[.,]/, '');
    return {cat: zond.catNumbersArr[ scaleName ], price: zond.price || 99999};
}

function findModifierCatNumber(scale, modifierId, posName, vue) {
    let modifier = vue.$store.state.modifiers.items.find(m => m.id === modifierId);
    let priceRate = {0.02: 0.5, 0.04: 1, 0.2: 2, 1: 4 }[ scale ];
    let priceFieldName = { '5`': 'priceInLeft', 'in': 'priceInProbe', '3`': 'priceInRight' }[ posName ];
    return {
        cat: modifier[ getScaleArrFldName(posName) ][ getScaleFldName(scale) ],
        name: `${modifier.name} ${posName} ${scale}`,
        price: modifier[ priceFieldName ] * priceRate
    };
}

function getScaleFldName(scale) {
    return 's' + scale.toString().replace(/[.,]/, '')
}

function getScaleArrFldName(position) {
    let prepPosName = (position.charAt(0).toUpperCase() + position.slice(1)).replace('`', '');
    return `catNumber${prepPosName}Arr`;
}

export function createSyntRow(key, scale, count, vue, price = null,  discountPercent = 0) {
    let priceRow = vue.$store.state.prices.primerPrices.find(r => r.scale === scale);
    price = price || priceRow[key];
    return createInvoiceRow(
        `${key}:${scale}`,
        priceRow[ key + 'CatNumber' ],
        syntData.find(d => d.key === key).name,
        '',
        price,
        price - Math.round((price / 100 * discountPercent) * 100) / 100,
        count
    );
}

export function createSequenceRow(key, count, vue, price = null, discountPrice = null) {
    let seq = seqData.find(s => s.key === key);
    let name = seq
        ? seq.name
        : vue.$getEnumValue('FragmentOptionsEnum', key);
    let prmPrice = price === null ? vue.$store.state.prices.sequencePrices[ `${key}` ] : price;
    return createInvoiceRow(
        `seq:${key}`,
        vue.$store.state.prices.sequencePrices[ `${key}Cat` ],
        name,
        '',
        prmPrice,
        discountPrice === null ? prmPrice : discountPrice,
        count
    );
}

export function createProductRow(product, count, price, discountPrice, sum) {
    return createInvoiceRow(
        `product:${product.cat}`,
        product.cat,
        product.name,
        product.enName,
        price,
        discountPrice,
        count,
        sum
    );
}

export function createDeliveryRow(price, deliveryRlId = null) {
    return createInvoiceRow(
        `delivery${deliveryRlId ? `:${deliveryRlId}` : ''}` ,
        'deliveryCatNumber',
        'Оплата доставки',
        'payment for delivery services',
         price,
         price,
         1,
         price
    );
}

export function createInvoiceRow(sourceKey, cat, name, enName, price, discountPrice, count, sum = null) {
    return {
        sourceKey,
        cat,
        name,
        enName,
        count,
        price,
        discountPrice,
        sum: sum === null ? count * discountPrice : sum
    };
}






