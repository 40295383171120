<template>
  <component-window
      :title="id ? 'Редактирование адреса' : 'Добавление адреса'"
      lock-name="address"
      :watch-object="editAddress"
      :isWatch="isWatch"
      :show-refresh-button="true"
      @click-refresh-button="loadAddress(editAddress.id)"
      @close="onBtnCancelClick"
  >
    <el-tag v-if="editAddress.deleled" type="danger">Этот адрес удален. Он виден только в старых заказах</el-tag>
    <el-tabs value="fields" style="height:650px">
      <el-tab-pane label="Поля" name="fields">
        <el-form label-width="130px" style="width:100%" :model="editAddress" ref="addressForm">
          <el-form-item
              v-for="(field, index) in fields"
              :key="'formItem_' + index"
              :label="field.title"
              :prop="field.name"
              :rules="!field.isRequired ? [] : [ $validateRuleRequired]">
            <div style="display:flex;flex-wrap: nowrap">
              <my-el-select
                  v-if="field.name === 'country'"
                  v-model="editAddress.country"
                  value-key="name"
                  :value="editAddress.country"
                  @change="editAddress.countryId = editAddress.country.id"
              >
                <el-option
                    v-for="country in $getEnum('countries')"
                    :key="country.name"
                    :label="country.name"
                    :value="country">
                </el-option>
              </my-el-select>
              <template v-else>
                <el-input v-model="editAddress[field.name]"/>
                <change-field @apply-change="applyHistoryChanges" :fieldName="`Addresses:(${editAddress.id})${field.name}`" :changes="changes"></change-field>
              </template>
            </div>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="Доставка по умолчанию" name="delivery">
        <el-form label-width="80px" style="width:100%">
          <el-form-item label="SST, MST">
            <div style="display: flex;justify-content: flex-start">
              <el-form-item label="Курьер">
                <delivery-service-selector
                    @change="editAddress.defaultDeliveryServicePrmId = $event ? $event.id : null"
                    v-model="editAddress.defaultDeliveryServicePrm"></delivery-service-selector>
              </el-form-item>
              <el-form-item label="Цена">
                <my-price-input can-be-empty
                                :value="editAddress.defaultDeliveryPricePrm === null ? NaN : editAddress.defaultDeliveryPricePrm"
                                @input="editAddress.defaultDeliveryPricePrm = $event"></my-price-input>
              </el-form-item>
            </div>
          </el-form-item>

          <el-form-item label="PDT">
            <div style="display: flex;justify-content: flex-start">
              <el-form-item label="Курьер">
                <delivery-service-selector
                    @change="editAddress.defaultDeliveryServicePdtId = $event ? $event.id : null"
                    v-model="editAddress.defaultDeliveryServicePdt"></delivery-service-selector>
              </el-form-item>
              <el-form-item label="Цена">
                <my-price-input can-be-empty
                                :value="editAddress.defaultDeliveryPricePdt === null ? NaN : editAddress.defaultDeliveryPricePdt"
                                @input="editAddress.defaultDeliveryPricePdt = $event"></my-price-input>
              </el-form-item>
            </div>
          </el-form-item>
          <el-form-item label="SEQ">
            <div style="display: flex;justify-content: flex-start">
              <el-form-item label="Курьер">
                <delivery-service-selector
                    @change="editAddress.defaultDeliveryServiceSeqId = $event ? $event.id : null"
                    v-model="editAddress.defaultDeliveryServiceSeq"></delivery-service-selector>
              </el-form-item>
              <el-form-item label="Цена">
                <my-price-input can-be-empty
                                :value="editAddress.defaultDeliveryPriceSeq === null ? NaN : editAddress.defaultDeliveryPriceSeq"
                                @input="editAddress.defaultDeliveryPriceSeq = $event"></my-price-input>
              </el-form-item>
            </div>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <template slot="footer">
      <div style="display: flex;justify-content: space-between;width:100%">
        <change-button :changes="changes" :field="`Addresses:(${editAddress.id})`"/>
        <div style="display:flex">
          <my-el-button @click="onBtnCancelClick" type="warning">Отменить</my-el-button>
          <my-el-button @click="onBtnSaveClick()" type="success" :disabled="editAddress.deleled" >Сохранить</my-el-button>
        </div>
      </div>
    </template>
  </component-window>
</template>

<script>
import { alert } from '@/components/common/dialogs/dialogUtils';
import changeField from '@/components/usersClientsAffiliation/ChangeField';
import changeButton from '@/components/usersClientsAffiliation/ChangeButton';
import _ from 'lodash';
import DeliveryServiceSelector from "@/components/deliveries/DeliveryServiceSelector";

const fields = [
  { name: 'postalCode', title: 'Индекс' },
  { name: 'country', title: 'Страна', noShow: true, isRequired: true },
  { name: 'region', title: 'Регион', noShow: false },
  { name: 'area', title: 'Район', },
  { name: 'city', title: 'Город', isRequired: true },
  { name: 'cityDistrict', title: 'Район города' },
  { name: 'street', title: 'Улица', isRequired: true },
  { name: 'house', title: 'Дом', isRequired: true },
  { name: 'block', title: 'Корпус / строение ', prefix: 'кор.' },
  { name: 'flat', title: 'Квартира / офис', prefix: 'кв.' },
  { name: 'fiasCode', title: 'код фиас', noShow: true },
  { name: 'fiasId', title: 'id фиас', noShow: true },
  { name: 'kladrId', title: 'id кладр', noShow: true }
];


export default {
  name: 'AddressEditor',
  props: ['id', 'clientId', 'userId', 'changes', 'onClose'],
  components: { DeliveryServiceSelector, changeField, changeButton },
  data() {
    return {
      windowSize: { minHeight: 400, minWidth: 400, height: '80%', width: '500' },
      editAddress: { forDelete: false },
      isValid: false,
      isWatch: false,
    };
  },

  async mounted() {
    if (!this.id) {
      this.editAddress.clientId = this.clientId;
      if (this.userId) {
        this.editAddress.userRls = [{
          userId: this.userId,
          addressId: 0
        }]
      }

      this.editAddress.userId = this.userId;
      return;
    }
    this.editAddress = await this.$store.dispatch('addresses/loadItem', this.id);
    this.isWatch = true;
  },


  methods: {
    applyHistoryChanges({ fieldName, value }) {
      let field = fieldName.substring(fieldName.indexOf(')') + 1);
      this.editAddress[ field ] = value;
    },
    async onBtnSaveClick() {
      try {
        await this.$refs.addressForm.validate()
      } catch (ex) {
        return;
      }

      try {
        this.editAddress.id = (await this.$store.dispatch('addresses/saveItem', { address: this.editAddress, changes: this.changes })).id;
      } catch (ex) {
        alert(ex.message);
        return;
      }
      this.$emit('close', this.editAddress);
      this.onClose && this.onClose(this.editAddress);
    },

    onBtnCancelClick() {
      this.$emit('close');
      this.onClose && this.onClose(null);
    },

    addressToString(address) {
      const rx = new RegExp(/[0-9]+/g);
      let fldCopy = _.cloneDeep(fields);
      let isNoRussia = (address && address?.countryId || 1) !== 1
      if (isNoRussia) {
        _.reverse(fldCopy);
      }

      return !address
          ? ''
          : fldCopy.reduce(
              (add, dscr) => {
                if ((!dscr.noShow) && address[ dscr.name ] && address[ dscr.name ].trim().length > 0) {
                  let val = address[ dscr.name ].trim();
                  val = isNoRussia
                      ? val
                      : ((dscr.prefix) && rx.test(val)) ? `${dscr.prefix} ${val}` : val;
                  add.push(val);
                }
                return add;
              }, []).join(', ');
    },

    addressToSplitString(address) {
      return !address
          ? ''
          : `${address.street} ${address.house || ''} ${address.block || ''}\n` +
          `${address.flat}\n` +
          `${address.city}${(address.city && address.postalCode) ? ', ' : ''}${address.postalCode}`;
    }
  },

  computed: {
    fields() {
      return fields;
    }
  }
}
</script>

<style scoped>

</style>
