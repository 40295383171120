<template functional>
  <div v-if="props.affiliation && props.affiliation.contracts.filter(c => c.isActive ).length > 0">
    <el-popover
        placement="top-start" trigger="hover"
    >
      <div style="font-size:16px; font-weight: bold; color: #409EFF; min-width: 300px ">Активные договоры</div>
      <div style="display: flex;flex-direction: column">
        <div v-for="contract in props.affiliation.contracts.filter(c => c.isActive )"
             :key="`cntrid_${contract.id}`"
             style="display: flex;flex-wrap: nowrap;flex-direction: column; border-bottom: solid 1px #EBEEF5">
          <div style="display: flex; justify-content: space-between">
            <my-date hide-time :date="contract.date" style="margin-right: 15px; font-weight: bold"></my-date><div style="font-weight: bold">{{ contract.owrAffiliation.shortName }}</div>
          </div>
          <div>
          {{ contract.name }}
          </div>
        </div>
      </div>
      <el-tag type="warning" slot="reference">
        <div style="display: flex;flex-wrap: nowrap">
          Активные договоры:
          <my-date hide-time style="margin-left: 5px;margin-right: 5px; font-weight: bold" :date="props.affiliation.contracts.filter(c => c.isActive)[0].date"></my-date>
          <span v-if="props.affiliation.contracts.filter(c => c.isActive)[ 0 ].name.length < props.length"
                style="font-weight: bold">{{ props.affiliation.contracts.filter(c => c.isActive)[ 0 ].name }}   ...</span>
          <span v-else
                style="font-weight: bold">
                   {{ props.affiliation.contracts.filter(c => c.isActive)[ 0 ].name.substring(0, props.length) }}   ...</span>
        </div>
      </el-tag>
    </el-popover>
  </div>
</template>

<script>
export default {
  name: "myComment",
  props: {
    affiliation: { default: null },
    length: { default: 20 }
  }
}
</script>

<style>
.el-popover {
  min-width: 0 !important;
}
</style>
