<template>
    <div style="display: flex;flex-direction: column;width:auto;max-height: 500px">
        <div v-if="title" style="padding:0 5px 10px 5px">{{title}}</div>
        <div style="padding:10px 5px 0 5px;overflow-y: auto" v-html="message"></div>
        <div style="display: flex;justify-content:center; padding: 20px 5px 5px 5px">
            <el-button @click="$emit('close'); resolve(true)"
                       type="success" >{{ okText ? okText : 'Ok'}}</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'confirmDialogTemplate',
        props: ['title', 'message', 'resolve', 'okText']
    }
</script>

<style scoped>

</style>