<template>
    <div style="display:flex;flex-direction:column;height:100%">
        <div style="display:flex;margin-bottom: 20px ">
            <el-form status-icon label-width="90px" style="width:50%;">
                <el-form-item label="Номер">
                    <div style="display: flex;flex-wrap: nowrap;justify-content: space-between">
                        <div style="display: flex">
                            <div v-if="editOrder.id > 0" style="width:150px">
                                <b v-html="linkToOldBase"></b>
                            </div>
                            <div v-else>Новый заказ - еще нет номера</div>
                        </div>
                        <div style="display: flex;flex-wrap: nowrap">
                            <my-el-button
                                    type="success"
                                    size="mini"
                                    :icon="letterIsSend('newOrder') ? 'el-icon-check':''"
                                    @click="sendNewOrderMail(metaorder)"
                            >
                                <i class="el-icon-message"></i>
                            </my-el-button>
                            <my-el-button
                                    v-if="editOrder.state === 'newOrder'"
                                    :disabled="!!errorsForSetAwaitApprovePopover"
                                    :dis-popover="errorsForSetAwaitApprovePopover"
                                    type="success"
                                    @click="$emit('order-state-change', {orderId: editOrder.id, newState: 'awaitApprove'})"
                                    size="mini">Согласовывается
                            </my-el-button>
                            <my-el-button
                              v-if="editOrder.state === 'awaitApprove'"
                              :disabled="!!errorsForSetInCompilePopover"
                              :dis-popover="errorsForSetInCompilePopover"
                              type="success"
                              @click="$emit('order-state-change', {orderId: editOrder.id, newState: 'inCompile'})"
                              size="mini">Собрать
                            </my-el-button>
                          <my-el-button
                              v-if="editOrder.state === 'inCompile' && !allOrderDeliveriesIsSet"
                              type="success"
                              @click="$emit('order-state-change', {orderId: editOrder.id, newState: 'awaitApprove'})"
                              size="mini">Вернуть на согласование
                          </my-el-button>

                          <my-el-button
                                    v-if="editOrder.state === 'inCompile'"
                                    :disabled="!!errorsForSetReadyToSendPopover"
                                    :dis-popover="errorsForSetReadyToSendPopover"
                                    type="success"
                                    @click="onClickReadyToSend"
                                    size="mini">Готов к отправке
                            </my-el-button>
                            <my-el-button
                               v-if="editOrder.state === 'readyToSend'"
                              type="warning"
                              @click="$emit('order-state-rollback', {orderId: editOrder.id, newState: 'inCompile'})"
                              size="mini">Вернуть в сборку
                            </my-el-button>



                          <my-el-button delete
                                          :disabled="!canDeleteOrder"
                                          :dis-popover="!canDeleteOrder ? 'Сначала нужно отвязать все лоты.' : ''"
                                          @click="$emit('delete-order', editOrder.id)"></my-el-button>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="Состояние">
                    <div style="display: flex; justify-content: space-between">
                        <b>{{$getEnumValue( 'OrderStateEnum', editOrder.state) }}</b>
                        <div>
                            <my-el-button
                                    v-if="metaorder.client && metaorder.client.isDistributor"
                                    type="success"
                                    size="mini"
                                    :icon="letterIsSend('invoiceFor') ? 'el-icon-check':''"
                                    @click="showInvoiceMail"
                            >
                                Отправить инвойс
                            </my-el-button>
                        </div>
                        <div style="margin-bottom: 0;display: flex; flex-wrap: nowrap;align-items: center;width: 100%">
                        <div style="white-space: nowrap;margin-left: 20px; margin-right: 5px">Требует оператора</div>
                        <el-switch
                            active-color="red"
                            v-model="editOrder.needOperatorAction"
                            @change="!$event && (editOrder.needOperatorActionDescription = null)"
                        ></el-switch>
                        <el-form-item v-if="editOrder.needOperatorAction" prop="needOperatorActionDescription"
                                      style="margin-bottom: 0;width: 100%; margin-right: 15px; flex-shrink: 1">
                          <el-input v-model="editOrder.needOperatorActionDescription" style="width:100%;margin-left: 10px;flex-grow: 2"></el-input>
                        </el-form-item>
                      </div>
                    </div>

                </el-form-item>

                <el-form-item label="Дедлайн">
                    <el-date-picker
                            v-model="editOrder.deadline"
                            type="date"
                            default-time="00:00:00"
                            :picker-options="{firstDayOfWeek: 1}"
                            placeholder="">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="Комментарий" style="margin-bottom: 0">
                    <el-input type="textarea"
                              style="padding-bottom: 0"
                              :autosize="{ minRows: 1, maxRows: 4}"
                              v-model="editOrder.comment"/>
                </el-form-item>
            </el-form>
            <div v-if="notDeletedElements.length > 0"
                 style="display: flex;  justify-content: flex-end; width: 50%;border-left: 1px solid #DCDFE6;margin-left: 10px;">
                <price-block :edit-order="editOrder"></price-block>
            </div>
        </div>
        <el-table
                :data="productElementsView"
                style="width: 100%;font-size: 16px"
                :border="true"
                height="100%"
                ref="prodTable"
                :row-class-name="tableRowClassName"
                @selection-change="onRowsSelectionChange"
                v-ls-saver:productTbl
        >
            <el-table-column
                    v-if="['newOrder','awaitApprove', 'inCompile', 'readyToSend'].includes(editOrder.state)"
                    type="selection"
                    width="40"
                    :selectable="canSelectRow"
            >
            </el-table-column>
            <el-table-column type="index" width="65">
                <template slot-scope="scope">
                    {{scope.row.showNumber}}
                </template>
                <template v-slot:header="scope">
                    <my-el-button
                            v-if="['newOrder', 'awaitApprove', 'inCompile', 'readyToSend'].includes(editOrder.state)"
                            :disabled="!!editProduct || selectedRows.length === 0 || selectedRows.length === editOrder.elements.filter(e =>!e.forDelete).length"
                            @click="moveProductsToNewOrder"
                            :fake="scope"
                            popover="В отдельный заказ"
                            type="success" icon="el-icon-sort"></my-el-button>
                </template>
            </el-table-column>
            <el-table-column label="КАТ" :width="100" :resizable="true" prop="product.cat">
                <template slot-scope="scope">
                    <div v-if="$g('editProduct.id') === scope.row.id && !isAnySetSubRow(scope.row)">
                        <el-autocomplete
                                popper-class="ac-product-popper"
                                value-key="cat"
                                v-model="catAutocompleteValue"
                                :fetch-suggestions="onAutocompleteProductSearch"
                                :select-when-unmatched="false"
                                style="width: 100%"
                                @input="onAutocompleteProductInput(scope.row.row)"
                                @select="onAutocompleteProductSelect(scope.row.row, $event)">
                            <template slot-scope="{ item }">
                                <div>{{ item.cat }} {{item.name}}</div>
                            </template>
                        </el-autocomplete>
                    </div>
                    <div v-else>
                        {{ $g('row.subCat',scope, scope.row.mainCat)}}
                        <a target="_blank" :href="`${coreUrl}/products/catalog/${scope.row.mainCat}/details`">
                            <el-popover trigger="hover" placement="top-end" width="100px">
                                Смотреть в CORE
                                <i class="el-icon-view" slot="reference"></i>
                            </el-popover>
                        </a>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="Название" :resizable="true">

                <div slot-scope="scope" v-if="scope.row.row.product" style="display: flex;flex-direction: column">
                    <div style="word-break: break-word">{{ scope.row.row.product.name }}</div>
                    <template v-if="scope.row.row.product.isForSale && ['forSale', 'onDemand'].includes(scope.row.row.product.status)" >
                        <div v-if="!isAnySetSubRow(scope.row) && isWithBuffer(scope.row)">
                            <el-dropdown
                                    v-if="editProduct &&  editProduct.id === scope.row.id "
                                    @command="onBulkSelectClick(scope.row.row, $event)"
                                    size="mini"
                                    split-button
                                    :type="scope.row.row.bulk ? 'success' : 'danger'"
                                    style="white-space: nowrap;"
                            >
                                <span v-html="scope.row.row.bulk ? `${scope.row.row.bulk.name} [${scope.row.row.bulk.cat}]` : 'Без буфера'"></span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item :command="null">Без буфера</el-dropdown-item>
                                    <el-dropdown-item
                                            v-for="(buffer, index) in scope.row.row.product.buffers"
                                            :key="'buf_' + index"
                                            v-html="buffer.buffer.name"
                                            :command="buffer.buffer"
                                    ></el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                            <el-tag v-else
                                    size="mini"
                                    :type="scope.row.row.bulk ? 'success' : 'danger'"
                                    v-html="scope.row.row.bulk ? `${scope.row.row.bulk.name} [${scope.row.row.bulk.cat}]` : 'Без буфера'"
                            ></el-tag>
                        </div>
                        <labels :labels="getLabels(scope.row)"></labels>
                    </template>
                    <el-tag v-else-if="!scope.row.row.product.isForSale" size="mini" style="color:white; background-color: red">Продукт не выведен в продажу, нельзя добавить в заказ</el-tag>
                    <el-tag v-else size="mini" style="color:white; background-color: red">Проверьте коммерческий статус продукта.</el-tag>
                </div>
            </el-table-column>
            <el-table-column label="Резерв" :resizable="true" width="70">
                <template slot-scope="scope">
                <div style="font-size: 12px"
                     v-html="getReservedInfo(scope.row)">
                </div>
                </template>
            </el-table-column>

            <el-table-column label="Кол-во, шт" :width="123" :resizable="true" prop="countAll">
                <template slot-scope="scope">
                    <div v-if="$g('editProduct.id') === scope.row.row.id && scope.row.row.product && !isAnySetSubRow(scope.row)">
                        <el-input-number
                                v-model="scope.row.row.countAll"
                                @change="onChangeCount"
                                controls-position="right"
                                style="width:100px"
                                :min="scope.row.row.product.minCountForOrder"></el-input-number>
                    </div>
                    <div v-else>
                        {{prepareCountColumn(scope.row)}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="Тестер / Спец заказ" :width="140" :resizable="true" prop="isTester" align="center">
                <template slot-scope="scope">
                    <div v-if="$g('editProduct.id') === scope.row.row.id && scope.row.row.product && !isAnySetSubRow(scope.row)"
                         style="display: flex;flex-direction: column" >
                      <div style="display: flex;align-items: center">
                        <el-switch  v-model="scope.row.row.isTester" style="margin: 0 3px 3px 0;"></el-switch>
                        Тестер
                      </div>
                      <div style="display: flex;align-items: center">
                         <el-switch v-model="scope.row.row.isSpecial" style="margin: 0 3px 0 0;"></el-switch>Спец заказ
                      </div>
                    </div>
                    <div v-else style="display: flex;flex-direction: column;justify-content: space-between">
                      <div v-if="scope.row.row.isTester && !isAnySetSubRow(scope.row)"
                          style="display: flex;align-items: center">
                        <i class="el-icon-circle-check" style="font-weight: bold;color:green;margin-right: 3px"></i>
                        Тестер
                      </div>
                      <div v-if ="scope.row.row.isSpecial && scope.row.row.isTester" style="height: 2px"></div>
                      <div v-if="scope.row.row.isSpecial && !isAnySetSubRow(scope.row)"
                          style="display: flex;align-items: center"
                      >
                        <i class="el-icon-circle-check" style="font-weight: bold;color:green;margin-right: 3px"></i>
                        Спец заказ
                      </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="Лот" :resizable="true" width="70">
              <template slot-scope="scope">
                <div v-if="scope.row.row.product && scope.row.row.product.type === 'setFree' && scope.row.mainCat === scope.row.subCat && scope.row.row.allLotsInFreeSetIsBind"
                     style="display: flex;justify-content: center">
                  <icon class="el-icon-check" style="font-weight: bold"></icon>
                </div>
                <div v-if="scope.row.row.product && ((scope.row.row.product.type !== 'setFree' && scope.row.mainCat === scope.row.subCat) || (scope.row.row.product.type === 'setFree' &&  scope.row.subCat !== scope.row.mainCat))"
                     style="display: flex;justify-content: center">
                    <my-el-button plus key="addlot"
                                  v-if="showAddLotButton(scope.row)"
                                  @click="selectLot(scope.row)"
                                  :disabled="!!errorsForLotsButtonsPopover"
                                  :dis-popover="errorsForLotsButtonsPopover"
                    ></my-el-button>
                    <my-el-button delete key="dellot"
                                  v-if="showDeleteLotButton(scope.row)"
                                  :disabled="!!errorsForLotsButtonsPopover"
                                  :dis-popover="errorsForLotsButtonsPopover"
                                  @click="unbindLot(scope.row)"/>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Место" :resizable="true">
                <div slot-scope="scope" style="display: flex;flex-direction: column">
                    <div v-for="lot in scope.row.lots.filter(l => l.cat === scope.row.subCat )" :key="lot.id">
                        <div style="display: flex; justify-content: space-between;flex-wrap: nowrap;">
                            <div style="font-weight: bold">{{lot.lotNumber}}</div>
                            <div class="my-link"
                                 style="margin-left: 10px; white-space: nowrap" @click="onClickPassport(lot)">Паспорт ({{lot.files.length}})
                            </div>
                        </div>
                        <div v-for="rec in getCoreRecsForLot(lot)" :key="rec.id" style="display: flex;flex-wrap: nowrap; margin-left: 10px;justify-content: space-between">
                            <div style="display: flex;flex-wrap: nowrap">
                                <div v-if="rec.isAdditional" style="font-size: 85%; margin-right: 5px">+</div>
                                <div :style="rec.isAdditional ? 'font-size: 85%' :''">{{rec.name}}</div>
                            </div>
                            <div style="display: flex;flex-wrap: nowrap">
                                <b v-if="!rec.isAdditional">{{rec.count}} шт.</b>
                            </div>
                        </div>
                    </div>
                </div>
            </el-table-column>
            <el-table-column label="Цена" width="120" :resizable="true">
                <template slot-scope="scope" v-if="scope.row.row.product && !isAnySetSubRow(scope.row)">
                    {{ $roundFmt(scope.row.row.price) }}
                    <template v-if="scope.row.row.countDiscount">
                        / {{$roundFmt(scope.row.row.discountPrice)}}<span style="color:red">*</span>
                    </template>
                </template>
            </el-table-column>
            <el-table-column label="Цена руками" :width="130" :resizable="true">
                <template slot-scope="scope" v-if="!isAnySetSubRow(scope.row)">
                    <div v-if="$g('editProduct.id') === scope.row.row.id && scope.row.row.product && !isAnySetSubRow(scope.row)">
                      <i v-if="scope.row.row.handPrice === null || scope.row.row.handPrice === undefined"
                         style=" color: blue"
                         class="el-icon-edit"
                         @click="scope.row.row.handPrice = 0" ></i>
                      <div v-else style="display: flex;flex-wrap: nowrap; align-items: center">
                         <my-price-input v-model="scope.row.row.handPrice" style="width: 90px" ></my-price-input>
                         <i style="margin-left: 5px;font-weight: bold; color: red" class="el-icon-close" @click="scope.row.row.handPrice = null" ></i>
                      </div>
                    </div>
                    <div v-else>
                        {{scope.row.row.handPrice || scope.row.row.handPrice === 0 ? $roundFmt(scope.row.row.handPrice) : ''}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column v-if="isDiscountPercentExist" label="С учетом скидки" width="120" :resizable="true">
                <template slot-scope="scope">
                    {{ scope.row.row.discountPercent }}{{scope.row.row.discountPercent ? '%' : ''}}
                </template>
            </el-table-column>

            <el-table-column label="Сумма" width="100" :resizable="true">
                <template slot-scope="scope" v-if="!isAnySetSubRow(scope.row)">
                    {{$roundFmt($calcOrderProductSum(scope.row.row, 0))}}
                </template>
            </el-table-column>
            <el-table-column label="Со скидкой" width="100" :resizable="true">
                <template slot-scope="scope" v-if="!isAnySetSubRow(scope.row)">
                    {{$roundFmt($calcOrderProductSum(scope.row.row, editOrder.discountPercent)) }}
                </template>
            </el-table-column>

            <el-table-column width="110" :resizable="false">
                <template v-if="!isAnySetSubRow(scope.row) && (scope.row.id || scope.row.id === 0) " slot-scope="scope">
                    <template v-if="$g('editProduct.id') === scope.row.id">
                        <my-el-button key="btn1" cancel @click="onBtnCancelEditProductClick(scope.row.row)"/>
                        <my-el-button key="btn2" check :disabled="orderRowIsError(scope.row.row)" @click="onBtnApplyEditProductClick"/>
                    </template>

                    <div v-else style="display: flex;flex-wrap: nowrap">

                        <my-el-button key="btn3" edit
                                      :disabled="!!errorsForEditDeleteProductPopover(scope.row)"
                                      :dis-popover="errorsForEditDeleteProductPopover(scope.row)"
                                      @click="setEditProduct(scope.row.row)"/>
                        <my-el-button key="btn4" delete
                                      :disabled="!!errorsForEditDeleteProductPopover(scope.row)"
                                      :dis-popover="errorsForEditDeleteProductPopover(scope.row)"
                                      @click="onBtnDeleteProductClick(scope.row.row)"/>
                    </div>
                </template>
                <template v-slot:header>
                    <my-el-button
                            type="success"
                            :disabled="!!errorsForAddProductPopover"
                            :dis-popover="errorsForAddProductPopover"
                            @click="onBtnAppendProductClick">Добавить
                    </my-el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    import { alert, confirm } from '@/components/common/dialogs/dialogUtils';
    import _ from 'lodash';
    import productLotSelector from './productLotSelector';
    import baseOrderTab from './BaseOrderTab.js';
    import tableSaver from '@/utils/tableSaver';
    import PriceBlock from "@/components/order/PriceBlock";
    import ProductPassportWindow from "@/components/order/ProductPassportWindow";
    import labels from "@/components/common/Labels";
    import { createNewSubOrderForSplitPdtOrPrimer } from "@/utils/orders";
    import mailEditor from "@/components/mailsAndTemplates/MailEditor";

    export default {
        name: 'productTab',
        components: { labels, PriceBlock },
        props: ['editOrder', 'lockManager', 'ordersLots', 'timestamp', 'metaorder', 'deliverys'],
        mixins: [baseOrderTab, tableSaver],

        data() {
            return {
                selectedRows: [],
                editProduct: null,
                catAutocompleteValue: ''
            }
        },

        watch: {
            timestamp() {
                this.$nextTick(() =>
                                   // eslint-disable-next-line vue/valid-next-tick
                                   this.$nextTick(() => {
                                       this.$refs.prodTable && this.$refs.prodTable.doLayout();
                                   }));
            },
            'editOrder.discountPercent': function() {
                this.calcOrder(this.editOrder, this.metaorder)
            },
        },

        methods: {
          async onClickReadyToSend() {
            const emitEvent = () => this.$emit('order-state-change', {orderId: this.editOrder.id, newState: 'readyToSend'});

            const existRowsWoLots = ! this.productElementsView
                                        .filter(r => !r.canSentWithoutLot)
                                        .every(r => r.row.allLotsInFreeSetIsBind || r.lots.some(l => l.cat === r.subCat) );
            if (existRowsWoLots) {
              await this.$confirm('Не для всех продуктов выбраны лоты. Вы уверены что хотите перевести заказ в состояние \'Готов к отправке\'?' ,'Внимание',
                                  {
                                    dangerouslyUseHTMLString: true,
                                    type: "warning",
                                    confirmButtonText: 'Да, уверен',
                                    confirmButtonClass: 'el-button el-button--danger el-button--mini',
                                    cancelButtonClass: 'el-button el-button--warning el-button--mini',
                                    cancelButtonText: 'Уверен, нет',
                                    showCancelButton: true,
                                    callback(prm) {
                                      if (prm === 'confirm') {
                                        emitEvent();
                                      }
                                    }
                                  });
            } else {
              emitEvent();
            }
          },

            canSelectRow(row) {
                return row.mainCat === row.subCat;
            },

            onRowsSelectionChange(val) {
                this.selectedRows = val;
            },
            async moveProductsToNewOrder() {
                if (!await confirm("Вынести выбранные элементы в отдельный заказ?")) {
                    return;
                }
                let withNewDelivery = await confirm("Сформировать новую доставку для создаваемого заказа?", 'Сформировать', null, true);
                let newOrder = createNewSubOrderForSplitPdtOrPrimer(this.metaorder, this.editOrder, this.deliverys, this.$settings.deadlines, withNewDelivery, this.editOrder.type);

                newOrder.elements = this.editOrder.elements.filter(el => this.selectedRows.some(r => r.id === el.id));
                this.editOrder.elements = this.editOrder.elements.filter(el => !newOrder.elements.some(n => n.id === el.id));
                newOrder.elements.forEach(el => el.orderId = 0);
                this.calcOrder(newOrder, this.metaorder);
                this.calcOrder(this.editOrder, this.metaorder);
            },

            getLabels(row) {
                let product = row.subCat !== row.mainCat
                     ? this.ordersLots?.find(ol => ol.cat === row.subCat)?.product
                     : row.row.product;

                if (!product) {
                    return [];
                }
                let result = product.siteGroups.filter(rl => rl.siteGroup.isLabelCrmShow).map(rl => rl.siteGroup);
                if (product.isDeliveryWarning) {
                    result.push({
                                    labelCrmText: 'ОУД',
                                    labelCrmTitle: `<U>Особые условия доставки:</U><br/><br/>${product.deliveryWarningText}`,
                                    labelCrmColor: 'red'
                                });
                }
                return result;
            },

            getReservedInfo(row) {
                let catRow = this.ordersLots?.find(ol => ol.cat === row.subCat);
                if (!catRow) {
                    return '';
                }
                let so = catRow.stockCount - catRow.reservedInOrders;
                let style = (so - row.count < 0) ? ' style = "font-weight:bold; color: red" ' : '';
                return `<div><div ${style}>СО: ${so}</div><div>P: ${catRow.reservedInOrders}</div></div>`;
            },

            async onClickPassport(lot) {
                this.turnWatch(false);
                lot.files = await this.$showWindowAsync(ProductPassportWindow, { lotId: lot.id, metaorderId: this.metaorder.id, orderId: this.editOrder.id })
                this.turnWatch(true);
            },

            prepareCountColumn(row) {
                if (!row.count) {
                    return '';
                }
                return row.count +
                    (this.metaorder.isStock
                            ? ` (продано: ${row.countStock})`
                            : ''
                    );
            },

            async showInvoiceMail() {
              let mail = await this.$mailUtils.buildInvoiceMail(this.metaorder, this.editOrder.id, this.deliverys);
              mail = await this.$showWindowAsync(mailEditor, { mail, mode: 'send', actionBeforeSend: null, accountType: 'com',
                noFilesMessage: this.metaorder.isArchive
                    ? 'Для работы с файлами сначала нужно во вкладке "Файлы"  достать файлы из архива' : ''
              });
              if (mail) {
                this.lockerIsWatch = false;
                this.$nextTick(() => {
                  this.metaorder.mails.push(mail);
                  this.lockerIsWatch = true;
                });
              }
            },

            isWithBuffer(row) {
                return row.row.product && row.row.product.buffers && row.row.product.buffers.length > 0
            },

            orderRowIsError(row) {
                return !row.product
                    || !row.countAll
                    || row.countAll < row.product.minCountForOrder
                    || !row.product.isForSale
                    || !['forSale', 'onDemand'].includes(row.product.status);
            },

            setEditProduct(row) {
                if (row.handPrice === null) {
                    row.handPrice = undefined;
                }
                this.editProduct = _.cloneDeep(row);
                this.catAutocompleteValue = row.product.cat;
            },

            onBtnCancelEditProductClick(row) {
                const idx = _.findIndex(this.editOrder.elements, item => item.id == row.id);
                if (row.id === 0) {
                    this.editOrder.elements.splice(idx, 1);
                } else {
                    this.editOrder.elements.splice(idx, 1, this.editProduct);
                }
                this.editProduct = null;
                this.catAutocompleteValue = '';
                this.calcOrder(this.editOrder, this.metaorder);
            },

            onBtnApplyEditProductClick() {
                if (this.editProduct.id === 0) {
                    let minId = _.min(this.editOrder.elements.map(item => item.id));
                    minId = (!minId || minId >= 0) ? -1 : (minId - 1);
                    const row = _.find(this.editOrder.elements, p => p.id === 0);
                    row.id = minId;
                }
                this.editProduct = null;
                this.catAutocompleteValue = '';
                this.calcOrder(this.editOrder, this.metaorder);
                this.$refs[ 'prodTable' ].sort('product.cat');
                this.$nextTick(() => this.$emit("reload-product-lots"));
            },

            async onBtnDeleteProductClick(product) {
                if (!await confirm('Удалить продукт?', 'Удалить')) {
                    return;
                }
                const idx = this.editOrder.elements.indexOf(product);
                if (this.editOrder.elements[ idx ].id > 0) {
                    this.editOrder.elements[ idx ].forDelete = true;
                } else {
                    this.editOrder.elements.splice(idx, 1);
                }
                this.calcOrder(this.editOrder, this.metaorder);
            },

            onBtnAppendProductClick: function() {
                let maxPrior = 1;
                if (this.editOrder.elements.length > 0) {
                  maxPrior = _.max(this.editOrder.elements.map(el => el.prior)) + 1;
                }
                this.editOrder.elements.push(
                    {
                        id: 0,
                        countAll: 1,
                        countDiscount: 0,
                        countStock: 0,
                        product: null,
                        orderId: this.editOrder.id,
                        bulk: null,
                        lots: [],
                        price: 0,
                        discountPrice: 0,
                        discountPercent: 0,
                        forDelete: false,
                        prior: maxPrior,
                        handPrice: null
                    });
                this.editProduct = { id: 0 };
            },

            async onAutocompleteProductSearch(search, cb) {
                search = search.trim();
                if (search.length < 2) {
                    cb([]);
                    return;
                }
                const query = {
                    currentPage: 1,
                    pageSize: 100,
                    orderBy: 'Cat',
                    orderIsDesc: false,
                    query: [
                        { fieldName: 'cat', op: 'like', values: [search] },
                        { fieldName: 'name', op: 'like', values: [search] },
                    ]
                }
                const result = (await this.$store.dispatch('products/loadItems', query)).items;
                //Дистрибьютерам даем заводить одинаковые позиции в заказе
                if (this.metaorder.client?.isDistributor) {
                    cb(result);
                } else {
                    cb(result.filter(r => !this.notDeletedElements.some(p => p.id !== 0 && p.product != null && p.product.id === r.id)));
                }
            },

            onAutocompleteProductSelect(row, product) {
                let priceFields = new Map([
                                              ['usd', 'priceUsd'],
                                              ['eur', 'priceEuro'],
                                              ['rub', 'price']]);
                row.product = product;
                row.price = product[ priceFields.get(this.metaorder.currency) ];
                row.discountPrice = row.price / 2;  //TODO: работает т.к. сейчас всегда 50%
                row.productId = product.id;
                if (!row.countAll || row.countAll < product.minCountForOrder) {
                    row.countAll = product.minCountForOrder;
                }
            },

            onAutocompleteProductInput(row) {
                row.product = null;
            },

            onBulkSelectClick(row, buffer) {
                row.bulk = buffer;
                row.bulkId = buffer ? buffer.id : null;
            },

            onChangeCount() {
                this.calcOrder(this.editOrder, this.metaorder);
            },

            setOrderProductCounts(order) {
                let groups = [];
                // привязываем продукты к группам
                order.elements
                .filter(e => !e.forDelete)
                .forEach(ordRow => {
                    if (ordRow.product?.groups) {
                        ordRow.countDiscount = 0;
                        ordRow.product.groups.forEach(g => {
                            let fndGroup = _.find(groups, ['groupId', g.productGroupId]);
                            if (!fndGroup) {
                                fndGroup = { groupId: g.productGroupId, products: [] };
                                groups.push(fndGroup);
                            }
                            if (ordRow.countDiscount === 0) {
                                fndGroup.products.push(ordRow);
                            }
                        });
                    }
                });
                groups = groups.filter(group => _.sumBy(group.products, 'countAll') > 1);
                groups.forEach((group) => {
                    const sortedProd = _.sortBy(group.products, ['price']);
                    sortedProd[ 0 ].countDiscount = sortedProd[ 0 ].countAll - 1;
                    _.tail(sortedProd).forEach((product) => {
                        product.countDiscount = product.countAll;
                        // Удаляем из других групп что бы скидка посчиталась только для одной груупппыыы
                        groups.forEach((item) => {
                            const index = item.products.indexOf(product);
                            if ( index >= 0) {
                              item.products.splice(index, 1);
                            }
                        });
                    });
                });
            },

            calcOrder(order, metaorder) {
                if (metaorder.client && !metaorder.client.isDistributor) {
                    this.setOrderProductCounts(order);
                }
                order.price = order.elements.reduce((acc, row) =>
                   acc + ((!row.forDelete && !row.isTester) ? this.$calcOrderProductSum(row, 0) : 0), 0);
                order.discountPrice = order.discountPercent
                   ? order.elements.reduce((acc, row) => acc + ((!row.forDelete && !row.isTester) ? this.$calcOrderProductSum(row, order.discountPercent) : 0), 0)
                   : 0;
            },

            isAnySetSubRow(row) {
                return row.row.product && (row.row.product.type === 'set' || row.row.product.type === 'setFree') && row.mainCat !== row.subCat;
            },

            async selectLot(row) {
                if (!this.ordersLots) {
                    if (await confirm("Нет информации по складским остаткам. Попробовать получить ?")) {
                        this.$emit("reload-product-lots", () => {
                            this.selectLot(row);
                        })
                    }
                    return;
                }

                if (this.lockManager.isEditNow || !this.editOrder.number) {
                    await this.saveOrder();
                    //После перегрузки заказа row продолжает показывать на старую запись поэтому надо найти актуальную
                    row = _.find(this.productElementsView, r => r.mainCat === row.mainCat && r.subCat === row.subCat);
                }
                let catInfo = _.find(this.ordersLots, l => l.cat === row.subCat);

                this.$showWindow(productLotSelector, { orderRowId: row.row.id, catInfo, count: row.count }, async storeRecords => {
                    if (!storeRecords) {
                        return;
                    }
                    let selectedLotNumber = storeRecords[ 0 ].lotNumber;
                    if (row.row.product.type !== 'setFree') {
                        let otherRows = this.notDeletedElements.filter(r => r.id !== row.row.id);
                        let lots = otherRows.map(row => _.find(row.lots, l => l.cat === row.product.cat)).filter(r => r);
                        if (lots.some(l => l.lotNumber === selectedLotNumber) && !this.metaorder.client?.isDistributor) {
                          await alert(`В заказе уже есть позиция с CAT ${row.row.product.cat} и номером лота ${selectedLotNumber}`);
                            return false;
                        }
                    }
                    let uniqLotNumbers = [...new Set(storeRecords.map(l => l.lotNumber))];
                    let lots = uniqLotNumbers.reduce((acc, lotNumber) => {
                        let lotRecords = storeRecords.filter(r => r.lotNumber === lotNumber);
                        let lot = {
                            lotNumber: lotNumber,
                            count: _.sumBy(lotRecords, 'count'),
                            cat: row.subCat,
                            orderProductId: row.row.id,
                            coreLotInfo: JSON.stringify(lotRecords.map(r => ({ id: r.id, name: r.storeName, count: r.count, isAdditional: r.isAdditional }))),
                            files: catInfo.lots
                            .find(lot => lot.lotNumber === lotNumber)
                            ?.files
                            ?.map(rec => ({
                                metaorderId: this.editOrder.metaorderId,
                                orderId: this.editOrder.id,
                                type: 'lotPassport',
                                name: rec.name,
                                description: rec.url     //Через description передаю на бэк url откуда скачивать паспорт
                            }))
                        };
                        acc.push(lot);
                        if (row.row.product.type === 'set') {
                            try {
                                acc.push(...buildSubLotsForSet(lotNumber));
                            } catch (err) {
                                alert(err.message);
                                throw err;
                            }
                        }
                        return acc;
                    }, []);

                    let dataForSend = {
                        orderId: this.editOrder.id,
                        orderNumber: this.editOrder.number,
                        metaorderNumber: this.metaorder.number,
                        isFreeSet: row.row.product.type === 'setFree',
                        freeSetCat: row.row.product.type === 'setFree' ? row.mainCat : '',
                        freeSetCount: row.row.product.type === 'setFree' ? row.row.countAll : '',
                        lots,
                    };
                    let mainLot = _.find(row.lots, l => l.cat === row.mainCat);
                    dataForSend.freeSetMainLot = dataForSend.isFreeSet && mainLot ? mainLot.lotNumber : '';

                    try {
                        this.turnWatch(false);
                        row.row.lots.push(...await this.$store.dispatch('productLots/saveItem', dataForSend));
                        this.turnWatch(true);
                    } catch (ex) {
                        await alert(ex.message);
                        return false;
                    }

                    if (row.row.product.type === 'setFree') {
                      let allSetLotsIsBind = this.productElementsView
                       .filter(viewRow => viewRow.id === row.id && viewRow.mainCat !== viewRow.subCat)
                       .every(viewRow => viewRow.lots.some(l => l.cat === viewRow.subCat));
                      if (row.row.allLotsInFreeSetIsBind !== allSetLotsIsBind) {
                        row.row.allLotsInFreeSetIsBind = allSetLotsIsBind;
                        await this.saveOrder();
                      } else {
                        this.$emit("reload-product-lots");
                      }
                    } else {
                      this.$emit("reload-product-lots");
                    }
                    return true;
                });


                let buildSubLotsForSet = (selectedLotNumber) => {
                    let resultLots = [];
                    let mainLot = _.find(catInfo.lots, l => l.lotNumber === selectedLotNumber);
                    mainLot.childrens.forEach(subLot => {
                        //Получаем нужное количесво для лота
                        let fndCat = _.find(catInfo.childCats, c => c.cat === subLot.cat);
                        let needCount = fndCat.volume * row.count;
                        //Сформировать лот можно только из записей на одном складе
                        let storeGroups = Object.values(_.groupBy(subLot.storePlaces, s => s.storeId));
                        //Ищем группу записей с максимальным количеством на одном складе
                        let maxGroup = _.orderBy(storeGroups, [g => _.sumBy(g, 'count')], ['desc'])[ 0 ];
                        if (_.sumBy(maxGroup, 'count') < needCount) {
                            throw new Error("Не хватает лота: " + subLot.lotNumber);
                        }

                        let lot = {
                            lotNumber: subLot.lotNumber,
                            count: needCount,
                            cat: subLot.cat,
                            orderProductId: row.row.id,
                        };
                        resultLots.push(lot);
                        let storeRecs = [];
                        //Набираем в лот складские позиции;
                        let orderedGroup = _.orderBy(maxGroup, ['count'], ['asc']);
                        for ( let idx = 0; idx < orderedGroup.length; idx++ ) {
                            let storeRow = orderedGroup[ idx ];
                            if (storeRow.count >= needCount) {
                                storeRecs.push({ id: storeRow.id, name: storeRow.name, count: needCount });
                                break;
                            }
                            storeRecs.push({ id: storeRow.id, name: storeRow.name, count: storeRow.count });
                            needCount -= storeRow.count;
                        }
                        lot.coreLotInfo = JSON.stringify(storeRecs)
                    });
                    return resultLots;
                }
            },

            async unbindLot(row) {
                if (!await confirm("Отвязать лот?")) {
                    return;
                }

                if (this.lockManager.isEditNow) {
                    await this.saveOrder();
                    //После перегрузки заказа row продолжает показывать на старую запись поэтому надо найти актуальную
                    row = _.find(this.productElementsView, r => r.mainCat === row.mainCat && r.subCat === row.subCat);
                }

                let dataForSend = {
                    orderNumber: this.editOrder.number,
                    metaorderNumber: this.metaorder.number,
                    isFreeSet: row.row.product.type === 'setFree',
                    freeSetCat: row.row.product.type === 'setFree' ? row.mainCat : '',
                    lots: row.mainCat === row.subCat
                        ? row.lots
                        : [_.find(row.lots, l => l.cat === row.subCat)]
                };
                let mainLot = _.find(row.lots, l => l.cat === row.mainCat);
                dataForSend.freeSetMainLot = dataForSend.isFreeSet && mainLot ? mainLot.lotNumber : '';

                try {
                    await this.$store.dispatch('productLots/deleteItem', dataForSend);
                } catch (ex) {
                    await alert(ex.message);
                    return false;
                }
                this.turnWatch(false);
                if (row.row.product.type !== 'setFree') {
                    row.row.lots = [];
                    this.$emit("reload-product-lots");
                } else {//смотрим что есть другие лоты кроме главного и того что удаляем
                  if (row.row.lots.some(l => l.cat !== row.mainCat && l.cat !== row.subCat)) {
                    let idx = _.findIndex(row.lots, l => l.cat === row.subCat);
                    row.lots.splice(idx, 1);
                  } else {
                    row.row.lots = [];
                  }
                  if (row.row.allLotsInFreeSetIsBind) {
                    row.row.allLotsInFreeSetIsBind = false;
                    await this.saveOrder();
                  } else {
                    this.$emit("reload-product-lots");
                  }
                }

                this.turnWatch(true);
            },

            getLotNumbersForRow(row) {
                return row.lots?.filter(l => l.cat === row.subCat).map(l => l.lotNumber) || [];
            },

            getCoreRecsForLot(lot) {
                return JSON.parse(lot.coreLotInfo) || [];
            },

            showAddLotButton(row) {
                if (this.getLotNumbersForRow(row).length > 0) {
                    return false;
                }
                if (row.row.product?.type === 'set' && row.subCat !== row.mainCat) {
                    return false;
                }
                return !(row.row.product?.type === 'setFree' && row.subCat === row.mainCat);
            },

            showDeleteLotButton(row) {
                if (this.getLotNumbersForRow(row).length === 0) {
                    return false;
                }
                if (row.row.product?.type === 'set' && row.subCat !== row.mainCat) {
                    return false;
                }
                return !(row.row.product?.type === 'setFree' && row.subCat === row.mainCat);

            },

            tableRowClassName({ row }) {
                if (row && this.$g('row.product.type', row) === 'set' || this.$g('row.product.type', row) === 'setFree') {
                    return (row.mainCat === row.subCat) ? 'main-set-row' : 'child-set-row';
                }
                return '';
            },

            errorsForEditDeleteProductPopover(row) {

                if (this.isAdminEditMode) {
                  return '';
                }

                if (this.errorsForAddProductPopover) {
                    return this.errorsForAddProductPopover;
                }
                if (row.lots.length > 0) {
                    return "Для редактирования или удаления нужно отвязать лоты.";
                }
            },
        },

        computed: {
            isAdminEditMode() {
              return this.$store.state.shared.currentUser.roles.some(r => r === 'EndOrderProductEditor');
            },

            canDeleteOrder() {
                return !this.editOrder.elements.some(el => (el.lots?.length || 0) > 0);
            },

            error() {
              if (this.editProduct) {
                return  'Нужно закончить редактирование продукта';
              }
              if ( !this.editOrder.elements.some(e => !e.forDelete) ) {
                return `Заказ должен содержать элементы чтоб сохраненным быть.`;
              }
              return '';

            },

            linkToOldBase() {
                let number = this.editOrder.number;
                let title = this.editOrder.number;
                if (!this.editOrder.oldJson && this.metaorder.currency !== 'rub') {
                    number = this.metaorder.number;
                } else {
                    if (!this.editOrder.oldJson) {
                        return number;
                    }
                }
                return `<a target='_blank' href='http://baza.evrogen.net/PHP/products/order_editor.php?order=${number}'>${title}</a>`;
            },

            coreUrl() {
                return this.$settings?.core?.url;
            },

            isDiscountPercentExist() {
                return this.productElementsView.some(e => e.row.discountPercent);
            },

            productElementsView() {
                let result = this.notDeletedElements.map(row => ({
                    id: row.id,
                    mainCat: this.$g('product.cat', row),
                    subCat: this.$g('product.cat', row),
                    row: row,
                    count: row.countAll,
                    countStock: row.countStock,
                    lots: row.lots,
                    prior: row.prior,
                    canSentWithoutLot: row.product?.canSentWithoutLot
                }));
                this.notDeletedElements
                .filter(row => row.product && (row.product.type === 'set' || row.product.type === 'setFree'))
                .forEach(row => {
                    let catInfo = _.find(this.ordersLots, l => l.cat === row.product.cat);
                    if (catInfo) {
                        catInfo.childCats.forEach(subCat => {
                            result.push({
                                            id: row.id,
                                            mainCat: row.product.cat,
                                            subCat: subCat.cat,
                                            row,
                                            lots: row.lots,
                                            count: subCat.volume * row.countAll,
                                            prior: row.prior
                                        });
                        });
                    }
                });

                let number = 0;
                return _.orderBy(result, ["prior", "mainCat"], ["asc", "asc"])
                .map(r => Object.assign({ showNumber: r.mainCat === r.subCat ? ++number : null }, r));
            },

            errorsForLotsButtonsPopover() {
                if (this.editOrder.state !== 'inCompile') {
                    return 'Заказ не в состоянии когда можно привязывать или отвязывать лоты.';
                }
                return (this.editProduct ? 'Для добавления или удаления лота завершите редактирование элемента заказа.<br/>' : '');
            },

            errorsForAddProductPopover() {
                if (!this.lockManager.canEdit) {
                    return "Заказ редактирует другой пользователь.";
                }
                if ( !['newOrder', 'inCompile', 'awaitApprove'].includes(this.editOrder.state))
                {
                    return 'Заказ не в состоянии когда можно добавлять, изменять или удалять продукты.';
                }
                return (this.editProduct ? 'Для добавления, удаления или изменения продукта завершите редактирование элемента заказа.<br/>' : '');
            },

            allOrderDeliveriesIsSet() {
              const allOrderDeliveries = this.deliverys.filter(dlv => !dlv.forDelete && dlv.ordersRl.some(rl => rl.orderId === this.editOrder.id))
              return allOrderDeliveries.every(dlv => dlv.scheduledSendDate);
            },

            errorsForSetInCompilePopover() {
                if (!this.allOrderDeliveriesIsSet) {
                  return "В разделе 'Доставка' должно быть заполнить поле 'Запланирован' для всех доставок в которые входит заказ."
                }

                if (!this.lockManager.canEdit) {
                    return "Заказ редактирует другой пользователь.";
                }
                if (this.notDeletedElements.length === 0) {
                    return "В заказе нет элементов.";
                }


                if (!this.checkOrderPay) {
                    return 'Заказ не оплачен.'
                }
                return (this.editProduct ? 'Для изменения состояния заказа завершите редактирование элементов заказа.<br/>' : '');
            },

          errorsForSetAwaitApprovePopover() {
            if (!this.lockManager.canEdit) {
              return "Заказ редактирует другой пользователь.";
            }
            if (this.notDeletedElements.length === 0) {
              return "В заказе нет элементов.";
            }
            return (this.editProduct ? 'Для изменения состояния заказа завершите редактирование элементов заказа.<br/>' : '');
          },




          errorsForSetReadyToSendPopover() {
                if (this.errorsForSetInCompilePopover) {
                    return this.errorsForSetInCompilePopover;
                }
                if ( this.$store.state.shared.currentUser.roles.includes('Administrator') )
                {
                  return ''
                }

                return this.productElementsView
                  .filter(r => !r.canSentWithoutLot)
                  .every(r => r.row.allLotsInFreeSetIsBind || r.lots.some(l => l.cat === r.subCat) )
                    ? ''
                    : 'Не для всех продуктов выбраны лоты.';
            },

            notDeletedElements() {
                return this.editOrder.elements.filter(e => !e.forDelete);
            }
        }
    }
</script>

<style>
    .ac-product-popper {
        width: auto !important;
    }

    .main-set-row {
        background-color: #ecf5ff !important;
    }

    .child-set-row {
        background-color: #F5F7FA !important;
    }
</style>
