<template>
    <component-window
            title="Выбор элемента счета"
            @close="onBtnCancelClick"
    >
        <div style="display: flex;flex-direction: column;justify-content: stretch;height: 100%">
            <div style="display: flex;flex-wrap: nowrap;justify-content: space-between">
                <div style="display: flex">
                    <el-form>
                        <el-form-item label="Выбрано элементов" style="margin-bottom: 0">
                            <div style="display: flex">
                                <b>{{preparedRows.reduce((acc,row) => acc + row.count * 1,0)}}</b>
                                <el-form-item style="margin-bottom: 0" label-position="right" label-width="90px" label="на сумму"><b>{{calcSum}}</b></el-form-item>
                                <div style="margin-left: 10px">{{ $getEnumValue('CurrencyEnum', currency)}}</div>
                            </div>
                        </el-form-item>
                    </el-form>
                </div>
                <div style="display: flex">
                    <el-form>
                        <el-form-item style="margin-bottom: 0" label-position="right" label-width="190px" label="Поиск продуктов">
                            <el-input v-model="fndProduct" @input="loadProductNodes()" style="margin-right: 3px"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <div style="overflow-y: auto;margin-top: 3px;height: 100%" ref="treeDiv">
                <el-tree
                        :expand-on-click-node="false"
                        :props="{isLeaf: 'leaf'}"
                        :load="loadNode"
                        show-checkbox
                        lazy
                        @check="onCheckNodeChange"
                        @node-collapse="onCollapseNode"
                        ref="tree"
                >
                    <div style="display: flex;width: 100%; flex-wrap: nowrap;align-items: center; justify-content: space-between; margin:5px 0 5px 0"
                         slot-scope="{ node, data }">
                        <div v-if="data.key === 'other' && node.checked">
                            Название:
                            <el-input v-model="node.data.name"></el-input>
                        </div>
                        <div v-else >{{ node.label }}</div>
                        <div style="display: flex;flex-wrap: nowrap;margin-bottom: 5px">
                            <el-select v-if="data.positions" v-model="data.position" style="width: 70px;margin-right: 3px">
                                <el-option v-for="position in data.positions"
                                           :key="position"
                                           :label="position"
                                           :value="position"
                                ></el-option>
                            </el-select>
                            <el-select v-if="data.scale" v-model="data.scale" style="width: 70px">
                                <el-option v-for="scale in [0.02, 0.04, 0.2, 1]"
                                           :key="scale"
                                           :label="scale"
                                           :value="scale"
                                ></el-option>
                            </el-select>
                            <div v-if="!node.disabled" style="display:flex;justify-content:flex-end;width:200px;">
                                <el-form :model="node.data" @submit.native.prevent>
                                    <div style="display: flex; flex-wrap: nowrap">
                                        <el-form-item v-if="node.data.key === 'other'" prop="count" :rules="[$validateRuleRequired]" style="margin-bottom: 0;margin-right: 3px">
                                            <div style="display: flex;flex-wrap: nowrap">
                                                <div v-if="node.checked" style="margin:0 2px 0 10px">#CAT:</div>
                                                <el-input v-if="node.checked" v-model="node.data.cat" style="width:70px"></el-input>
                                            </div>
                                        </el-form-item>

                                        <el-form-item v-if="node.data.key === 'delivery' || node.data.key === 'other'"
                                                      prop="price"
                                                      :rules="countRule"
                                                      style="margin-bottom: 0;margin-right: 3px">
                                            <div style="display: flex;flex-wrap: nowrap">
                                                <div v-if="node.checked" style="margin:0 2px 0 10px">Цена:</div>
                                                <my-price-input
                                                        class="left-number-input"
                                                        style="width:90px"
                                                        v-if="node.checked"
                                                        v-model="node.data.price">
                                                </my-price-input>
                                            </div>
                                        </el-form-item>
                                        <el-form-item v-if="node.data.key !== 'delivery'" prop="count" :rules="countRule" style="margin-bottom: 0;margin-right: 3px">
                                            <div style="display: flex;flex-wrap: nowrap">
                                                <div v-if="node.checked" style="margin:0 2px 0 10px">К-во:</div>
                                                <el-input v-if="node.checked" v-model="node.data.count" style="width:70px"></el-input>
                                            </div>
                                        </el-form-item>
                                    </div>
                                </el-form>
                            </div>
                        </div>
                    </div>
                </el-tree>
            </div>
        </div>

        <template slot="footer">
            <div style="display:flex">
                <my-el-button @click="onBtnCancelClick" type="warning" align="right">Отменить</my-el-button>
                <my-el-button @click="onBtnSaveClick()" type="success">Сохранить</my-el-button>
            </div>
        </template>
    </component-window>
</template>

<script>
    import * as invoiceHelper from '@/utils/invoiceHelper';

    export default {
        name: 'InvoiceElementSelector',
        props: [ 'currency', 'onClose' ],
        data() {
            return {
                windowSize: { minHeight: 400, minWidth: 400, height: '90%', width: '50%' },
                countRule: [ this.$validateRuleNumber?.(false, false, true, false, true) ],
                checkedNodes: [],
                fndProduct: '',
                saveProductNode: null,
                priceFields: new Map([
                                         [ 'usd', 'priceUsd' ],
                                         [ 'eur', 'priceEuro' ],
                                         [ 'rub', 'price' ] ]),
                treeRoot: [
                    {
                        label: 'Продукты',
                        key: 'pdt',
                        disabled: true
                    },
                    {
                        label: 'Синтез',
                        key: 'synthes',
                        disabled: true
                    },
                    {
                        label: 'Секвенирование',
                        key: 'seq',
                        disabled: true
                    },
                    {
                        label: 'Доставка',
                        leaf: true,
                        key: 'delivery',
                        name: 'Услуги доставки',
                        count: 1,
                        price: 0,
                        discountPrice: 0
                    },
                    {
                        label: 'Что то еще',
                        leaf: true,
                        key: 'other',
                        name: '',
                        count: 1,
                        price: 0,
                        cat: '',
                        discountPrice: 0
                    }


                ]
            }
        },

        methods: {
            onCheckNodeChange(node, props) {
                this.checkedNodes = props.checkedNodes;
            },

            onCollapseNode(data, node) {
               //TODO: Лень разбираться - без expand после схлопывания пропадают продукты из рендера
               node.expand();
               return false;
            },

            async onBtnSaveClick() {
                if (this.checkedNodes.some(node => !node.disabled && (!Number.isInteger(node.count * 1) || node.count * 1 === 0))) {
                    return;
                }
                this.onClose(this.preparedRows);
                this.$emit('close', null);
            },

            onBtnCancelClick() {
                this.$emit('close', null);
            },

            async loadProductNodes() {
                let node = this.$refs.tree.root.childNodes[ 0 ];

                if (this.fndProduct.trim().length < 3) {
                    if (!this.fndProduct.trim()) {
                        node.childNodes = node.childNodes.filter(n => n.checked);
                    }
                    return;
                }
                node.expanded = true;
                node.childNodes = node.childNodes.filter(n => n.checked);
                if (this.fndProduct.trim()) {
                    node.doCreateChildren(
                        (await this.loadProducts()).map(p => ({
                            label: `${p.cat}: ${p.name}`,
                            leaf: true,
                            product: p,
                            count: 1,
                        }))
                    );
                    this.$refs.treeDiv.scrollTo(0, 0);
                }
            },

            async loadProducts() {
                const queryArr = [
                    { fieldName: 'cat', op: 'like', values: [ this.fndProduct ] },
                    { fieldName: 'name', op: 'like', values: [ this.fndProduct ] },
                ];
                return await this.$store.dispatch('products/loadItems', { query: queryArr });
            },

            async loadNode(node, resolve) {
                let positions = [
                    { field: 'acceptedInLeft', name: '5`' },
                    { field: 'acceptedInIn', name: 'In' },
                    { field: 'acceptedInRight', name: '3`' }
                ];
                let result = [];
                if (node.level === 0) {
                    result = this.treeRoot;
                } else if (node.data.key === 'synthes') {
                    result = [
                        { key: 'mods', label: 'Модификаторы', disabled: true },
                        { key: 'zonds', label: 'Зонды', disabled: true },
                    ];
                    result.push(...(invoiceHelper.syntData.map(sd => ({
                        key: sd.key,
                        label: sd.name,
                        scale: 0.04,
                        leaf: true,
                    }))));
                } else if (node.data.key === 'mods') {
                    result = this.$store.state.modifiers.items.map(modifier => ({
                        label: modifier.name,
                        scale: 0.04,
                        position: positions.filter(p => modifier[ p.field ])[ 0 ].name,
                        positions: positions.filter(p => modifier[ p.field ]).map(p => p.name),
                        leaf: true,
                        modifier
                    }));
                } else if (node.data.key === 'zonds') {
                    result = this.$store.state.zonds.items.map(zond => ({
                        label: zond.modifiers.map(m => m.name).join(' '),
                        scale: 0.04,
                        leaf: true,
                        count: 1,
                        zond,
                    }));
                } else if (node.data.key === 'seq') {
                    result = invoiceHelper.seqData.map(sd => ({
                        label: sd.name,
                        leaf: true,
                        key: sd.key,
                    }));
                    result.push(
                        ...(this.$getEnum('FragmentOptionsEnum').filter(o => o.value !== 'none').map(option => (
                            {
                                label: this.$getEnumValue('FragmentOptionsEnum', option.value),
                                leaf: true,
                                key: `${option.value}`,
                            }))));
                }
                result = result.map(r => r.disabled ? r : Object.assign(r, { count: 1 }));
                return resolve(result);
            }
        },
        computed: {
            calcSum() {
                let result = this.preparedRows.filter(row => row.price).reduce((acc, row) => acc + row.count * row.price, 0);
                return Number.isNaN(result) ? '-' : result;
            },

            preparedRows() {
                let isSynthes = node => invoiceHelper.syntData.some(sd => sd.key === node.key);
                let isSeq = node => invoiceHelper.seqData.some(sd => sd.key === node.key)
                    || this.$getEnum('FragmentOptionsEnum').some(en => en.value === node.key);

                let buildRowByType = (node) => {
                    if (node.modifier) {
                        return invoiceHelper.createModifierRow(node.modifier.id, node.scale, node.position.toLowerCase(), node.count * 1, this);
                    } else if (node.zond) {
                        return invoiceHelper.createZondRow(node.zond.modifiers.map(m => m.id), node.scale, node.count * 1, this)
                    } else if (node.product) {
                        let price = node.product[ this.priceFields.get(this.currency) ];
                        return invoiceHelper.createProductRow(node.product, node.count * 1, price, price)
                    } else if (isSynthes(node)) {
                        return invoiceHelper.createSyntRow(node.key, node.scale, node.count * 1, this)
                    } else if (isSeq(node)) {
                        return invoiceHelper.createSequenceRow(node.key, node.count, this)
                    } else if (node.key === 'delivery') {
                        return invoiceHelper.createDeliveryRow(node.price, this)
                    } else if (node.key === 'other') {
                        return invoiceHelper.createInvoiceRow(
                            `other`,
                            node.cat,
                            node.name,
                            '',
                            node.price,
                            node.price,
                            node.count,
                        );
                    }
                };
                return this.checkedNodes.filter(n => !n.disabled).map(node => buildRowByType(node));
            }
        }
    }
</script>
<style>
    .el-tree-node__content {
        margin-top: 3px !important;
        margin-bottom: 3px !important;
    }
</style>
