<template>
    <div style="display:flex;justify-content: stretch;flex:1 1;height:100%">
        <div @drop.prevent="canAddFile && addFiles($event)"
             @dragover.prevent="canAddFile && (highlightBorder = true)"
             @dragenter="canAddFile && (highlightBorder = true)"
             @dragleave="canAddFile && (highlightBorder = false)"
             :style="mainMultiDivStyle"
             style="display:flex;flex: 1 1 100%;width: 100%">
            <div v-if="filteredFiles.length === 0"
                 @click="canAddFile && openFiles()"
                 style="display: flex;justify-content: center;width: 100%;text-align: center;height: 100%;align-items: center"
                 :style="canAddFile ? 'cursor:pointer' : ''"
            >
                {{emptyText}}
            </div>
            <template v-else>
                <div v-if="isImages"
                     style="display: flex; flex-wrap:wrap; width:100%; overflow-y:auto"
                     :style="{border: highlightBorder ? '1px solid #90D9FF' : '1px solid #DCDFE6'}"
                >
                    <div style="display: flex;width: 100%;justify-content: flex-end">
                        <div style="display: flex;position: absolute; margin-top: 5px;">
                            <my-el-button plus
                                          v-if="withAdd"
                                          :disabled="fileType.length !== 1" @click="openFiles"/>
                        </div>
                    </div>
                    <div
                            v-for="file in filteredFiles"
                            :key="file.name"
                            class="image-div">
                        <div style="font-size: 14px;color: #606266;margin-bottom: 3px">{{file.showName || file.name}}</div>
                        <div style="cursor:zoom-in; height:100%;overflow-y: auto"
                             v-viewer="{ inline: false, button: false, navbar: false, title: false, toolbar: false, tooltip: false, movable: true, zoomable: true, rotatable: false, scalable: true, transition: false, fullscreen: true, keyboard: false}">
                            <img style="width: 300px;background-color: white" v-if="file.dataInBase64" :src="`data:image/png;base64, ${file.dataInBase64}`">
                            <img v-else style="width: 300px;background-color: white" :src="`/api/files/getFile?id=${file.id}&access_token=${$currentUser.getJwtToken()}`" >
                        </div>
                        <i @click="onBtnDeleteFileClick(file)"
                           class="el-icon-delete"
                           style="align-self: flex-end;margin-top: 3px"></i>
                    </div>
                </div>
                <el-table
                        v-else
                        :show-header="showHeader"
                        :data="filteredFiles"
                        :style="{border: highlightBorder ? '1px solid #90D9FF' : ''}"
                        :border="true"
                        ref="table"
                        height="100%"
                        v-ls-saver:fileTable
                >
                    <el-table-column :label="title || 'Файл'" prop="name" min-width="100">
                        <div slot-scope="scope" style="display: flex">
                            <div v-if="withDownload"
                                 class="my-link"
                                 @click="onBtnDownloadFileClick(scope.row)"
                                 v-html="scope.row.showName"></div>
                            <a style="margin-left: 15px" class="el-icon-edit"
                               v-if="scope.row.type !== 'lotPassport' && scope.row.webDavPrefix && scope.row.id > 0"
                               :href="`${scope.row.webDavPrefix}${host}/webdav/${scope.row.id}/${scope.row.name}`"></a>
                        </div>
                    </el-table-column>
                    <el-table-column v-if="withTypes" label="Тип файла" width="100px">
                        <template slot-scope="scope">
                            {{$getEnumValue('FileTypeEnum', scope.row.type) }}
                        </template>
                    </el-table-column>
                    <el-table-column v-if="withDate" width="100px" label="Дата">
                        <template slot-scope="scope">
                            <my-date :date="scope.row.date"></my-date>
                        </template>
                    </el-table-column>
                    <el-table-column v-if="withDelete" width="65">
                        <template slot-scope="scope">
                            <my-el-button delete v-if="withDelete"
                                          @click="onBtnDeleteFileClick(scope.row)"
                            />
                        </template>
                        <template v-slot:header>
                            <my-el-button plus
                                          v-if="withAdd"
                                          @click="openFiles"/>
                        </template>
                    </el-table-column>
                </el-table>
            </template>
        </div>
        <input ref="fileInput" style="display: none" type="file" @change="addFiles" :multiple="multiFile"/>
    </div>
</template>

<script>
    import { confirm } from './dialogs/dialogUtils';
    import tableSaver from '@/utils/tableSaver';


    let selectWnd = {
        name: 'confirmWindow1',
        template: `<component-window
                        title="Добавление файлов"
                        ref="compWindowAddFiles"
                        :maximize ="false"
                        @close="onBtnCancelClick">
                     <div style="display: flex; flex-direction: column">
                       <el-form label-position="top" status-icon :model="result" ref="form" >
                         <el-form-item label="Выберите тип добавляемых файлов" prop="type">
                              <el-select
                                v-model="result.type"
                                :multiple = "false"
                                placeholder=""
                                value=""
                                >
                                   <el-option
                                       v-for="(fType,i) in computedTypes"
                                       :value="fType"
                                       :key="fType"
                                       :label="$getEnumValue('FileTypeEnum', fType)"/>
                            </el-select>
                         </el-form-item>
                       </el-form>
                     </div>
                     <template slot="footer">
                        <div style="display:flex;justify-content: space-between;width: 100%">
                          <div><my-el-button @click="onBtnCancelClick" type="warning">Отменить</my-el-button></div>
                          <div><my-el-button @click="onBtnSelect" type="success">Выбрать</my-el-button></div>
                        </div>
                     </template>
                   </component-window>`,
        props: [ 'onClose', 'types' ],
        data() {
            return {
                result: {type: ''},
                windowSize: { minHeight: 170, minWidth: 250, height: '280', width: '250' }
            }
        },
        methods: {
            onBtnCancelClick() {
                this.onClose(null);
                this.$emit('close');
            },
            async onBtnSelect() {
                try {
                    await this.$refs.form.validate()
                } catch (ex) {
                    return false;
                }
                this.onClose(this.result.type);
                this.$emit('close');
            }
        },
        computed: {
            computedTypes() {
                return !this.types || this.types.length === 0
                    ?  this.$getEnum('FileTypeEnum').map(e => e.value)
                    :  this.types
                }
            }
    };
    export default {
        name: "fileTable",
        mixins: [ tableSaver ],
        props: {
            highlightBorder: {default: false},
            multiFile: { default: true },
            title: { default: '' },
            files: { default: [] },
            isImages: { type: Boolean, default: false },
            withDelete: { type: Boolean, default: true },
            withDownload: { type: Boolean, default: true },
            withTypes: { type: Boolean, default: false },
            withAdd: { type: Boolean, default: true },
            withDate: { type: Boolean, default: false },
            fileType: { type: Array, default: () => [] },
            showHeader: { default: true }
        },
        data() {
          return {
            imgData: {}
          }
        },

        methods: {
            openFiles() {
                this.$refs.fileInput.value = '';
                this.$refs.fileInput.click();
            },

            async onBtnDownloadFileClick(file) {
              if (file.dataInBase64) {
                this.$myHttp.downloadFromDataLink(file.dataInBase64, file.showName, true);
              } else {
                await this.$myHttp.postWithDownload(`/api/files/getFile?id=${file.id}`,null,file.showName);
              }
            },

            async onBtnDeleteFileClick(file) {
                if (!await confirm(`Удалить файл '${file.showName}'?`, 'Удалить')) {
                    return;
                }
                if (file.dataInBase64) {
                    const idx = this.files.indexOf(file);
                    this.files.splice(idx, 1);
                } else {
                    file.forDelete = true;
                }
                this.$emit('delete-file', file);
            },
            async addFiles(e) {

                let fileList = e.target.files || e.dataTransfer.files;
                if (!fileList.length) {
                    return;
                }


                let fileType = '';
                if (this.fileType.length !== 1) {
                  fileType = await this.$showWindowAsync(selectWnd, {types: this.fileType});
                  if (!fileType) {
                      return;
                  }
                } else {
                    fileType = this.fileType[0];
                }
                let promiseArray = [];
                for ( let i = 0; i < (this.multiFile ? fileList.length : 1); i++ ) {
                    let fileName = fileList[ i ].name;
                    if (this.files.some(r => !r.forDelete && r.name.toLowerCase() === fileName.toLowerCase())) {
                        continue;
                    }
                    const fReader = new FileReader();
                    promiseArray.push(this.readerPromiseWrap(fReader, fileList, i, fileType));
                    fReader.readAsBinaryString(fileList[ i ])
                }
                let newFiles = await Promise.all(promiseArray);
                this.files.push(...newFiles);
                this.$emit("add-files", newFiles);
            },

            readerPromiseWrap(fReader, fileList, index, fileType) {
                return new Promise(resolve => {
                    fReader.onload = () => {
                        let file =
                            {
                                id: 0,
                                name: fileList[ index ].name,
                                showName: fileList[ index ].name,
                                dataInBase64: btoa(fReader.result),
                            };
                        file.type = fileType;
                        resolve(file);
                    };
                });
            }
        },
        computed: {
            host: () => {
                return window.location.origin;
                },
            canAddFile() {
                return this.withAdd;
            },
            emptyText() {
                if (!this.withAdd) {
                    return 'Нет файлов';
                }
                return 'Перетащите сюда файлы или кликните, чтобы выбрать';
            },

            mainMultiDivStyle() {
                let style = {
                    alignItems: !this.filteredFiles?.length || !this.canAddFile ? 'center' : ''
                };
                if (!this.filteredFiles?.length) {
                    style.border = this.highlightBorder && this.canAddFile ? '2px solid #90D9FF' : '2px dashed #EBEEF5'
                }
                return style;
            },

            filteredFiles() {
                return this.fileType.length > 0
                    ? this.files.filter(f => this.fileType.includes(f.type) && !f.forDelete)
                    : this.files.filter(f => !f.forDelete);
            }
        }
    }
</script>

<style>
    .images-div {
        display: flex;
        flex: 1 1;
        justify-content: flex-start;
        flex-wrap: wrap;
        height: 100%;
        overflow-y: auto;
        border: 1px solid #DCDFE6;
        padding: 10px;
    }

    .image-div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 5px;
        margin: 10px;
        border: 1px solid white;
        background-color: #DCDFE6;
        align-self: center;
    }

    .image-div:hover {
        border: 1px solid #90D9FF;
    }

    .viewer-move {
        background-color: white;
    }
</style>
